import React from 'react';
import { bool, func, string, shape } from 'prop-types';
import { Button, Input } from '@freska/freska-ui';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { useIntl } from 'react-intl';
import { checkForError } from '../../utils/validate';
import FlexContainer from '../Common/FlexContainer';
import { useServiceWorker } from '../../useServiceWorker';

import Form from '../Common/Form';

const propTypes = {
  loading: bool.isRequired,
  onSubmit: func.isRequired,
  password: string.isRequired,
  passwordConfirmation: string.isRequired,
  onChange: func.isRequired,
  errors: shape({}),
};

const defaultProps = {
  errors: {},
};

const ChangePasswordForm = ({
  loading,
  onSubmit,
  password,
  passwordConfirmation,
  errors,
  onChange,
}) => {
  const intl = useIntl();
  const { onlineStatus } = useServiceWorker();
  return (
    <Form name="update-password" onSubmit={onSubmit} loading={loading}>
      <Input
        id="new-password"
        name="new-password"
        label={intl.formatMessage({
          id: 'profile.password.new_password',
        })}
        error={checkForError(errors, 'password')}
        onChange={e => onChange(e, 'password')}
        value={password}
        type="password"
        disabled={loading}
        mb={2}
        hasBorder
      />
      <Input
        id="confirm-password"
        name="confirm-password"
        label={intl.formatMessage({
          id: 'profile.password.confirm_new_password',
        })}
        type="password"
        disabled={loading}
        error={checkForError(errors, 'passwordConfirmation')}
        onChange={e => onChange(e, 'passwordConfirmation')}
        value={passwordConfirmation}
        mb={2}
        hasBorder
      />
      <FlexContainer>
        <Button
          loading={loading}
          type="submit"
          id="reset-password-btn"
          disabled={!onlineStatus}
        >
          <FormattedMessage id="profile.password.update" />
        </Button>
      </FlexContainer>
    </Form>
  );
};

ChangePasswordForm.propTypes = propTypes;
ChangePasswordForm.defaultProps = defaultProps;

export default withRouter(ChangePasswordForm);
