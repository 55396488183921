import React, { Fragment } from 'react';
import { arrayOf, oneOf, bool } from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Card, Label, Text } from '@freska/freska-ui';
import withLanguage from '../../utils/withLanguage';
import { formatPrice } from '../../utils/formatNumber';
import { LANGUAGES_TYPE } from '../../constants';

import InvoiceCard from './InvoiceCard';

import { sizes, space } from '../../theme/theme';
import { invoiceType } from '../../types';

const propTypes = {
  invoices: arrayOf(invoiceType).isRequired,
  language: oneOf(LANGUAGES_TYPE).isRequired,
  isPending: bool,
};

const defaultProps = {
  isPending: false,
};

function getGrandTotal(invoices, locale, currency) {
  return formatPrice(
    invoices.reduce(
      (pre, cur) => pre + Number(cur.total_amount_vat_excluded),
      0
    ),
    locale,
    currency
  );
}

const InvoiceList = ({ language: locale, invoices, isPending }) => (
  <GroupSection>
    <Label pl={3} mb={2}>
      <FormattedMessage
        id={`invoices.section_headers.${
          isPending ? 'pending_invoices' : 'invoiced_last_18months'
        }`}
      />
    </Label>
    <Card mx={space.xs}>
      {invoices.length ? (
        <Fragment>
          {invoices.map(invoice => (
            <InvoiceCard
              key={invoice.id}
              isPending={isPending}
              data={invoice}
            />
          ))}
          <GrandTotalContainer>
            <Text as="span" align="right" bold>
              <FormattedMessage
                id={`invoices.grand_total.${
                  isPending ? 'pending_invoices' : 'invoiced_last_18months'
                }`}
              />
              &nbsp;
              {getGrandTotal(invoices, locale, invoices[0].currency)}
            </Text>
          </GrandTotalContainer>
        </Fragment>
      ) : (
        <Text as="span" color="secondary">
          <FormattedMessage
            id={`invoices.no_${
              isPending ? 'pending_invoices' : 'invoiced_last_18months'
            }`}
          />
        </Text>
      )}
    </Card>
  </GroupSection>
);

const GroupSection = styled.section`
  margin-bottom: ${space.default};
  max-width: ${sizes.width.invoiceCardMaxWidth};

  :last-child {
    margin-bottom: ${space.lg};
  }
`;

const GrandTotalContainer = styled.section`
  display: flex;
  justify-content: flex-end;
  margin-top: ${space.default};
`;

InvoiceList.propTypes = propTypes;
InvoiceList.defaultProps = defaultProps;

export default withLanguage(InvoiceList);
