import { string, bool, func } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { theme, Label, Box } from '@freska/freska-ui';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  labelId: string.isRequired,
  selected: bool.isRequired,
  Icon: func.isRequired,
};

const BottomNavTab = ({ labelId, selected, Icon, ...rest }) => {
  return (
    <Tab {...rest}>
      <Icon color={selected ? theme.colors.primary : theme.colors.secondary} />
      <StyledLabel
        color={selected ? theme.colors.primary : theme.colors.secondary}
      >
        <FormattedMessage id={`bookings.details.tab.${labelId}`} />
      </StyledLabel>
    </Tab>
  );
};

const Tab = styled(Box)`
  width: 100%;
  padding: ${theme.space[1]}px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:hover {
    background-color: ${theme.colors.greyLight};
  }
`;
const StyledLabel = styled(Label)`
  font-size: 10px;
  text-align: center;
  margin: 4px 0 0;
`;

BottomNavTab.propTypes = propTypes;

export default BottomNavTab;
