import React, { useRef, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

const propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullscreen']),
  children: PropTypes.node,
  mountId: PropTypes.string,
};

const defaultProps = {
  size: 'medium',
  children: null,
  mountId: 'root',
};

function Modal({ size, mountId, children }) {
  const mask = useRef(null);
  const dialog = useRef(null);
  const mount = document.getElementById(mountId);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return ReactDOM.createPortal(
    <DialogMask ref={mask}>
      <Wrapper size={size} ref={dialog}>
        {children}
      </Wrapper>
    </DialogMask>,
    mount
  );
}

const DialogMask = styled.div`
  align-items: center;
  align-content: center;
  background: ${props => transparentize(0.3, props.theme.colors.black)};
  display: flex;
  height: 100vh;
  left: 0;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99999;
`;

const Wrapper = styled.section.attrs({
  role: 'dialog',
  'aria-hidden': false,
  'aria-labelledby': 'dialog-title',
  'aria-describedby': 'dialog-desc',
})`
  position: relative;

  margin: ${props => props.theme.space[1]}px;
  max-height: calc(100vh - calc(2 * ${props => props.theme.space[2]}px));
  width: 100%;

  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius}px;
  overflow-y: auto;

  ${props =>
    props.size === 'small' &&
    css`
      max-width: ${props.theme.spaceUnit * 44}px;
    `};

  ${props =>
    props.size === 'medium' &&
    css`
      max-width: ${props.theme.spaceUnit * 64}px;
    `};

  ${props =>
    props.size === 'large' &&
    css`
      max-width: ${props.theme.spaceUnit * 96}px;
    `};

  ${props =>
    props.size === 'fullscreen' &&
    css`
      max-height: unset;
      height: 100vh;
      width: 100vw;
      margin: 0;
      border-radius: 0;
    `};
`;

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
