import React, { Fragment } from 'react';
import {
  FormattedMessage,
  FormattedDate,
  FormattedTime,
  useIntl,
} from 'react-intl';
import styled from 'styled-components';
import {
  Box,
  Button,
  Tag,
  Text,
  IconDirections,
  IconCalendarEvent,
  IconRecurring,
  IconOneOff,
  theme,
} from '@freska/freska-ui';
import FiberNewIcon from '@material-ui/icons/FiberNew';

import { trackEvent } from '../../utils/tracking';
import { formatDuration } from '../../utils/formatNumber';
import { space } from '../../theme/theme';

import Alert from '../Common/Alert';
import DataBlock from '../Common/DataBlock';

import BookingDetailsPets from './BookingDetailsPets';
import BookingDetailsServices from './BookingDetailsServices';
import BookingDetailsCleaningSupplies from './BookingDetailsCleaningSupplies';
import BookingDetailsAddress from './BookingDetailsAddress';
import BookingDetailsAccess from './BookingDetailsAccess';
// import BookingDetailsRating from './BookingDetailsRating';
// import BookingDetailsCustomerFeedback from './BookingDetailsCustomerFeedback';
import BookingDetailsUnconfirmed from './BookingDetailsUnconfirmed';
import BookingDetailsWindowCleaningNotes from './BookingDetailsWindowCleaningNotes';
import BookingDetailsServiceWorkerComment from './BookingDetailsServiceWorkerComment';
import { bookingType } from '../../types';

function getFrequencyIcon(serviceId, isSubstituteCleaner) {
  if (serviceId === '0' || isSubstituteCleaner) return IconOneOff;
  return IconRecurring;
}

function getTagColor(status, isUnconfirmed) {
  if (isUnconfirmed) return 'attention';
  switch (status) {
    case 'booked':
    case 'prebooked':
      return 'secondary';
    default:
      return 'completed';
  }
}

function getBookingStatus(status, isUnconfirmed) {
  if (isUnconfirmed) return 'unconfirmed';

  switch (status) {
    case 'booked':
      return 'booked';
    case 'prebooked':
      return 'prebooked';
    default:
      return 'completed';
  }
}

const buildMapsURL = address => {
  const fullAddress = `${address.street_address} ${address.city} ${address.postcode}`;
  return encodeURI(
    `https://www.google.com/maps/dir/?api=1&destination=${fullAddress}`
  );
};

const handleDirectionsClick = address => {
  trackEvent('Bookings: Directions button clicked', {
    category: 'Bookings',
  });
  window.open(buildMapsURL(address));
  return false;
};

const isDirectionButtonPrimary = (bookingStatus, isUnconfirmed) =>
  (bookingStatus === 'booked' && !isUnconfirmed) ||
  bookingStatus === 'prebooked';
const propTypes = {
  booking: bookingType,
};
function BookingDetailsTabDetails({ booking }) {
  const intl = useIntl();

  const extendedDuration = booking.detailSummary.extendedDuration;

  return (
    <Fragment>
      <HeaderCustomerGrid>
        <Box>
          <Box display="flex">
            {booking.company ? (
              <Fragment>
                <Text as="p" color="black" bold mb={0}>
                  {booking.company.name}
                </Text>
                <Text as="p" color="black" mb={0}>
                  <FormattedMessage id="bookings.company_contact_person" />
                  {`${booking.person.firstname} ${booking.person.lastname}`}
                </Text>
              </Fragment>
            ) : (
              <Text as="p" color="black" bold mb={0}>
                {`${booking.person.firstname} ${booking.person.lastname}`}
              </Text>
            )}
            {booking.detailSummary.extendedDuration > 0 && (
              <FiberNewIcon style={{ fill: theme.colors.attention, marginLeft: '4px' }} />
            )}
          </Box>
          <Address as="p" mb={0}>
            <span>
              {booking.address.street_address}
              &#44;
            </span>
            <span>
              {booking.address.postcode}
              &nbsp;
              {booking.address.city}
            </span>
          </Address>
        </Box>
        <StyledButton
          isDirectionButtonPrimary={isDirectionButtonPrimary(
            booking.status,
            booking.isUnconfirmed
          )}
          onClick={() => handleDirectionsClick(booking.address)}
          m={0}
        >
          <IconDirections
            title={intl.formatMessage({
              id: 'bookings.card.directions',
            })}
            color={
              isDirectionButtonPrimary(booking.status, booking.isUnconfirmed)
                ? 'white'
                : 'primary'
            }
          />
        </StyledButton>
      </HeaderCustomerGrid>
      <Tag
        color={getTagColor(booking.status, booking.isUnconfirmed)}
        mb={3}
        isOutlined={
          booking.status === 'booked' || booking.status === 'prebooked'
        }
      >
        {intl.formatMessage({
          id: `bookings.status.${getBookingStatus(
            booking.status,
            booking.isUnconfirmed
          )}`,
        })}
      </Tag>
      <DataBlock
        Icon={IconCalendarEvent}
        hasContent={!!booking.start_time && !!booking.end_time}
        hasBottomMargin={!extendedDuration}
      >
        <Text m={0} as="p">
          <FormattedDate
            value={booking.start_time}
            year="numeric"
            month="long"
            day="numeric"
            weekday="long"
          />
          <br />
          <FormattedTime
            value={booking.start_time}
            hour="2-digit"
            minute="2-digit"
            hour12={false}
          />
          &nbsp;&ndash;&nbsp;
          <FormattedTime
            value={booking.end_time}
            hour="2-digit"
            minute="2-digit"
            hour12={false}
          />
          {booking.invoiceable_duration &&
            booking.invoiceable_duration !== booking.duration ? (
            <Fragment>
              <br />
              <Text as="span">
                {formatDuration(booking.duration, intl, 'short', true)}
              </Text>
              &nbsp;
              <Text as="span" bold>
                &#40;
                {booking.invoiceable_duration >= booking.duration && '+'}
                {formatDuration(
                  booking.invoiceable_duration - booking.duration,
                  intl,
                  'short',
                  true
                )}
                &#41;
              </Text>
            </Fragment>
          ) : (
            ` (${formatDuration(booking.duration, intl, 'short', true)})`
          )}
        </Text>
      </DataBlock>
      {extendedDuration > 0 && (
        <Alert
          mt={1}
          mb={3}
          severity="info"
          title={intl.formatMessage({ id: 'bookings.new_customer' })}
          message={intl.formatMessage(
            { id: 'bookings.extended_duration' },
            { duration: extendedDuration }
          )}
        />
      )}
      <DataBlock
        Icon={getFrequencyIcon(booking.frequency, booking.isSubstituteCleaner)}
        hasContent={!!booking.start_time && !!booking.end_time}
      >
        <Text m={0} as="p">
          <FormattedMessage
            id={`bookings.card.frequency.${booking.isSubstituteCleaner ? 'substitute' : booking.frequency
              }`}
          />
        </Text>
      </DataBlock>

      {/* {!!booking.rating && (
        <Fragment>
          <BookingDetailsRating rating={booking.rating} />
          <BookingDetailsCustomerFeedback comment={booking.rating.comments} />
        </Fragment>
      )} */}
      {booking.work_report && (
        <BookingDetailsServiceWorkerComment
          comment={booking.work_report.internal_comments}
        />
      )}

      {!!booking.isUnconfirmed && <BookingDetailsUnconfirmed data={booking} />}

      <BookingDetailsCleaningSupplies
        cleaningSuppliesByFreska={booking.detailSummary.needsCleaningSupplies}
      />

      {booking.service.id === 3 && !!booking.window_cleaning_details && (
        <BookingDetailsWindowCleaningNotes
          windowCleaningDetails={booking.window_cleaning_details}
        />
      )}

      <BookingDetailsAccess
        access={booking.access}
        accessIconStatus={booking.detailSummary.accessStatus}
        serviceWorkerId={Number(booking.service_worker.id)}
      />

      <BookingDetailsAddress addressNote={booking.detailSummary.addressNotes} />

      {booking.service.id !== 3 && (
        <BookingDetailsServices additionalServices={booking.detailSummary.additionalServices} />
      )}
      <BookingDetailsPets pets={booking.detailSummary.pets} />
    </Fragment>
  );
}

const HeaderCustomerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 48px;
  grid-column-gap: ${space.sm};
  align-items: center;
  margin-bottom: ${space.md};
`;

const Address = styled(Text)`
  margin-bottom: 0;
  span {
    display: inline-block;
    padding-right: ${space.xs};
  }
`;

const StyledButton = styled(Button)`
  min-width: 42px;
  min-height: 42px;
  width: 42px;
  height: 42px;
  padding: ${space.sm};
  border-radius: 50%;
  justify-self: center;
  svg {
    height: 24px;
    width: 24px;
  }
  ${props =>
    !props.isDirectionButtonPrimary &&
    `
    background-color: ${theme.colors.white};
    box-shadow: none;
    :hover,
    :visited,
    :focus  {
      background-color: ${theme.colors.white};
    }
    `}
`;

BookingDetailsTabDetails.propTypes = propTypes;

export default BookingDetailsTabDetails;
