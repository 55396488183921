import { Box } from '@freska/freska-ui';
import { shape, arrayOf, func, node, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { theme } from '@freska/freska-ui';
import Header from '../Header/Header';
import withUser from '../../utils/withUser';

const propTypes = {
  children: node.isRequired,
  headerActions: arrayOf(
    shape({ handler: func, component: func, iconTooltip: string })
  ),
  user: shape({}).isRequired,
};

const defaultProps = {
  headerActions: [],
};

const ViewGrid = ({ children, headerActions, user }) => {
  const hasBanner = user.region.countrycode === 'NO' || (user.region.countrycode === 'FI' && user.employed);
  return (
    <Wrapper mt={hasBanner ? '60px' : 0}>
      <Header actions={headerActions} hasBanner={hasBanner} />
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding: 0 ${theme.space[1]}px;
  @media (min-width: 600px) {
    padding: 0 ${theme.space[3]}px;
  }
`;

ViewGrid.propTypes = propTypes;
ViewGrid.defaultProps = defaultProps;

export default withUser(ViewGrid);
