export default {
  app: {
    cancel: 'Avbryt',
    close: 'Stäng',
    menu: 'Meny',
    open: 'Öppna',
    save: 'Spara',
    delete: 'Radera',
  },
  snackbar: {
    offline: 'You are now offline! Limited functionality available.',
    online:
      'You are back online! Page will refresh automatically in 3 seconds…',
    new_version: 'A new version of the app is available!',
    action: {
      update_now: 'Uppdatera nu',
      refresh: 'Ladda om sidan',
    },
  },
  areas: {
    title: 'Arbetsområden',
    next_booking: 'Nästa bokning',
    home_address_marker_desc: 'Ditt hem',
    toggle_visibility_working_area_caption: 'Göm / Visa mina arbetsområden',
    toggle_visibility_customer_markers_caption: 'Göm / Visa mina kunder',
    booking_details_link: 'Öppna bokningsinformation',
    error_loading_map:
      'Something went wrong loading the map. Try again later or contact the Freska service team on service.team@freska.fi',
  },
  availability: {
    add_period: 'Lägg till tillgänglighetsperiod',
    availability_details: 'Tillgänglighetsinformation',
    availability_this_day: 'Tillgänglighet denna dag',
    back_link: 'Tillbaka till kalender',
    bookings_this_day:
      '{num, plural, =0 {Bokningar} one {{num} bokning} other {{num} bokningar}} denna dag',
    calendar: 'Kalender',
    continuous: 'Kontinuerlig',
    create_new_availability: 'Skapa ny veckotillgänglighet',
    create_upcoming_availability: 'Skapa tillgänglighetsregel',
    edit_rule: 'Redigera regel',
    edit_weekly_availability: 'Redigera veckotillgänglighet',
    end_on: 'Sluta på',
    ends: 'Slutar',
    off: 'Av',
    only_one_offs: 'Endast engångsbokningar',
    start_from: 'Börja från',
    starting_on: 'Tillgänglig från',
    ending_on: 'Tillgänglig till',
    please_note: 'Vänligen notera',
    stable_availability:
      ' En stabil tillgänglighet på minst 12 veckor ökar dina chanser att få stamkunder',
    rules: 'Tillgänglighetsregler',
    starts: 'Börjar',
    title: 'Tillgänglighet',
    modal_title: 'Tillgänglighet den',
    modal_description: '{date}',
    no_bookings_this_day: 'Inga bokningar denna dag',
    no_availability_this_day: 'Ingen tillgänglighet har lagts till ännu',
    one_off_modal: {
      title: 'Endast engångsbokningar',
      message:
        'Du väljer att endast vara tillgänglig för engångsbokningar. Observera att när det här alternativet är valt kommer du inte att tilldelas några regelbundna kunder som har återkommande bokningar. Om du aktiverar det här alternativet kommer dina redan befintliga regelbundna kunder inte att tas bort.',
      confirm: 'Ja, ge mig endast engångsbokningar',
    },
    delete_modal: {
      title: 'Ta bort tillgänglighet',
      message:
        'Om du tar bort denna tillgänglighet kommer dina bokningar inte att annulleras automatiskt. Du behöver fortfarande kontakta Freskas kontor via WhatsApp.',
      confirm: 'Ta bort i alla fall',
    },
    errors: {
      intervals_only:
        'Observera att din tidsinmatning har avrundats till närmaste 15 minuters intervall.',
    },
    remove_availability: 'Radera',
    save_availability: 'Spara tillgänglighet',
    disabled_explanation:
      'Kommande tillgänglighet kan bara ställas in om den första har ett slutdatum',
  },
  absence: {
    absence_types: {
      sick_leave: 'Sjukskriven',
      vacation: 'På semester',
      other_absence: 'Frånvaroledighet',
    },
    last_day_of_absence: 'Sista frånvarodagen: ',
    report_fit_link_caption: 'Anmälan passar',
    still_sick: 'Jag är fortfarande sjuk',
    modal_close: 'Stäng',
    action_fit_for_work: 'Redo att jobba igen',
    modal_heading: 'Anmäl redo för jobb',
    modal_text_intro_before_threshold:
      'Du är för närvarande sjukskriven. Rapportera <b>innan {time} idag</b> om du är redo att jobba imorgon.',
    modal_text_intro_after_threshold:
      "You are currently on sick leave. <b>You missed today's {time} deadline</b> to restart work tomorrow.",
    modal_text_details_before_threshold:
      'Annars kommer dina bokningar för morgondagen automatiskt att omplaneras eller omtilldelas.',
    modal_text_details_after_threshold:
      'Morgondagens bokningar har tagits bort från dig. Om du anmäler att du är redo att jobba nu, förväntas du återgå till jobbet <b>efter i morgon</b> för din nästa schemalagda bokning. Frågor? Vänligen kontakta oss.',
    modal_text_details_still_sick:
      'Fortfarande sjuk? Krya på dig, och kom ihåg att anmäla när du är redo för jobb i tid, när du är frisk igen.',
    error_updating_absence:
      'Hoppsan! Något gick fel. Vi kunde inte rapportera att du är redo för jobb imorgon. Vänligen kontakta Freskas kontor istället.',
    error_fetching_ongoing_absences:
      'Ett fel uppstod när du letade efter dina nuvarande frånvaro. Försök igen senare eller i brådskande fall, kontakta Freskas kontor.',
  },
  access: {
    status: {
      no_key: 'Kunden ska öppna dörren',
      door_code_only: 'Endast dörrkod',
      stored_by_customer: 'Kunden har nyckeln',
      key: 'Nyckel',
    },
    labels: {
      door_code: 'Dörrkod',
      access_notes: 'Åtkomstanteckningar',
    },
  },
  bookings: {
    calendar: 'Kalender',
    completed: 'Avslutad',
    hide_past_bookings: 'Göm tidigare bokningar',
    last_week: 'Förra veckan',
    next_week: 'Nästa vecka',
    no_bookings: 'Inga bokningar denna vecka',
    show_past_bookings: 'Visa tidigare bokningar',
    title: 'Bokningar',
    this_week: 'Denna vecka',
    today: 'Idag',
    total_time:
      '{totalBookings} {totalBookings, plural, one {Bokning} other {Bokningar}} denna vecka (totalt {totalHours} {totalHours, plural, one {timme} other {timmar}})',
    unconfirmed_bookings: '{num} Obekräftad bokning/bokningar',
    upcoming: 'Kommande',
    week_num: 'Vecka {num}',
    company_contact_person: 'Kontaktperson: ',
    new_customer: 'Ny kund!',
    extended_duration:
      'Detta är en ny abonnemangskunds första städning. Vi adderade {duration} minuter extra till denna bokning så att du hinner utföra en 5-stjärnig städning. Du kan använda den extra tiden till att dubbelkolla de viktigaste områdena och skriva hälsningar i välkommen hem-kortet. 💙',
    card: {
      access: 'Åtkomst till bokningen',
      additional_services: 'Tilläggstjänster',
      address_notes: 'Adressanteckningar',
      cleaning_supplies: 'Städmedel',
      customer_notes: 'Kundanteckningar',
      window_cleaning_notes: 'Fönsterputsdetaljer',
      directions: 'Få riktlinjer',
      rating: 'Kundbetyg',
      average_rating: 'Genomsnittligt betyg',
      no_rating_provided: 'Inget betyg finns',
      service_type: {
        1: 'Hemstädning',
        2: 'Kontorsstädning',
        3: 'Fönsterputs',
        4: 'Flyttstädning',
        6: 'Sjukhusstädning',
        7: 'Storstädning',
        8: 'Visningsstädning',
        9: 'Kontorsmöte',
        10: 'Nyckelhämtning',
        11: 'Rast',
        12: 'Resa',
        13: 'Sen avbokning',
        14: 'Träning',
        15: 'Semester',
        16: 'Föräldraledighet',
        17: 'Obetald ledighet',
        18: 'Sjukskriven',
        19: 'Barnsjukskrivning',
        20: 'Flex hemstädning',
        21: 'Semesterservice',
        22: 'Trappstädning',
        23: 'Anpassad städning',
        36: 'Flyttstädning',
        38: 'Sjukhusstädning',
      },
      frequency: {
        0: 'Engång',
        1: 'Varje vecka',
        2: 'Varannan vecka',
        4: 'Var 4:e vecka',
        substitute: 'Ersättare',
      },
      pets: {
        cat: 'Katt',
        dog: 'Hund',
        other: 'Övriga',
        pets: 'Husdjur',
      },
    },
    details: {
      back_link: 'Tillbaka till översikten',
      booking_details: 'Bokningsdetaljer',
      cleaning_supplies: {
        by_freska: 'Freskas Städmedel.',
        by_customer: 'Kunden tillhandahåller städmedel.',
      },
      sw_comment_to_freska: 'Din kommentar till Freska',
      service_type: 'Typ av tjänst',
      service_frequency: 'Frekvens',
      window_cleaning: {
        window_groups: 'Fönstertyp',
        single_windows: 'Enkla fönster',
        balcony: 'Balkong',
        balcony_value: 'Ja, {num} rutor',
        no_balcony: 'Nej',
        number_of_layers: 'Sidor/fönster',
        ventilation_windows: 'Ventilera. fönster',
        included: 'Ja',
        not_included: 'Nej',
        blinds: 'Persienner',
      },
      feedback_history: {
        no_history: 'Det finns ännu ingen feedback för denna bokning.',
        title: 'Feedbackhistorik',
      },
      tab: {
        details: 'Detaljer',
        instructions: 'Instruktioner',
        feedback: 'Feedback',
      },
      instructions: {
        title: 'Instruktioner',
        customer_instructions_title: 'Kundens städinstruktioner',
        worker_notes_title: 'Personalanteckningar',
        worker_notes_description:
          'Personalanteckningar är till för att underlätta för din ersättare att fortsätta ge din kund högsta kvalité på hemstädningen om du exempelvis är sjuk eller på semester. All personal kommer kunna se dina anteckningar.',
        worker_notes_info_tag: 'Hjälp',
        no_cleaning_instructions:
          'Kunden har inte lämnat några städinstruktioner.',
        error_deleting_worker_note:
          'Att radera anteckningen lyckades inte. Ladda om sidan och försök igen. Om problemet kvarstår, vänligen kontakta Freskas kontor.',
        error_fetching_worker_notes:
          'Det gick inte att hämta anteckningarna. Ladda om sidan och försök igen. Om problemet kvarstår, vänligen kontakta Freskas kontor.',
        error_saving_worker_note:
          'Att spara ändringar i anteckningen lyckades inte. Ladda om sidan och försök igen. Om problemet kvarstår, vänligen kontakta Freskas kontor.',
        save_changes: 'Spara ändringar',
        add_note: 'Lägg till anteckning',
        cancel_add_note: 'Radera',
        cancel_edit_note: 'Radera',
        last_updated: 'Senast uppdaterad ',
        delete_confirmation_modal: {
          title: 'Radera anteckning',
          message:
            'Denna åtgärd tar bort den här anteckningen för all (framtida) personal för denna kund och tjänst. Denna åtgärd kan inte ångras.',
          button_caption: 'Radera anteckning',
        },
        no_worker_notes:
          'Inga personalanteckningar för denna kund eller tjänst har lagts till ännu.',
        error_saving_worker_notes:
          'Det gick inte att spara personalanteckningar. Vänligen försök igen senare',
      },
    },
    status: {
      approved: 'Godkänd',
      booked: 'Bokad',
      completed: 'Slutförd',
      invoiced: 'Faktureras',
      paid: 'Betalat',
      prebooked: 'Förbokat',
      unconfirmed: 'Obekräftad',
      uninvoiced: 'Ej fakturerad',
      outside_availability_period: 'Utanför tillgänglighet',
      canceled: 'avbokad',
    },
    summary: 'Sammanfattning',
    unconfirmed: {
      comments_to_freska: 'Kommentarer till Freska (valfritt)',
      confirm_as_completed: 'Jag har slutfört denna bokning',
      ended: 'Avslutad',
      show_booking_details: 'Visa bokningsdetaljer',
      started: 'Satte igång',
      tips_and_tricks: 'Tips och tricks för städare',
      comments_to_freska_placeholder:
        'Skriv bara här om tiden har varit annorlunda än den faktiska bokade tiden, eller om det är något vi borde veta. Denna text är valfri och inte synlig för kunden!',
    },
    actions: {
      show_list_view: 'Visa listvy',
      show_calendar_view: 'Visa kalendervy',
    },
  },
  calendar: {
    modal: {
      title: 'Tillgänglighetsinformation',
    },
  },
  keys: {
    title: 'Nycklar',
    back_link: 'Tillbaka till detaljer',
    key_details: 'Nyckelinformation',
    notes: 'Anteckningar',
    next_booking: 'Nästa bokning',
    no_keys: 'Inga nycklar hittades',
    pick_up_before_date: 'Hämta innan: {date}',
    return_latest_by_date: 'Lämna tillbaka senast: {date}',
    section_headers: {
      return: 'Nycklar ska återlämnas till kontoret',
      pick_up: 'Nycklar att hämta på kontoret',
      not_urgent: 'Dina nycklar',
    },
    status: {
      at_office: 'Freskas kontor',
      at_you: 'Du',
      at_another_cleaner: 'En annan städare',
      at_cleaner: 'Du',
    },
    tags: {
      return: 'Lämna tillbaka nyckeln till kontoret',
      pick_up: 'Kräver upphämtning',
    },
    labels: {
      return_date: 'Lämnas tillbaka senast',
      label: 'Key label',
      safekeeper: 'Nyckelansvarig',
      pick_up_location: 'Upphämtningsplats',
      key_notes: 'Nyckelanteckningar',
    },
  },
  dates: {
    mon: 'Mån',
    monday: 'Måndag',
    tue: 'Tis',
    tuesday: 'Tisdag',
    wed: 'Ons',
    wednesday: 'Onsdag',
    thu: 'Tor',
    thursday: 'Torsdag',
    fri: 'Fre',
    friday: 'Fredag',
    sat: 'Lör',
    saturday: 'Lördag',
    sun: 'Sön',
    sunday: 'Söndag',
    jan: 'Jan',
    january: 'Januari',
    feb: 'Feb',
    february: 'Februari',
    mar: 'Mar',
    march: 'Mars',
    apr: 'Apr',
    april: 'April',
    may: 'Maj',
    may_full: 'Maj',
    jun: 'Jun',
    june: 'Juni',
    jul: 'Jul',
    july: 'Juli',
    aug: 'Aug',
    august: 'Augusti',
    sep: 'Sep',
    september: 'September',
    oct: 'Okt',
    october: 'Oktober',
    nov: 'Nov',
    november: 'November',
    dec: 'Dec',
    december: 'December',
  },
  pluralDates: {
    mon: 'Måndagar',
    tue: 'Tisdagar',
    wed: 'Onsdagar',
    thu: 'Torsdagar',
    fri: 'Fredagar',
    sat: 'Lördagar',
    sun: 'Söndagar',
  },
  shortDates: {
    mon: 'Mån',
    tue: 'Tis',
    wed: 'Ons',
    thu: 'Tor',
    fri: 'Fre',
    sat: 'Lör',
    sun: 'Sön',
  },
  time: {
    duration_hours: {
      short: '{hours}h',
      long: '{hours} timme / timmar',
    },
    duration_minutes: {
      short: '{minutes}m',
      long: '{minutes} minut/minuter',
    },
  },
  error: {
    contact_support: 'Kontakta supporten om problemet kvarstår.',
    default: 'Ett fel har uppstått. Var god försök igen.',
    incorrect_credentials: 'Din e -post och/eller lösenord är felaktiga',
    something_went_wrong:
      'Det verkar som att något gick fel. Oroa dig inte, vi städar upp det.',
    start_over: 'Börja om',
    whoops: 'Hoppsan!',
    wrong_input: 'Lösenordet matchar inte',
    offline_header: 'Inte tillgänglig offline',
    offline_description:
      'När du är offline ger Superheroes-appen begränsad funktionalitet baserat på de tidigare sidorna du har besökt. När du är ansluten till internet kommer full funktionalitet att återställas.',
  },
  login: {
    email: 'E-post',
    password: 'Lösenord',
    login: 'Logga in',
    return_to_login: 'Återgå till inloggning',
    forgot_password: 'Glömt lösenord?',
    reset_password: 'Skicka återställningsinstruktioner',
    reset_password_email_sent:
      'E-postmeddelandet med instruktioner har skickats till denna e-postadress',
    translate_msg: 'På finska',
    reset_password_success: 'Ditt lösenord har ändrats',
  },
  nav: {
    availability: 'Tillgänglighet',
    bookings: 'Bokningar',
    keys: 'Nycklar',
    logout: 'Logga ut',
    support: 'Support',
    profile: 'Din Profil',
    rating: 'Kundbetyg',
    working_area: 'Arbetsområden',
    you_are_here: 'Du är här',
    invoices: 'Fakturor',
    news: 'Nyheter',
    dashboard: 'Startsida',
    hse_form: 'Hälsoformulär',
  },
  dashboard_news: {
    title: 'Meny',
  },
  dashboard: {
    title: 'Startsida',
    latest_news: 'Senaste nyheterna',
    all_news: 'Alla nyheter',
    next_booking: 'Kommande bokning',
    view_schedule: 'Visa schema',
    published: 'Publicerad den {date} kl. {time}',
    news_fetch_failed: 'Tyvärr hände något när vi hämtade nyheterna',
    action_required: 'Att göra',
    booking_status: 'Obekräftad bokning',
    up_to_date: 'Du är helt uppdaterad! Grymt! 💪',
    customer_instructions_available: 'Kundinstruktioner finns tillgängliga!',
    no_upcoming_cleanings: 'Inga bokningar under de kommande tre dagarna.',
    fetching_next_failed:
      'Något hände när nästa bokning skulle hämtas. Se schemat för att se dina kommande städningar.',
    view: 'View',
    key_status: {
      at_cleaner: 'Lämna tillbaka nyckeln till kontoret',
      at_office: 'Hämta på kontoret',
    },
  },
  not_found: {
    return: 'Lämna',
    title: 'Sidan hittas inte',
    whoops: 'Hoppsan... den sidan kunde inte hittas.',
  },
  support: {
    title: 'Support',
    back_link: 'Tillbaka till support',
  },
  news: {
    title: 'Nyheter',
    back_link: 'Tillbaka till översikten',
    publishedAt: 'Publicerad på {date} vid {time}',
  },
  profile: {
    title: 'Din Profil',
    back_link: 'Tillbaka till profil',
    password: {
      change_password: 'Ändra lösenord',
      confirm_new_password: 'Bekräfta nytt lösenord',
      current_password: 'Nuvarande lösenord',
      new_password: 'Nytt lösenord',
      update: 'Uppdatera lösenord',
    },
    language: {
      options: {
        english: 'Engelska',
        finnish: 'Finska',
      },
      set_language: 'Ställ in språk',
    },
    success: 'Ditt lösenord har ändrats',
  },
  ratings: {
    average_rating: 'Genomsnittligt kundbetyg de senaste {num} månaderna',
    behavior: 'Beteende',
    comments: 'Kundfeedback',
    latest_ratings: 'Senaste betyg',
    on_time: 'I tid',
    overall: 'Övergripande',
    title: 'Kundbetyg',
    total_cleanings: 'Totalt utförda städningar',
    total_hours: 'Totalt utförda timmar',
    quality: 'Kvalité',
    no_customer_feedback_provided:
      'Kunden lämnade ingen feedback om denna bokning.',
  },
  services: {
    fridge: 'Kylskåp',
    ironing: 'Strykning av kläder',
    oven_cleaning: 'Ugnsrengöring',
    sauna_cleaning: 'Bastustädning',
    trash: 'Sopor',
  },
  invoices: {
    title: 'Fakturor',
    back_link: 'Tillbaka till fakturor',
    invoice_details: 'Fakturainformation',
    invoice_period: 'Fakturaperiod',
    start_date: 'Startdatum',
    end_date: 'Slutdatum',
    date_range: 'Datumintervall',
    create_invoice_period_continue: 'Skapa fakturaperiod...',
    create_invoice_period: 'Skapa fakturaperiod',
    all_invoiceable_bookings: 'Alla faktureringsbara bokningar',
    specific_period: 'Specifik period',
    invoice_period_disclaimer:
      'Granska och bekräfta denna information innan du skapar din faktura.',
    invoice_period_note: 'Viktig',
    grand_total: {
      pending_invoices: 'Totalt väntande:',
      invoiced_last_18months: 'Totalt fakturerat:',
    },
    awaiting_invoice_header: 'Detta är inte en faktura.',
    awaiting_invoice_desc:
      'Du behöver fortfarande skicka din faktura till Freska med ditt eget företag eller med en faktureringstjänst.',
    extra_hours: 'Extra timmar adderade',
    agreement:
      'Jag har granskat alla inkluderade bokningar noggrant och bekräftar att dessa är korrekta',
    section_headers: {
      pending_invoices: 'Väntar på din faktura',
      invoiced_last_18months: 'Fakturerat',
      uninvoiced_bookings: 'Ej fakturerade bokningar',
      invoiceable_bookings: 'Fakturerbara bokningar',
    },
    status: {
      invoiced: 'Fakturerat',
      paid: 'Betalad',
      uninvoiced: 'Väntar på din faktura',
      processed: 'Bearbetas',
    },
    errors: {
      no_invoiceable_bookings: 'Inga bokningar under vald period',
      no_price_level:
        'Det gick inte att skapa fakturadetaljer. Vänligen kontakta kundservice',
      invoice_create_fail:
        'Något gick fel när du skapade dina fakturauppgifter.',
    },
    ukko: {
      quantity: 'Kvantitet',
      unit_price: 'Enhetspris',
      payment_terms: 'Betalningsvillkor',
      total_amount: 'Total',
      invoice_number: 'Faktura nr.',
      paid_on: 'Betalas på',
      total_amount_with_vat: 'Totalt med moms',
      vat_amount: 'Momsbeloppet',
    },
    hours: '{hours} timmar',
    hours_short: '{hours}t',
    included_bookings: 'Inkluderade bokningar',
    totals_per_service_type: 'Totalt per servicetyp',
    no_invoices: 'Ingen fakturainformation tillgänglig',
    no_pending_invoices: 'Ingen fakturainformation tillgänglig.',
    no_invoiced_last_18months: 'Inga fakturerade fakturor tillgängliga.',
  },
  face_mask: {
    title: 'Bär alltid munskydd',
    description:
      'För att förhindra smittspridning av Covid 19 rekommenderar vi starkt att bära munskydd.',
  },
  article_was_not_found:
    'Tyvärr hittades inte artikeln. Gå tillbaka och försök igen.',
};
