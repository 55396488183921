import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Card, IconPin, Text, theme, Box } from '@freska/freska-ui';
import { format, parseISO } from 'date-fns';
import { space, sizes } from '../../theme/theme';
import { newsContentType } from '../../types';

const propTypes = {
  item: newsContentType.isRequired,
};

const NewsCard = ({ item }) => (
  <CardContainer
    className="card-section"
    key={item.slug}
    thumbImage={item.thumbImage}
  >
    <Box display="flex">
      {item.thumbImage && (
        <ThumbImage imageURL={`https:${item.thumbImage.fields.file.url}`} />
      )}
      <Box>
        <Text as="p" size="large" bold mb="4px" color="primary">
          {item.title}
        </Text>
        <Text as="p" size="small">
          <FormattedMessage
            id="news.publishedAt"
            values={{
              date: format(parseISO(item.publishOn), 'MMM d, yyyy'),
              time: format(parseISO(item.publishOn), 'HH:mm'),
            }}
          />
        </Text>
      </Box>
    </Box>
    {item.isPinned && <StyledPin size={20} color="completed" /> }
  </CardContainer>
);

const StyledPin = styled(IconPin)`
  align-self: flex-start;
  flex-shrink: 0;
  margin-right: -12px;
  margin-top: -12px;
`;

const ThumbImage = styled.div`
  flex-shrink: 0;
  background-image: url(${props => props.imageURL});
  background-position: center;
  background-size: cover;
  clip-path: inset(0% 0% 0% 0% round ${theme.borderRadius}px);
  width: 100%;
  max-width: 66px;
  height: auto;
  margin-top: -${theme.space[2]}px;
  margin-right: ${theme.space[2]}px;
  margin-bottom: -${theme.space[2]}px;
  margin-left: -${theme.space[2]}px;
`;

const CardContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${sizes.width.bookingCardMaxWidth};
  width: calc(100% - calc(${space.sm} * 2));
  cursor: pointer;
`;

NewsCard.propTypes = propTypes;

export default NewsCard;
