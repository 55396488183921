import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import { Box, Text, Label, theme, typography } from '@freska/freska-ui';
import { getAccessStatusIcon } from '../../utils/bookingsHelper';
import { space, sizes } from '../../theme/theme';
import { serviceWorkerKeysType } from '../../types';

const propTypes = {
  keyInfo: serviceWorkerKeysType.isRequired,
  status: string.isRequired,
};

const KeyCard = ({ keyInfo, status }) => {
  const intl = useIntl();
  return (
    <CardContainer key={keyInfo.id}>
      <IconContainer withAttention={status !== 'not_urgent'}>
        {getAccessStatusIcon(
          status,
          16,
          status !== 'not_urgent' ? 'white' : 'secondary'
        )}
      </IconContainer>
      <CardContentContainer>
        {status !== 'not_urgent' && (
          <Label m={0} color="attention">
            <FormattedMessage id={`keys.tags.${status}`} />
          </Label>
        )}
        <Text as="p" size="large" bold mb={0}>
          {keyInfo.label}
        </Text>
        {keyInfo.nextBooking && (
          <Customer as="p" mb={1}>
            {`${keyInfo.nextBooking.person.firstname} ${keyInfo.nextBooking.person.lastname}`}
          </Customer>
        )}

        {!!keyInfo.return_date && (
          <Text as="p" size="small" color="secondary">
            <FormattedMessage
              id="keys.return_latest_by_date"
              values={{
                date: intl.formatDate(keyInfo.return_date, {
                  weekday: 'short',
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                }),
              }}
            />
          </Text>
        )}
        {keyInfo.status === 'at_office' && keyInfo.nextBooking && (
          <Text as="p" size="small" color="secondary">
            <FormattedMessage
              id="keys.pick_up_before_date"
              values={{
                date: intl.formatDate(keyInfo.nextBooking.start_time, {
                  weekday: 'short',
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                }),
              }}
            />
          </Text>
        )}
      </CardContentContainer>
    </CardContainer>
  );
};

const CardContentContainer = styled.section`
  grid-column: 2;
  border-bottom: 1px solid ${theme.colors.greyMed};
  padding-bottom: ${space.sm};
`;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1;
  justify-content: center;
  height: ${sizes.width.customerTypeIcon};
  width: ${sizes.width.customerTypeIcon};

  background: ${props =>
    props.withAttention ? theme.colors.attention : theme.colors.greyLight};
  border-radius: 50%;
`;

const Customer = styled(Text)`
  span {
    display: inline-block;
    padding-right: 4px;
  }
`;

const CardContainer = styled(Box)`
  ${typography.fontRegular()};
  display: grid;
  grid-gap: ${space.md};
  grid-template-columns: ${sizes.width.customerTypeIcon} 1fr;
  margin-bottom: ${space.md};
  max-width: ${sizes.width.bookingCardMaxWidth};
`;

KeyCard.propTypes = propTypes;

export default KeyCard;
