import React, { Component } from 'react';
import styled from 'styled-components';
import { shape, arrayOf, string, bool, func } from 'prop-types';
import { Box, theme } from '@freska/freska-ui';
import {
  getISOWeek,
  eachDayOfInterval,
  addWeeks,
  getDate,
  getMonth,
  getDayOfYear,
  getDay,
  format,
  differenceInSeconds,
  parseISO,
  startOfISOWeek,
  endOfISOWeek,
  isWithinInterval,
} from 'date-fns';
import { Route, Switch, withRouter } from 'react-router-dom';

import { trackEvent } from '../../utils/tracking';
import ViewGrid from '../Common/ViewGrid';
import DayModal from './DayModal';
import DaysRow from './DaysRow';
import DayItem from './DayItem';
import WeekGraphWrapper from './WeekGraphWrapper';
import WeekGraph from './WeekGraph';
import WeekButton from './WeekButton';
import { bookingType, availabilityType } from '../../types';

const propTypes = {
  data: shape({
    bookings: arrayOf(bookingType).isRequired,
    availabilities: arrayOf(availabilityType).isRequired,
    mergedAvailabilities: arrayOf(
      shape({
        date: string,
        availabilities: arrayOf(availabilityType),
      })
    ).isRequired,
  }).isRequired,
  isUserEmployed: bool.isRequired,
  toggleDrawer: func.isRequired,
  history: shape({}).isRequired,
};

class FullCalendar extends Component {
  state = {
    showModal: false,
    weeksToShow: 12,
    data: null,
  };

  availableDays = new Set();
  availableWeeks = new Map();

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      return { data: nextProps.data };
    }
    return false;
  }

  componentDidMount() {
    this.populateCalendar();
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (prevProps.data !== data) {
      this.populateCalendar();
    }
  }

  clearSets = () => {
    this.availableDays.clear();
    this.availableWeeks.clear();
  };

  populateCalendar = () => {
    const {
      data: { bookings, availabilities },
    } = this.props;

    this.clearSets();

    this.setState({
      data: {
        bookings,
        availabilities,
      },
    });

    this.mapArrayToDaysAndWeeks(
      availabilities,
      this.availableWeeks,
      this.availableDays
    );
  };

  toggleDayModal = date => {
    const { history } = this.props;
    trackEvent('Availability: Calendar: Single Availabiltiy Edit: Closed', {
      category: 'Availability',
    });
    history.push({
      pathname: `/availability${date ? `/${format(date, 'yyyy-MM-dd')}` : ''}`,
    });
  };

  toggleWeekPreview = weekNum => {
    trackEvent('Availability: Calendar: Week Preview Toggled', {
      category: 'Availability',
    });
    this.setState(prevState => ({
      [`week-graph-${weekNum}`]: !prevState[`week-graph-${weekNum}`],
    }));
  };

  buildArraysOfWeeks = (calendarRange, allWeeks) => {
    const copyOfCalendarRange = [...calendarRange];
    while (copyOfCalendarRange.length) {
      allWeeks.push(copyOfCalendarRange.splice(0, 7));
    }
  };

  isDayAvailable = day => this.availableDays.has(format(day, 'yyyy-MM-dd'));

  getDayAvailabilities = date => {
    const parsedDate = new Date(date);
    const weekNum = getISOWeek(parsedDate);
    const weekAvailabilities = this.availableWeeks.get(weekNum);
    const weekDay = getDay(parsedDate) === 0 ? 6 : getDay(parsedDate) - 1;
    const availabilityTimes =
      weekAvailabilities && weekAvailabilities[weekDay]
        ? weekAvailabilities[weekDay]
        : null;

    return availabilityTimes || [];
  };

  mapArrayToDaysAndWeeks = (array, weekArray, dayArray) => {
    if (dayArray) {
      array.map(({ start_time: startTime }) =>
        dayArray.add(format(new Date(startTime), 'yyyy-MM-dd'))
      );
    }

    array.map(day => {
      const date = new Date(day.start_time);
      const weekNo = getISOWeek(date);
      const weekDay = getDay(date) === 0 ? 6 : getDay(date) - 1;
      const week = new Array(7);

      const eventsOnWeekNo = weekArray.get(weekNo)
        ? weekArray.get(weekNo)
        : week.fill([]);

      if (eventsOnWeekNo[weekDay] && !!eventsOnWeekNo[weekDay].length) {
        eventsOnWeekNo[weekDay].push({
          startTime: day.start_time,
          endTime: day.end_time,
          availabilityId: day.availabilityId,
        });
      } else {
        eventsOnWeekNo[weekDay] = [
          {
            startTime: day.start_time,
            endTime: day.end_time,
            availabilityId: day.availabilityId,
          },
        ];
      }
      weekArray.set(weekNo, eventsOnWeekNo);
      return weekArray;
    });
  };

  isOutsideAvailabilityPeriod = booking => {
    const {
      data: { mergedAvailabilities },
    } = this.props;

    const filteredAvailabilities = mergedAvailabilities.filter(
      dayAvailability =>
        dayAvailability.date ===
        format(parseISO(booking.start_time), 'yyyy-MM-dd')
    );
    if (filteredAvailabilities.length) {
      return !filteredAvailabilities[0].availabilities.some(availability => {
        try {
          return (
            isWithinInterval(parseISO(booking.start_time), {
              start: parseISO(availability.start_time),
              end: parseISO(availability.end_time),
            }) &&
            isWithinInterval(parseISO(booking.end_time), {
              start: parseISO(availability.start_time),
              end: parseISO(availability.end_time),
            })
          );
        } catch {
          return false;
        }
      });
    }
    return true;
  };

  findBookingsOnDay = (bookings, day) => {
    const bookingsOnDay = bookings.find(
      av => av.date === format(day, 'yyyy-MM-dd')
    );
    return bookingsOnDay ? bookingsOnDay.bookings : [];
  };

  render() {
    const { weeksToShow } = this.state;
    const {
      data: { bookings },
      isUserEmployed,
      toggleDrawer,
    } = this.props;

    const startDate = startOfISOWeek(new Date());
    const endDate = endOfISOWeek(addWeeks(startDate, weeksToShow));
    const calendarRange = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });

    const allWeeks = [];

    this.buildArraysOfWeeks(calendarRange, allWeeks);

    return (
      <Switch>
        {calendarRange.map(date => (
          <Route
            path={`/availability/${format(date, 'yyyy-MM-dd')}`}
            key={date}
            render={() => (
              <DayModal
                date={date}
                availabilities={this.getDayAvailabilities(date)}
                bookings={this.findBookingsOnDay(bookings, date)}
                toggleDrawer={toggleDrawer}
                close={this.toggleDayModal}
                defaultDay={{
                  startTime: '08:00',
                  endTime: '18:00',
                }}
                handleOutsideAvailabilityPeriod={
                  this.isOutsideAvailabilityPeriod
                }
              />
            )}
          />
        ))}
        <Route
          path="/"
          render={() => (
            <ViewGrid>
              <DaysRow />
              {allWeeks.map((week, weekIndex) => {
                const thisWeekNum = getISOWeek(week[0]);
                const weekAvailabilities = this.availableWeeks.get(thisWeekNum);

                return (
                  <WeekRow key={`week-${thisWeekNum}`} weekNum={thisWeekNum}>
                    <WeekButton
                      clickHandler={() => this.toggleWeekPreview(thisWeekNum)}
                      shouldOpen={!!weekAvailabilities}
                      // eslint-disable-next-line react/destructuring-assignment
                      isOpen={this.state[`week-graph-${thisWeekNum}`]}
                      week={thisWeekNum}
                    />

                    {week.map((day, dayIndex) => {
                      const thisDateNum = getDate(day);
                      const thisMonthNum = getMonth(day);
                      const availabilityTimes =
                        weekAvailabilities && weekAvailabilities[dayIndex]
                          ? weekAvailabilities[dayIndex]
                          : null;

                      return (
                        <DayItem
                          day={day}
                          bookingsArray={this.findBookingsOnDay(
                            bookings,
                            day
                          ).sort((previous, next) =>
                            differenceInSeconds(
                              parseISO(previous.start_time),
                              parseISO(next.start_time)
                            )
                          )}
                          isAvailable={this.isDayAvailable(day)}
                          clickHandler={
                            isUserEmployed
                              ? null
                              : () => {
                                  this.toggleDayModal(day, availabilityTimes);
                                }
                          }
                          key={`day-${getDayOfYear(day)}`}
                          weekIndex={weekIndex}
                          dayIndex={dayIndex}
                          date={thisDateNum}
                          month={thisMonthNum}
                          handleOutsideAvailabilityPeriod={
                            this.isOutsideAvailabilityPeriod
                          }
                        />
                      );
                    })}

                    <WeekGraphWrapper
                      /* eslint-disable-next-line react/destructuring-assignment */
                      isShown={this.state[`week-graph-${thisWeekNum}`]}
                      forwardRef={el => {
                        this[`week-graph-${thisWeekNum}`] = el;
                      }}
                      weekNum={thisWeekNum}
                    >
                      <WeekGraph
                        weekAvailabilities={weekAvailabilities}
                        weekNum={thisWeekNum}
                      />
                    </WeekGraphWrapper>
                  </WeekRow>
                );
              })}
            </ViewGrid>
          )}
        />
      </Switch>
    );
  }
}

const WeekRow = styled(Box)`
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 0.7fr repeat(7, 1fr);
  grid-template-areas:
    'week . . . . . . .'
    '${props =>
      `pre-${props.weekNum} pre-${props.weekNum} pre-${props.weekNum} pre-${props.weekNum} pre-${props.weekNum} pre-${props.weekNum} pre-${props.weekNum} pre-${props.weekNum}`}'
      ;
  padding-right: ${theme.space[1]}px;

  :last-child {
    margin-bottom: ${theme.space[11]}px;
  }

  @media (min-width: 600px) {
    padding-right: ${theme.space[3]}px;
  }
`;

FullCalendar.propTypes = propTypes;

export default withRouter(FullCalendar);
