import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Text, Button, Label, Heading } from '@freska/freska-ui';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { graphql, withApollo } from 'react-apollo';
import { CHANGE_PASSWORD } from '../../gql/mutations';
import LocalStorage from '../../utils/localStorage';

import { trackEvent } from '../../utils/tracking';
import ViewGrid from '../../components/Common/ViewGrid';
import NotificationBlock from '../../components/Common/NotificationBlock';
import ChangePasswordForm from '../../components/Forms/ChangePasswordForm';
import schema from '../../components/Forms/schemas/changePasswordSchema';
import validateData from '../../utils/validate';
import { space, sizes } from '../../theme/theme';
import logout from '../../utils/logout';
import FlexContainer from '../../components/Common/FlexContainer';

const propTypes = {
  changePassword: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  client: PropTypes.shape({}).isRequired,
};

const getUserEmail = () => LocalStorage.get('user-email');

class ProfileContainer extends PureComponent {
  state = {
    loading: false,
    success: false,
    error: null,
    errors: {},
    password: '',
    passwordConfirmation: '',
  };

  handleChange = (e, field) => {
    this.setState({ [field]: e.target.value });
  };

  validate = e => {
    const { password, passwordConfirmation } = this.state;
    e.preventDefault();

    trackEvent('Profile: Change password button clicked', {
      category: 'Account',
    });
    const variables = {
      password,
      passwordConfirmation,
    };

    validateData(variables, schema).then(res =>
      this.setState(
        { errors: res },
        () => Object.keys(res).length === 0 && this.onSubmit(variables)
      )
    );
  };

  onSubmit = variables => {
    const { changePassword } = this.props;

    this.setState({ loading: true }, () => {
      changePassword({ variables })
        .then(() => {
          this.setState({
            loading: false,
            success: true,
            error: null,
            password: '',
            passwordConfirmation: '',
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            success: false,
            error: error.graphQLErrors.some(
              ({ message }) => message === 'Wrong user input'
            )
              ? 'wrong_input'
              : 'default',
          });
        });
    });
  };

  render() {
    const {
      loading,
      success,
      error,
      errors,
      password,
      passwordConfirmation,
    } = this.state;
    const { history, client } = this.props;

    return (
      <ViewGrid>
        <StyledFlexContainer center column>
          {(success || !!error) && (
            <NotificationBlock hasError={!!error}>
              <FormattedMessage
                id={error ? `error.${error}` : 'profile.success'}
              />
            </NotificationBlock>
          )}
          <StyledCard p={3}>
            <Label>
              <FormattedMessage id="profile.authenticated_user" />
            </Label>
            <Text as="span">{getUserEmail()}</Text>
          </StyledCard>
          <StyledCard p={3}>
            <Heading level={3} mb={3}>
              <FormattedMessage id="profile.password.change_password" />
            </Heading>
            <ChangePasswordForm
              loading={loading}
              onSubmit={this.validate}
              password={password}
              passwordConfirmation={passwordConfirmation}
              onChange={this.handleChange}
              errors={errors}
            />
          </StyledCard>
          <LogoutButton
            variant="secondary"
            id="logout-btn"
            onClick={() => {
              logout({ history, client });
            }}
          >
            <FormattedMessage id="nav.logout" />
          </LogoutButton>
        </StyledFlexContainer>
      </ViewGrid>
    );
  }
}

const StyledFlexContainer = styled(FlexContainer)`
  max-width: ${sizes.width.bookingCardMaxWidth};
  width: 100%;
  margin: 0 auto;
`;

const StyledCard = styled(Card)`
  margin: 0 ${space.sm} ${space.md};
  width: calc(100% - calc(${space.sm} * 2));
`;

const LogoutButton = styled(Button)`
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  display: block;
`;

ProfileContainer.propTypes = propTypes;

export default graphql(CHANGE_PASSWORD, {
  name: 'changePassword',
})(withRouter(withApollo(ProfileContainer)));
