import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { isToday, parseISO } from 'date-fns';
import { Label, Text } from '@freska/freska-ui';

import BookingCard from './BookingCard';
import { sizes, space } from '../../theme/theme';

const propTypes = {
  date: PropTypes.string,
  bookings: PropTypes.arrayOf(PropTypes.object).isRequired,
  unconfirmed: PropTypes.bool,
};

const defaultProps = {
  date: '',
  unconfirmed: false,
};

function BookingDayGroup({ date, unconfirmed, bookings }) {
  return (
    <DayGroup unconfirmed={unconfirmed}>
      {date && (
        <Fragment>
          <DateHeadline as="span" size="large" bold>
            <FormattedDate
              value={date}
              month="short"
              day="numeric"
              weekday="long"
            />
          </DateHeadline>
          {isToday(parseISO(date)) && (
            <StyledLabel color="secondary">
              &bull;&nbsp;&nbsp;
              <FormattedMessage id="bookings.today" />
            </StyledLabel>
          )}
        </Fragment>
      )}

      <GridContainer>
        {bookings.map(booking => (
          <BookingCard
            data={booking}
            key={booking.id}
            unconfirmed={unconfirmed || booking.isUnconfirmed}
          />
        ))}
      </GridContainer>
    </DayGroup>
  );
}

const DayGroup = styled.section`
  margin: ${space.default} auto 0;
  max-width: ${sizes.width.bookingCardMaxWidth};
  width: 100%;

  ${props =>
    props.unconfirmed &&
    `
      margin-bottom: 0;
      padding-bottom: ${space.sm};
    `};

  :first-child {
    margin-top: ${space.sm};
  }
`;

const GridContainer = styled.div`
  margin: 0 auto;
  padding: 0 ${space.default};
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
`;

const DateHeadline = styled(Text)`
  display: inline-block;
  margin: 0 ${space.default};
`;

const StyledLabel = styled(Label)`
  display: inline;
`;

BookingDayGroup.propTypes = propTypes;
BookingDayGroup.defaultProps = defaultProps;

export default BookingDayGroup;
