import React, {
  createContext,
  useEffect,
  useMemo,
  useContext,
  useState,
} from 'react';
import { node } from 'prop-types';
import * as serviceWorker from './serviceWorker';

const propTypes = {
  children: node.isRequired,
};

export const ServiceWorkerContext = createContext();

function getOnlineStatus() {
  return typeof navigator !== 'undefined' &&
    typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;
}

export const ServiceWorkerProvider = ({ children }) => {
  const [waitingServiceWorker] = useState(null);
  const [isUpdateAvailable] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(getOnlineStatus());
  const goOnline = () => setOnlineStatus(true);
  const goOffline = () => setOnlineStatus(false);

  useEffect(() => {
    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    serviceWorker.register();

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  useEffect(() => {
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  }, [waitingServiceWorker]);

  const value = useMemo(
    () => ({
      onlineStatus,
      isUpdateAvailable,
      updateAssets: () => {
        if (waitingServiceWorker) {
          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
        }
      },
    }),
    [isUpdateAvailable, waitingServiceWorker, onlineStatus]
  );

  return (
    <ServiceWorkerContext.Provider value={value}>
      {children}
    </ServiceWorkerContext.Provider>
  );
};

ServiceWorkerProvider.propTypes = propTypes;

export const useServiceWorker = () => {
  return useContext(ServiceWorkerContext);
};
