import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text, IconExpandMore, IconExpandLess } from '@freska/freska-ui';

import { space } from '../../theme/theme';
import FlexContainer from '../Common/FlexContainer';

const propTypes = {
  clickHandler: PropTypes.func.isRequired,
  week: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  shouldOpen: PropTypes.bool,
};

const defaultProps = {
  isOpen: false,
  shouldOpen: true,
};

class WeekButton extends PureComponent {
  render() {
    const { clickHandler, week, isOpen, shouldOpen } = this.props;

    return (
      <WeekNumberButton onClick={shouldOpen ? clickHandler : undefined}>
        <CalendarDayFlexContainer
          column
          alignH="flex-start"
          alignV="flex-start"
        >
          <MonthLabel as="span" size="small" align="center">
            &nbsp;
          </MonthLabel>
          <WeekNumber as="span" size="smaller" align="center">
            {week}
          </WeekNumber>
          <ExpandIconFlexContainer>
            {shouldOpen &&
              (isOpen ? (
                <IconExpandLess size={16} />
              ) : (
                <IconExpandMore size={16} />
              ))}
          </ExpandIconFlexContainer>
        </CalendarDayFlexContainer>
      </WeekNumberButton>
    );
  }
}

const WeekNumberButton = styled.button`
  background: none;
  border: 0;
  grid-area: 'week';
`;

const MonthLabel = styled(Text)`
  display: inline-block;
  width: 100%;
  height: 19px;
  margin-top: ${space.xs};
`;

const WeekNumber = styled(Text)`
  display: inline-block;
  width: 100%;
  padding-top: 4px;
`;

const CalendarDayFlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`;

const ExpandIconFlexContainer = styled(FlexContainer)`
  width: 100%;
  height: 16px;
  margin: ${space.xs} 0 0;
  display: inline-flex;
  justify-content: center;
`;

WeekButton.propTypes = propTypes;
WeekButton.defaultProps = defaultProps;

export default WeekButton;
