import React, { useState } from 'react';
import { string, bool } from 'prop-types';
import { theme, typography, Checkbox } from '@freska/freska-ui';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { listItem as defaultListItem } from 'react-markdown/lib/renderers';
import { v4 as uuidv4 } from 'uuid';

const propTypes = {
  content: string.isRequired,
};

const renderListItemProps = {
  checked: bool,
};

function RenderListItem(props) {
  const [checked, setChecked] = useState(props.checked);

  if (props.checked !== null && props.checked !== undefined) {
    const newKey = uuidv4();
    return (
      <Checkbox
        onChange={() => setChecked(!checked)}
        checked={checked}
        name={newKey}
        id={newKey}
        label={props.children}
        mb={1}
      />
    );
  }
  return defaultListItem(props);
}

const ParseMarkdown = ({ content }) => {
  return (
    <StyledReactMarkdown
      source={content}
      parserOptions={{ commonmark: true }}
      renderers={{ listItem: RenderListItem }}
    />
  );
};

const StyledReactMarkdown = styled(ReactMarkdown)`
  & * {
    ${typography.fontRegular}
  }
  & > p {
    margin-bottom: ${theme.space[1]}px;
  }
  & h1 {
    ${typography.heading3}
  }
  & h2 {
    ${typography.fontLarge}
    ${typography.fontBold}
  }
  & h3,
  & h4,
  & h5,
  & h6 {
    ${typography.fontLarge}
  }
  & a {
    ${typography.link}
  }
  & em {
    font-style: italic;
  }
  & ul,
  & ol {
    margin-bottom: ${theme.space[1]}px;
    list-style-position: outside;
  }
  & ul {
    list-style-type: disc;
  }
  & ul > li,
  ol > li {
    margin-left: ${theme.space[3]}px;
  }
  & ul > div input[type='checkbox'] {
    margin-right: ${theme.space[1]}px;
  }
  & ol {
    list-style-type: decimal;
  }
  & code {
    background-color: ${theme.colors.greyLight};
    display: inline-block;
    padding: 0 4px;
    font-family: monospace;
  }
  & pre {
    background-color: ${theme.colors.greyLight};
    width: 100%;
    padding: ${theme.space[1]}px;
    margin: ${theme.space[1]}px 0;
    white-space: pre-wrap;
  }
  & blockquote {
    background-color: ${theme.colors.greyMed}48;
    border-left: 4px solid ${theme.colors.greyMed};
    padding: ${theme.space[1]}px ${theme.space[1]}px;
    margin: ${theme.space[1]}px 0;
    width: 100%;
  }
`;

RenderListItem.propTypes = renderListItemProps;
ParseMarkdown.propTypes = propTypes;

export default ParseMarkdown;
