import React from 'react';
import { shape, string } from 'prop-types';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import { Box, Spinner, theme } from '@freska/freska-ui';
import { GET_NEWS_ITEMS } from '../../gql/queries';
import ViewGrid from '../../components/Common/ViewGrid';
import withUser from '../../utils/withUser';
import { space } from '../../theme/theme';
import NewsCard from '../../components/Contentful/NewsCard';
import ContentfulEntry from '../../components/Contentful/ContentfulEntry';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';

const propTypes = {
  match: shape({ params: shape({ slug: string }) }),
};

function News({ match: { params } }) {
  const { loading, data, error } = useQuery(GET_NEWS_ITEMS);

  if (loading) {
    return (
      <ViewGrid>
        <Box p={3}>
          <Spinner />
        </Box>
      </ViewGrid>
    );
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (params.slug) {
    const item = data.getNewsItems.find(item => params.slug === item.slug);
    return <ContentfulEntry item={item} slug="news" />;
  }
  return (
    <ViewGrid>
      <GridContainer>
        {data.getNewsItems.map(item => (
          <StyledLink to={`/news/${item.slug}`} key={item.slug}>
            <NewsCard item={item} />
          </StyledLink>
        ))}
      </GridContainer>
    </ViewGrid>
  );
}

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  grid-gap: ${space.sm};
  margin-bottom: ${space.lg};

  a,
  a:active,
  a:visited,
  a:link {
    color: ${theme.colors.primary};
  }

  &:last-child {
    margin-bottom: ${space.sm};
  }
`;

News.propTypes = propTypes;

export default withUser(News);
