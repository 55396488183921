import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Text, theme, IconDrawerHandle } from '@freska/freska-ui';
import { space, sizes } from '../../theme/theme';

const propTypes = {
  children: PropTypes.node.isRequired,
  collapsedHeight: PropTypes.number,
  isExpandable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  drawerTitle: PropTypes.string,
};

const defaultProps = {
  collapsedHeight: 100,
  drawerTitle: null,
  isExpanded: false,
  isExpandable: true,
};

class Drawer extends Component {
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    isExtended: !this.props.isExpanded,
  };

  handleExpand = () => {
    this.setState(prevState => ({
      isExtended: !prevState.isExtended,
    }));
  };

  render() {
    const { isExtended } = this.state;
    const { children, isExpandable, collapsedHeight, drawerTitle } = this.props;
    return (
      <DrawerContainer
        isExtended={isExtended}
        collapsedHeight={collapsedHeight}
      >
        {isExpandable && (
          <DrawerHandle onClick={() => this.handleExpand()} type="button">
            <StyledIconDrawerHandle isExtended={isExtended} />
          </DrawerHandle>
        )}
        <ContentContainer>
          {drawerTitle && (
            <Text as="span" size="large" bold mb={3} mt={1} px={3}>
              {drawerTitle}
            </Text>
          )}
          {children}
        </ContentContainer>
      </DrawerContainer>
    );
  }
}

const DrawerContainer = styled.section`
  background: ${theme.colors.white};
  border-radius: 10px 10px 0 0;
  bottom: 0;
  box-shadow: ${theme.elevation.top};
  max-height: ${props =>
    props.isExtended ? `${props.collapsedHeight}px` : '100%'};
  margin-top: ${space.md};
  overflow: ${props => (props.isExtended ? 'hidden' : 'auto')};
  padding: 0 0 ${space.default};
  position: fixed;
  z-index: 2;
  width: 100%;
  max-width: ${sizes.width.maxContent};
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: ${sizes.width.bookingCardMaxWidth};
  padding: 0;
`;

const DrawerHandle = styled.button`
  background: none;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  display: block;
  padding: ${space.sm};
  width: 100%;
`;

const StyledIconDrawerHandle = styled(IconDrawerHandle)`
  transform: ${props => (props.isExtended ? 'scaleY(-1)' : 'scaleY(1)')};
  transition: transform 275ms 200ms;
`;

Drawer.propTypes = propTypes;
Drawer.defaultProps = defaultProps;

export default Drawer;
