import React from 'react';
import { number, string, oneOfType } from 'prop-types';
import {
  IconLiquids,
  IconPets,
  IconLocation,
  IconAddCircle,
  IconNote,
} from '@freska/freska-ui';
import styled from 'styled-components';

import { getAccessStatusIcon } from '../../utils/bookingsHelper';
import { space } from '../../theme/theme';
import { detailSummaryType } from '../../types';

const propTypes = {
  services: detailSummaryType.isRequired,
  size: number,
  marginRight: oneOfType([number, string]),
};

const defaultProps = {
  size: 16,
  marginRight: 2,
};

export const checkForPets = data =>
  data.hasDogs || data.hasCats || data.hasOtherPets;

function hasServiceIconListData({ services }) {
  const hasData =
    services.needsCleaningSupplies ||
    !!services.additionalServices.length ||
    services.customerNotes ||
    checkForPets(services.pets) ||
    services.addressNotes;

  return hasData;
}

function ServicesIconList({ services, size, marginRight }) {
  return (
    <IconList size={size} hasData={hasServiceIconListData({ services })}>
      {services.needsCleaningSupplies && (
        <li>
          <IconLiquids size={size} mr={marginRight} />
        </li>
      )}
      {!!services.additionalServices.length && (
        <li>
          <IconAddCircle size={size} mr={marginRight} />
        </li>
      )}
      {services.customerNotes && (
        <li>
          <IconNote size={size} mr={marginRight} />
        </li>
      )}
      {checkForPets(services.pets) && (
        <li>
          <IconPets size={size} mr={marginRight} />
        </li>
      )}
      {services.addressNotes && (
        <li>
          <IconLocation size={size} mr={marginRight} />
        </li>
      )}
      {services.accessStatus !== 'KeyNotAvailable' && (
        <li>{getAccessStatusIcon(services.accessStatus, size)}</li>
      )}
    </IconList>
  );
}

const IconList = styled.ul`
  align-items: center;
  display: ${props => (props.hasData ? 'flex' : 'none')};
  list-style: none;
  margin-bottom: ${space.sm};

  height: ${props => props.size}px;
  padding: 0;
`;

ServicesIconList.propTypes = propTypes;
ServicesIconList.defaultProps = defaultProps;

export default ServicesIconList;
