import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Box, Text, theme } from '@freska/freska-ui';
import InfoIcon from '@material-ui/icons/Info';

const propTypes = {
  messageId: string.isRequired,
};

export function InfoBox({ messageId, ...rest }) {
  return (
    <StyledBox {...rest}>
      <InfoIcon style={{ fill: '#8bc8db', fontSize: '20px' }} />
      <Text color="black" size="small" ml={1}>
        <FormattedMessage id={messageId} />
      </Text>
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  padding: 12px;
  margin-top: ${theme.space[3]}px;
  display: flex;

  background-color: #e9f5f9;
  border: 1px solid #8bc8db;
  border-radius: ${theme.space[1]}px;
`;

InfoBox.propTypes = propTypes;
