import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Text, theme, IconDrawerHandle } from '@freska/freska-ui';
import { space, sizes } from '../../theme/theme';

const propTypes = {
  children: PropTypes.node.isRequired,
  handleToggleExpand: PropTypes.func,
  isExpanded: PropTypes.bool,
  isExpandable: PropTypes.bool,
  drawerTitle: PropTypes.string,
};

const defaultProps = {
  drawerTitle: null,
  isExpandable: true,
  isExpanded: false,
  handleToggleExpand: () => {},
};

const BookingsDrawer = ({
  children,
  isExpandable,
  drawerTitle,
  handleToggleExpand,
  isExpanded,
}) => (
  <DrawerContainer>
    {isExpandable ? (
      <DrawerHandle onClick={handleToggleExpand} type="button">
        <StyledIconDrawerHandle isExpanded={isExpanded} />
      </DrawerHandle>
    ) : (
      <StyledSpace />
    )}
    <ContentContainer>
      {drawerTitle && (
        <Text as="p" px={3} mb={1} size="large" bold>
          {drawerTitle}
        </Text>
      )}
      {children}
    </ContentContainer>
  </DrawerContainer>
);

const DrawerContainer = styled.section`
  background: ${theme.colors.white};
  border-radius: 10px 10px 0 0;
  box-shadow: ${theme.elevation.top};
  padding: 0 0 ${space.sm};
  z-index: 2;
  flex-grow: 1;
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: ${sizes.width.bookingCardMaxWidth};
  padding: 0;
`;

const DrawerHandle = styled.button`
  background: none;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  display: block;
  padding: ${space.sm};
  width: 100%;
`;

const StyledSpace = styled.div`
  background: none;
  border-radius: 10px 10px 0 0;
  display: block;
  padding: ${space.sm};
  width: 100%;
`;

const StyledIconDrawerHandle = styled(IconDrawerHandle)`
  transform: ${props => (props.isExpanded ? 'scaleY(-1)' : 'scaleY(1)')};
  transition: transform 275ms 200ms;
`;

BookingsDrawer.propTypes = propTypes;
BookingsDrawer.defaultProps = defaultProps;

export default BookingsDrawer;
