import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Text, IconArrowBack } from '@freska/freska-ui';
import { v4 as uuidv4 } from 'uuid';
import { space } from '../../theme/theme';
import FlexContainer from './FlexContainer';

const propTypes = {
  captionIntlId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

const defaultProps = {
  onClick: undefined,
  id: `back-link-${uuidv4()}`,
};

const BackLink = ({ onClick, id, captionIntlId }) => (
  <BackLinkButton onClick={onClick} id={id}>
    <StyledFlexContainer alignH="flex-start" alignV="flex-start" nowrap>
      <IconArrowBack size={24} color="primary" mr={space.sm} />
      <Text as="span" color="primary">
        <FormattedMessage id={captionIntlId} />
      </Text>
    </StyledFlexContainer>
  </BackLinkButton>
);

const BackLinkButton = styled.button`
  background: none;
  margin-bottom: ${space.sm};
  width: 100%;
  display: block;
`;
const StyledFlexContainer = styled(FlexContainer)`
  height: 24px;
`;

BackLink.propTypes = propTypes;
BackLink.defaultProps = defaultProps;

export default BackLink;
