import React from 'react';
import styled from 'styled-components';
import { Text, IconWindow, typography } from '@freska/freska-ui';
import { FormattedMessage } from 'react-intl';

import { space } from '../../theme/theme';
import DataBlock from '../Common/DataBlock';
import { windowCleaningDetailsType } from '../../types';

const propTypes = {
  windowCleaningDetails: windowCleaningDetailsType.isRequired,
};

function showWindowCleaningDetail(labelId, classString, windowCleaningDetail) {
  return (
    <div>
      <Text size="small" bold as="p" mb={space.xs}>
        <FormattedMessage id={labelId} />
      </Text>
      <Text as="span" size="small" className={classString}>
        {windowCleaningDetail || '-'}
      </Text>
    </div>
  );
}

const BookingDetailsWindowCleaningNotes = ({ windowCleaningDetails }) => (
  <DataBlock
    Icon={IconWindow}
    title="bookings.card.window_cleaning_notes"
    hasContent={!!windowCleaningDetails}
  >
    <WindowCleaningDetailsGrid>
      {showWindowCleaningDetail(
        'bookings.details.window_cleaning.single_windows',
        'windowCleaningSingleWindows',
        windowCleaningDetails.single_windows
      )}
      {showWindowCleaningDetail(
        'bookings.details.window_cleaning.window_groups',
        'windowCleaningWindowGroups',
        windowCleaningDetails.window_groups
      )}
      {showWindowCleaningDetail(
        'bookings.details.window_cleaning.ventilation_windows',
        'windowCleaningVentilationWindows',
        windowCleaningDetails.ventilation_windows
      )}
      {showWindowCleaningDetail(
        'bookings.details.window_cleaning.number_of_layers',
        'windowCleaningNumberOfLayers',
        windowCleaningDetails.number_of_layers
      )}
      <div>
        <Text size="small" bold as="p" mb={space.xs}>
          <FormattedMessage id="bookings.details.window_cleaning.balcony" />
        </Text>
        <Text size="small" className="windowCleaningBalcony">
          {windowCleaningDetails.balcony &&
          windowCleaningDetails.balcony_panes ? (
            <FormattedMessage
              id="bookings.details.window_cleaning.balcony_value"
              values={{ num: windowCleaningDetails.balcony_panes }}
            />
          ) : (
            <FormattedMessage id="bookings.details.window_cleaning.no_balcony" />
          )}
        </Text>
      </div>
      <div>
        <Text size="small" bold as="p" mb={space.xs}>
          <FormattedMessage id="bookings.details.window_cleaning.blinds" />
        </Text>
        <Text as="span" size="small" className="windowCleaningBlinds">
          {windowCleaningDetails.blinds ? (
            <FormattedMessage id="bookings.details.window_cleaning.included" />
          ) : (
            <FormattedMessage id="bookings.details.window_cleaning.not_included" />
          )}
        </Text>
      </div>
    </WindowCleaningDetailsGrid>
  </DataBlock>
);

const WindowCleaningDetailsGrid = styled.section`
  display: grid;
  ${typography.fontSmall()};
  grid-template-columns: repeat(
    auto-fill,
    minmax(min-content, calc(50% - ${space.md}))
  );
  grid-gap: ${space.sm};

  span:nth-child(odd) {
    display: block;
  }
`;

BookingDetailsWindowCleaningNotes.propTypes = propTypes;

export default BookingDetailsWindowCleaningNotes;
