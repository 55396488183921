import React, { useEffect, useRef } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import ContentContainer from './ContentContainer';
import AvailabilityContainer from '../../containers/AvailabilityContainer/AvailabilityContainer';
import KeysContainer from '../../containers/KeysContainer/KeysContainer';
import InvoicesContainer from '../../containers/InvoicesContainer/InvoicesContainer';
import BookingsContainer from '../../containers/BookingsContainer/BookingsContainer';
import News from '../../containers/ContentfulContainer/News';
import Support from '../../containers/ContentfulContainer/Support';
import Dashboard from '../../containers/Dashboard/Dashboard';
import WorkingAreasContainer from '../../containers/WorkingAreasContainer/WorkingAreasContainer';
import RatingsContainer from '../../containers/RatingsContainer/RatingsContainer';
import ProfileContainer from '../../containers/ProfileContainer/ProfileContainer';
import BookingDetails from '../Bookings/BookingDetails';
import InvoiceDetails from '../Invoices/InvoiceDetails';
import NotFound from '../NotFound/NotFound';
import LocalStorage from '../../utils/localStorage';
import { PrivateRoute } from '../../utils/routes';
import { useServiceWorker } from '../../useServiceWorker';
import { trackEvent } from '../../utils/tracking';
import AppClosed from './AppClosed';
import { useSnackbar } from 'notistack';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from '@freska/freska-ui';


const SiteGrid = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { onlineStatus } = useServiceWorker();
  const intl = useIntl();
  const prevOnlineStatus = useRef();

  useEffect(() => {
    const actionPageRefresh = () => (
      <Link
        as="button"
        color="primary"
        size="small"
        underline
        onClick={() => {
          trackEvent('Connectivity restored (user refreshed page)', {
            category: 'Core',
          });
          window.location.reload();
        }}
      >
        <FormattedMessage id="snackbar.action.refresh" />
      </Link>
    );
    if (onlineStatus === false) {
      closeSnackbar('online');
      enqueueSnackbar(
        intl.formatMessage({
          id: 'snackbar.offline',
        }),
        {
          key: 'offline',
          variant: 'info',
          persist: true,
        }
      );
      trackEvent('Connectivity lost (user went offline)', {
        category: 'Core',
      });
    }
    if (onlineStatus === true) {
      closeSnackbar('offline');
    }
    if (prevOnlineStatus.current === false && onlineStatus === true) {
      enqueueSnackbar(
        intl.formatMessage({
          id: 'snackbar.online',
        }),
        {
          autoHideDuration: 3000,
          key: 'online',
          variant: 'success',
          action: actionPageRefresh,
        }
      );

      trackEvent('Connectivity restored (user came back online)', {
        category: 'Core',
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    prevOnlineStatus.current = onlineStatus;
  }, [prevOnlineStatus, onlineStatus, intl, enqueueSnackbar, closeSnackbar]);

  // useEffect(() => {
  //   const actionUpdateAvailable = () => (
  //     <Link
  //       as="button"
  //       color="primary"
  //       size="small"
  //       underline
  //       onClick={() => {
  //         updateAssets();
  //         trackEvent('Service worker updated by user on new version', {
  //           category: 'Core',
  //         });
  //       }}
  //     >
  //       <FormattedMessage id="snackbar.action.update_now" />
  //     </Link>
  //   );
  //   if (isUpdateAvailable) {
  //     enqueueSnackbar(
  //       intl.formatMessage({
  //         id: 'snackbar.new_version',
  //       }),
  //       {
  //         key: 'newServiceWorkerVersionAvailable',
  //         variant: 'info',
  //         persist: true,
  //         action: actionUpdateAvailable,
  //       }
  //     );
  //   }
  // }, [isUpdateAvailable, enqueueSnackbar, intl, updateAssets]);

  if (process.env.NODE_ENV === 'production') {
    return (
      <AppClosed />
    )
  }

  return (
    <ContentContainer>
      <Switch>
        <PrivateRoute exact path="/" render={props => (
          <Dashboard {...props} />
        )} />
        <PrivateRoute path="/bookings/:type?" component={BookingsContainer} exact />
        <PrivateRoute path="/index.html" exact>
          <Redirect to="/" />
        </PrivateRoute>
        <PrivateRoute path="/booking/:tab" component={BookingDetails} />

        {/** TODO: Remove me after some time (not in use but old pages can still be open) */}
        <PrivateRoute path="/bookings/w/:date" component={BookingsContainer} />
        <PrivateRoute path="/keys" component={KeysContainer} />
        <PrivateRoute path="/areas" component={WorkingAreasContainer} />
        <PrivateRoute path="/ratings" component={RatingsContainer} />
        <PrivateRoute path="/profile" component={ProfileContainer} />
        <PrivateRoute
          path="/latest-news/:slug?"
          render={props => (
            <Dashboard {...props} />
          )}
        />
        <PrivateRoute
          path="/support/:slug?"
          render={props => (
            <Support {...props} />
          )}
        />
        <PrivateRoute
          path="/news/:slug?"
          render={props => (
            <News {...props} />
          )}
        />
        {!LocalStorage.get('is-employed') && (
          <PrivateRoute
            path="/availability"
            component={AvailabilityContainer}
          />
        )}
        {!LocalStorage.get('is-employed') && (
          <PrivateRoute exact path="/invoices" component={InvoicesContainer} />
        )}
        {!LocalStorage.get('is-employed') && (
          <PrivateRoute path="/invoices/:id" component={InvoiceDetails} />
        )}
        <PrivateRoute component={NotFound} />
      </Switch>
    </ContentContainer>
  );
};

export default SiteGrid;
