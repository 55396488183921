const set = (id, data) => {
  localStorage.setItem(id, JSON.stringify(data));
};

const get = id => {
  try {
    const data =
      localStorage[id] === undefined ? undefined : JSON.parse(localStorage[id]);
    return data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Unable to parse local storage');
    return false;
  }
};

const remove = id => {
  localStorage.removeItem(id);
};

export default {
  set,
  get,
  remove,
};
