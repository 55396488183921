import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Input, Checkbox, theme, Text } from '@freska/freska-ui';
import { format } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { func, shape, bool, string } from 'prop-types';

import Toggle from '../Common/Toggle';
import { checkForError } from '../../utils/validate';
import FlexContainer from '../Common/FlexContainer';

const propTypes = {
  handleDateChange: func.isRequired,
  handleOneOffSwitch: func.isRequired,
  handleContinuousCheck: func.isRequired,
  errors: shape({}),
  isContinuous: bool.isRequired,
  startValue: string.isRequired,
  isOneOffChecked: bool.isRequired,
  endValue: string,
};

const defaultProps = {
  endValue: null,
  errors: {},
};

function DateRange({
  handleDateChange,
  errors,
  handleOneOffSwitch,
  handleContinuousCheck,
  isContinuous,
  startValue,
  endValue,
  isOneOffChecked,
}) {
  const intl = useIntl();
  return (
    <Fragment>
      <RangeWrapper>
        <StyledInput
          id="startTime"
          label={intl.formatMessage({ id: 'availability.start_from' })}
          placeholder={intl.formatMessage({ id: 'availability.start_from' })}
          type="date"
          min={format(new Date(), 'yyyy-MM-dd')}
          value={startValue}
          onChange={e => handleDateChange(e, 'startTime')}
          error={checkForError(errors, 'valid_from')}
          hasBorder
          shrink
        />
        <FlexContainer row center>
          <Text as="p" mx="4px" align="center">
            &ndash;
          </Text>
          <Checkbox
            id="continuous-check"
            onChange={handleContinuousCheck}
            checked={!isContinuous}
            name="Continuous"
            label=""
          />
          {isContinuous ? (
            <Text mb={0} color="secondary">
              <FormattedMessage id="availability.continuous" />
            </Text>
          ) : (
            <StyledInput
              id="endTime"
              label={intl.formatMessage({ id: 'availability.end_on' })}
              type="date"
              value={endValue || format(new Date(), 'yyyy-MM-dd')}
              onChange={e => handleDateChange(e, 'endTime')}
              error={checkForError(errors, 'valid_to')}
              hasBorder
            />
          )}
        </FlexContainer>
      </RangeWrapper>
      <ListItem alignH="space-between" alignV="center">
        <FormattedMessage id="availability.only_one_offs" />
        <Toggle
          id="one-off-check"
          handleChange={handleOneOffSwitch}
          isChecked={isOneOffChecked}
        />
      </ListItem>
    </Fragment>
  );
}

const RangeWrapper = styled.div`
  display: inline-flex;
  margin-bottom: ${theme.space[2]}px;
`;

const StyledInput = styled(Input)`
  width: 160px;
  @media (max-width: 375px) {
    width: 136px;
  }

  input {
    padding-left: 8px;
    padding-right: 8px;

    @media (max-width: 375px) {
      font-size: 17px;
    }
  }

  input::-webkit-clear-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media (min-width: 480px) {
    width: 180px;
  }
`;

const ListItem = styled(FlexContainer)`
  border-bottom: solid 1px ${theme.colors.greyMed};
  padding-bottom: ${theme.space[2]}px;
`;

DateRange.propTypes = propTypes;
DateRange.defaultProps = defaultProps;

export default DateRange;
