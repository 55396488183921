import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Text, Card, Label } from '@freska/freska-ui';
import { Link } from 'react-router-dom';

import KeyCard from './KeyCard';

import { sizes, space } from '../../theme/theme';

const propTypes = {
  keys: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  status: PropTypes.string.isRequired,
};

const KeysList = ({ keys, status }) => (
  <GroupSection>
    <Label pl={3} mb={2}>
      <FormattedMessage id={`keys.section_headers.${status}`} />
    </Label>
    <KeysSection mx={space.xs}>
      {keys.length ? (
        keys.map(key => (
          <StyledLink to={`/keys/${key.id}`} key={key.id}>
            <KeyCard key={key.id} keyInfo={key} status={status} />
          </StyledLink>
        ))
      ) : (
        <Text as="span" color="secondary">
          <FormattedMessage id="keys.no_keys" />
        </Text>
      )}
    </KeysSection>
  </GroupSection>
);

const GroupSection = styled.section`
  margin-bottom: ${space.default};
  max-width: ${sizes.width.invoiceCardMaxWidth};

  :last-child {
    margin-bottom: ${space.lg};
  }
`;

const KeysSection = styled(Card)`
  a:last-child {
    section {
      margin-bottom: 0;
    }
  }
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

KeysList.propTypes = propTypes;

export default KeysList;
