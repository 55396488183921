import React from 'react';
import { useQuery } from 'react-apollo';
import { Spinner } from '@freska/freska-ui'
import { shape, string } from 'prop-types';
import { addDays } from 'date-fns'

import ContentfulEntry from '../../components/Contentful/ContentfulEntry';
import ViewGrid from '../../components/Common/ViewGrid';
import { GET_BOOKINGS, GET_NEWS_ITEMS, GET_NEXT_BOOKING, GET_SERVICE_WORKER_KEYS } from '../../gql/queries';
import withUser from '../../utils/withUser';

import ActionRequired from './ActionRequired';
import LatestNews from './LatestNews';
import NextBooking from './NextBooking';

const propTypes = {
  match: shape({ params: shape({ slug: string }) }),
};

const nextBookingUntil = addDays(new Date(), 3);

function Dashboard({ match: { params } }) {
  const { loading: loadingNews, data, error: errorNews } = useQuery(GET_NEWS_ITEMS, {
    variables: {
      limit: 3,
    }
  });
  const { loading: loadingUnconfirmed, data: unconfirmedBookingsData } = useQuery(GET_BOOKINGS, { variables: { bookingStatus: 'uncompleted' } });
  const { loading: loadingNextBooking, data: nextBookingData, error: nextBookingError } = useQuery(GET_NEXT_BOOKING, { variables: { endDate: nextBookingUntil } });
  const { loading: loadingKeys, data: serviceWorkerKeysData } = useQuery(GET_SERVICE_WORKER_KEYS, { variables: { isActionRequired: true } });

  if (params.slug) {
    if (loadingNews) {
      return <Spinner mt={3} />;
    }

    const item = data?.getNewsItems.find(item => params.slug === item.slug);
    if (item) {
      return <ContentfulEntry item={item} slug="news" />;
    }
  }

  return (
    <ViewGrid>
      <NextBooking
        booking={nextBookingData?.getNextBooking}
        isLoading={loadingNextBooking}
        isError={!!nextBookingError}
      />
      <ActionRequired
        bookings={unconfirmedBookingsData?.getBookings[0]?.bookings}
        keys={serviceWorkerKeysData?.getServiceWorkerKeys}
        isLoading={loadingKeys || loadingUnconfirmed}
      />
      <LatestNews loading={loadingNews} items={data?.getNewsItems} error={!!errorNews} />
    </ViewGrid>
  );
}

Dashboard.propTypes = propTypes;

export default withUser(Dashboard);