import { getHours, getMinutes, parseISO } from 'date-fns';

export const getStringTime = (time, withColon = false, round = true) => {
  let hoursCopy = getHours(parseISO(time));
  let minutesCopy = getMinutes(parseISO(time));

  if (round) {
    if (minutesCopy % 15 !== 0) {
      if (minutesCopy > 45) {
        minutesCopy = '0';
        hoursCopy += 1;
      } else {
        minutesCopy = 15 * Math.floor(minutesCopy / 15);
      }
    }
  }
  if (minutesCopy < 10) {
    minutesCopy = `0${minutesCopy}`;
  }
  if (hoursCopy < 10) {
    hoursCopy = `0${hoursCopy}`;
  }
  if (withColon) {
    return `${hoursCopy}:${minutesCopy}`;
  }
  return `${hoursCopy}${minutesCopy}`;
};

export const getClosestMonday = () => {
  const closestMonday = new Date();
  closestMonday.setDate(
    closestMonday.getDate() + ((((7 - closestMonday.getDay()) % 7) + 1) % 7)
  );
  return closestMonday.toISOString().substring(0, 10);
};
