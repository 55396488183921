import React, { useState } from 'react';
import { string, shape, number } from 'prop-types';
import {
  TextArea,
  IconEdit,
  Card,
  Button,
  Box,
  Spinner,
  theme,
  typography,
  IconClose,
  Text,
} from '@freska/freska-ui';
import styled, { css } from 'styled-components';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { UPDATE_WORKER_NOTE, DELETE_WORKER_NOTE } from '../../gql/mutations';
import { useMutation } from 'react-apollo';
import { GET_WORKER_NOTES_BY_BOOKING_ID } from '../../gql/queries';
import NotificationBlock from '../Common/NotificationBlock';
import ParseMarkdown from './ParseMarkdown';
import ConfirmationModal from '../Common/ConfirmationModal';
import { trackEvent } from '../../utils/tracking';

const propTypes = {
  note: shape({ id: string, content: string, updated_at: string }).isRequired,
  bookingId: number.isRequired,
};

const BookingDetailsWorkerNote = ({ note, bookingId }) => {
  function handleUpdateWorkerNote() {
    updateWorkerNote({
      variables: {
        id: note.id,
        content: textAreaValue,
        bookingId,
        creatorId: note.creator_id,
      },
      refetchQueries: [
        {
          query: GET_WORKER_NOTES_BY_BOOKING_ID,
          variables: {
            bookingId,
          },
        },
      ],
      awaitRefetchQueries: true,
    }).then(() => {
      trackEvent('Worker note edited', {
        category: 'Booking details',
      });
      setEditable(false);
    });
  }

  function handleDeleteWorkingNote() {
    deleteWorkerNote({
      variables: {
        id: note.id,
        bookingId,
        creatorId: note.creator_id,
      },
      refetchQueries: [
        {
          query: GET_WORKER_NOTES_BY_BOOKING_ID,
          variables: {
            bookingId,
          },
        },
      ],
      awaitRefetchQueries: true,
    }).then(() => {
      trackEvent('Worker note deleted', {
        category: 'Booking details',
      });
    });
  }

  const [editable, setEditable] = useState(false);
  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
  ] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState(note.content);
  const [
    updateWorkerNote,
    { loading: updateWorkerNoteLoading, error: updateWorkerNoteError },
  ] = useMutation(UPDATE_WORKER_NOTE);
  const [
    deleteWorkerNote,
    { loading: deleteWorkerNoteLoading, error: deleteWorkerNoteError },
  ] = useMutation(DELETE_WORKER_NOTE);
  if (deleteWorkerNoteLoading)
    return (
      <LoadingBox>
        <Spinner size={24} />
      </LoadingBox>
    );

  if (deleteWorkerNoteError) {
    return (
      <NotificationBlock hasError>
        <FormattedMessage id="bookings.details.instructions.error_deleting_worker_note" />
      </NotificationBlock>
    );
  }

  return (
    <ViewNoteGrid editable={editable}>
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          onConfirm={() => handleDeleteWorkingNote()}
          onClose={() => setShowDeleteConfirmationModal(false)}
          titleId="bookings.details.instructions.delete_confirmation_modal.title"
          messageId="bookings.details.instructions.delete_confirmation_modal.message"
          buttonCaptionId="bookings.details.instructions.delete_confirmation_modal.button_caption"
        />
      )}
      {note.canUpdate && editable ? (
        <Card variant="secondary" p={2}>
          {updateWorkerNoteError && (
            <NotificationBlock hasError>
              <FormattedMessage id="bookings.details.instructions.error_saving_worker_note" />
            </NotificationBlock>
          )}
          <StyledTextArea
            hideLabel
            label="Worker note"
            name="editWorkerNote"
            value={textAreaValue}
            onChange={e => setTextAreaValue(e.target.value)}
            mb={1}
            rows={8}
            disabled={updateWorkerNoteLoading}
          />
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="row"
          >
            <Button
              size="small"
              disabled={updateWorkerNoteLoading}
              onClick={handleUpdateWorkerNote}
              mr={1}
            >
              {updateWorkerNoteLoading ? (
                <Spinner size={19} />
              ) : (
                <FormattedMessage id="bookings.details.instructions.save_changes" />
              )}
            </Button>
            <Button
              size="small"
              variant="secondary"
              disabled={updateWorkerNoteLoading}
              onClick={() => {
                setTextAreaValue(note.content);
                setEditable(false);
              }}
            >
              <FormattedMessage id="bookings.details.instructions.cancel_edit_note" />
            </Button>
          </Box>
        </Card>
      ) : (
        <Box pb={1}>
          <Text size="small" color="secondary" mb={1}>
            <FormattedMessage id="bookings.details.instructions.last_updated" />
            <FormattedDate
              value={note.updated_at}
              year="numeric"
              day="2-digit"
              month="2-digit"
              hour="numeric"
              minute="numeric"
            />
          </Text>
          <ParseMarkdown content={note.content} />
        </Box>
      )}
      {!editable && (
        <ActionsColumn>
          {note.canUpdate && (
            <IconEdit
              onClick={() => setEditable(!editable)}
              mr={2}
              color="primary"
              size={16}
            />
          )}
          {note.canDelete && (
            <IconClose
              onClick={() => setShowDeleteConfirmationModal(true)}
              color="primary"
              size={16}
            />
          )}
        </ActionsColumn>
      )}
    </ViewNoteGrid>
  );
};

const StyledTextArea = styled(TextArea)`
  width: 100%;
  & textarea {
    ${typography.fontSmall}
    font-family: monospace;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
`;

const LoadingBox = styled(Box)`
  border-bottom: 1px solid ${theme.colors.greyMed};
  padding-bottom: ${theme.space[2]}px;
  margin-bottom: ${theme.space[2]}px;
`;

const ViewNoteGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 56px;
  grid-gap: ${theme.space[2]}px;
  border-bottom: 1px solid ${theme.colors.greyMed};
  margin-bottom: ${theme.space[3]}px;

  ${props =>
    props.editable &&
    css`
      grid-template-columns: 1fr;
      border-bottom: none;
    `}
`;

const ActionsColumn = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  & svg {
    flex-shrink: 0;
  }
`;

BookingDetailsWorkerNote.propTypes = propTypes;

export default BookingDetailsWorkerNote;
