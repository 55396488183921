import React from 'react';
import { bool } from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Card, Spinner, Text, IconChevronRight } from '@freska/freska-ui';

import BookingCard from '../../components/Bookings/BookingCard';
import Alert from '../../components/Common/Alert';
import { bookingType } from '../../types';
import {
  bookingDetailsURL,
  bookingsPathname,
  bookingIntstructionsURL,
} from '../../utils/urls';

const propTypes = {
  booking: bookingType,
  isLoading: bool,
  isError: bool,
};

function NextBooking({ booking, isLoading, isError }) {
  const intl = useIntl();
  const bookingURL = bookingDetailsURL(booking?.id);
  const instructionsURL = bookingIntstructionsURL(booking?.id);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Text bold ml={2}>
          <FormattedMessage id="dashboard.next_booking" />
        </Text>
        <StyledLink to={bookingsPathname()}>
          <Box display="flex" alignItems="center">
            <Text color="primary">
              <FormattedMessage id="dashboard.view_schedule" />
            </Text>
            <IconChevronRight color="primary" size={24} />
          </Box>
        </StyledLink>
      </Box>
      <Card mt={1} mb={3} p={0} px={2}>
        {isLoading && (
          <Box py={2}>
            <Spinner size={24} />
          </Box>
        )}

        {isError && (
          <Text py={2} color="alert">
            <FormattedMessage id="dashboard.fetching_next_failed" />
          </Text>
        )}

        {!isLoading && !booking && !isError && (
          <Text py={2} color="secondary">
            <FormattedMessage id="dashboard.no_upcoming_cleanings" />
          </Text>
        )}

        {booking && (
          <Box pb={1}>
            <BookingCard
              data={booking}
              unconfirmed={booking.isUnconfirmed}
              type="dashboard"
            />
            {booking.detailSummary.extendedDuration > 0 && (
              <Alert
                mb={1}
                py={1}
                px="12px"
                title={intl.formatMessage({ id: 'bookings.new_customer' })}
                action={
                  <StyledLink to={bookingURL}>
                    <Text color="primary" size="small">
                      <FormattedMessage id="dashboard.view" />
                    </Text>
                  </StyledLink>
                }
              />
            )}
            {!!booking.detailSummary.customerNotes && (
              <Alert
                mb={1}
                py={1}
                px="12px"
                title={intl.formatMessage({
                  id: 'dashboard.customer_instructions_available',
                })}
                action={
                  <StyledLink to={instructionsURL}>
                    <Text color="primary" size="small">
                      <FormattedMessage id="dashboard.view" />
                    </Text>
                  </StyledLink>
                }
              />
            )}
          </Box>
        )}
      </Card>
    </>
  );
}

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

NextBooking.propTypes = propTypes;

export default NextBooking;
