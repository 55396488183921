import * as Joi from 'joi-browser';

export const validate = (data, schema, options: {}) =>
  Joi.validate(data, schema, { abortEarly: false, ...options });

const buildUsefulErrorObject = errors => {
  const usefulErrors = {};

  errors.forEach(error => {
    if (
      !Object.prototype.hasOwnProperty.call(usefulErrors, error.path.join('.'))
    ) {
      usefulErrors[error.path.join('.')] = {
        type: error.type,
        msg: `error.${error.path.join('.')}.${error.type}`,
      };
    }
  });

  return usefulErrors;
};

const validateData = (data, schema, options: {}) =>
  new Promise((resolve, reject) => {
    const errors = validate(data, schema, options).error;

    resolve(errors ? buildUsefulErrorObject(errors.details) : {});
    reject(new Error('Error occurred while validating.'));
  });

export const checkForError = (errors, field) =>
  !!Object.prototype.hasOwnProperty.call(errors, field);
export default validateData;
