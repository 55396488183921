/*
  This file roughly simulates a benefit from type checking languages such as TypeScript.
  When dealing with data that can have multiple values, we want to restrict which values
  are supported much like PropTypes.oneOf([...]). However, this file makes the data
  immutable and keeps all this data together in one place.

  The ***_TYPE const is used for PropTypes validation.
    (ex: language: PropTypes.oneOf(LanguagesType))
  The ***_ENUM const is meant for actual use within the code.
    (dispatch(setLanguage(LanguagesEnum.FI)))
*/

export const BOOKINGS_VIEW_TYPE_KEY = 'bookings-view-type';
export const BOOKING_DETAILS_PATHNAME = '/booking/details';
export const BOOKING_INSTRUCTIONS_PATHNAME = '/booking/instructions';

export const BOOKINGS_VIEW_TYPES_ENUM = Object.freeze({
  LIST: 'list',
  CALENDAR: 'calendar',
});

export const LANGUAGES_TYPE = Object.freeze([
  'fi-FI',
  'en-GB',
  'se-SE',
  'no-NO',
]);
export const LANGUAGES_ENUM = Object.freeze({
  FI: 'fi-FI',
  EN: 'en-GB',
  SE: 'se-SE',
  NO: 'no-NO',
});

export const BOOKING_STATUS_TYPE = Object.freeze([
  'uncompleted',
  'withoutUncompleted',
]);
export const BOOKING_STATUS_ENUM = Object.freeze({
  UNCONFIRMED: 'uncompleted',
  ALL: 'withoutUncompleted',
});

export const BOOKING_CALENDAR_ENUM = Object.freeze({
  START_HOUR: '07',
  START_MINUTES: '00',
  GRID_INTERVAL: 5,
});

export const RATING_QUALITY = Object.freeze({
  THRESHOLD: 4.75,
});

export const CALENDAR_TIME_RANGE_TYPE = Object.freeze([
  '0300',
  '0315',
  '0330',
  '0345',
  '0400',
  '0415',
  '0430',
  '0445',
  '0500',
  '0515',
  '0530',
  '0545',
  '0600',
  '0615',
  '0630',
  '0645',
  '0700',
  '0715',
  '0730',
  '0745',
  '0800',
  '0815',
  '0830',
  '0845',
  '0900',
  '0915',
  '0930',
  '0945',
  '1000',
  '1015',
  '1030',
  '1045',
  '1100',
  '1115',
  '1130',
  '1145',
  '1200',
  '1215',
  '1230',
  '1245',
  '1300',
  '1315',
  '1330',
  '1345',
  '1400',
  '1415',
  '1430',
  '1445',
  '1500',
  '1515',
  '1530',
  '1545',
  '1600',
  '1615',
  '1630',
  '1645',
  '1700',
  '1715',
  '1730',
  '1745',
  '1800',
  '1815',
  '1830',
  '1845',
  '1900',
  '1915',
  '1930',
  '1945',
  '2000',
  '2015',
  '2030',
  '2045',
  '2100',
  '2115',
  '2130',
  '2145',
  '2200',
  '2215',
  '2230',
  '2245',
  '2300',
  '2315',
  '2330',
  '2345',
  '0000',
  '0115',
  '0130',
  '0145',
  '0200',
]);

export const MONTH_ABBREV_TYPE = Object.freeze([
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
]);

export const DAY_ABBREV_TYPE = Object.freeze([
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
]);

export const SERVICE_TYPES = Object.freeze([
  'home_cleaning',
  'office_cleaning',
  'window_cleaning',
  'moving_cleaning',
  'hospital_cleaning',
]);

export const NETWORK_STATUS_ENUM = Object.freeze({
  LOADING: 1,
  SET_VARIABLES: 2,
  FETCH_MORE: 3,
  REFETCH: 4,
  POLL: 6,
  READY: 7,
  ERROR: 8,
});
