import React, { Fragment, useState } from 'react';
import { instanceOf } from 'prop-types';
import { useQuery } from 'react-apollo';
import { Box, Spinner } from '@freska/freska-ui';
import styled from 'styled-components';
import { format, endOfISOWeek, startOfISOWeek } from 'date-fns';

import LocalStorage from '../../utils/localStorage';
import { GET_BOOKINGS_VIEW_DATA, GET_BOOKINGS } from '../../gql/queries';
import { BOOKING_STATUS_ENUM, NETWORK_STATUS_ENUM } from '../../constants';
import BookingsList from './BookingsList';
import WeekNav from './WeekNav';
import BookingWeekPreview from './BookingWeekPreview';
import FlexContainer from '../Common/FlexContainer';
import BookingsDrawer from '../Common/BookingsDrawer';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import { sizes } from '../../theme/theme';
import { useServiceWorker } from '../../useServiceWorker';

const propTypes = {
  weekStartDate: instanceOf(Date).isRequired,
};

function BookingsView({ weekStartDate }) {
  const [isGraphExpanded, setIsGraphExpanded] = useState(
    LocalStorage.get('is-graph-open') !== false
  );
  const { onlineStatus } = useServiceWorker();

  const {
    error: bookingsViewError,
    data: bookingsViewData,
    networkStatus: bookingsViewNetworkStatus,
  } = useQuery(GET_BOOKINGS_VIEW_DATA, {
    variables: {
      startDate: format(startOfISOWeek(weekStartDate), 'yyyy-MM-dd'),
      endDate: format(endOfISOWeek(weekStartDate), 'yyyy-MM-dd'),
    },
    pollInterval: onlineStatus ? 60000 : 0,
  });

  const {
    error: unconfirmedBookingError,
    data: unconfirmedBookingData,
    networkStatus: unconfirmedBookingNetworkStatus,
  } = useQuery(GET_BOOKINGS, {
    variables: {
      bookingStatus: BOOKING_STATUS_ENUM.UNCONFIRMED,
    },
    pollInterval: onlineStatus ? 60000 : 0,
  });

  const loading =
    unconfirmedBookingNetworkStatus === NETWORK_STATUS_ENUM.LOADING ||
    bookingsViewNetworkStatus === NETWORK_STATUS_ENUM.LOADING ||
    bookingsViewNetworkStatus === NETWORK_STATUS_ENUM.SET_VARIABLES;

  function handleToggleExpand(e) {
    e.preventDefault();
    LocalStorage.set('is-graph-open', !isGraphExpanded);
    setIsGraphExpanded(!isGraphExpanded);
  }

  return (
    <StyledFlexContainer column>
      <WeekNav startDayOfActiveWeek={weekStartDate} />
      {bookingsViewError || unconfirmedBookingError ? (
        <ErrorHandler error={bookingsViewError || unconfirmedBookingError} />
      ) : (
        <Fragment>
          {loading ? (
            <Box p={3}>
              <Spinner />
            </Box>
          ) : (
            <Fragment>
              {bookingsViewData &&
                !!bookingsViewData.getBookingsViewData.bookings.length && (
                  <BookingWeekPreview
                    data={bookingsViewData.getBookingsViewData}
                    startDate={weekStartDate}
                    showWeekGraph={
                      !!bookingsViewData.getBookingsViewData.bookings.length &&
                      isGraphExpanded
                    }
                  />
                )}
              <BookingsDrawer
                isExpandable={
                  bookingsViewData &&
                  !!bookingsViewData.getBookingsViewData.bookings.length
                }
                handleToggleExpand={handleToggleExpand}
                isExpanded={isGraphExpanded}
              >
                {unconfirmedBookingData &&
                  !!unconfirmedBookingData.getBookings.length && (
                    <BookingsList
                      data={unconfirmedBookingData.getBookings}
                      bookingStatus={BOOKING_STATUS_ENUM.UNCONFIRMED}
                      showNoBookings={false}
                    />
                  )}

                <BookingsList
                  data={
                    bookingsViewData
                      ? bookingsViewData.getBookingsViewData.bookings
                      : []
                  }
                  bookingStatus={BOOKING_STATUS_ENUM.ALL}
                  startDate={weekStartDate}
                />
              </BookingsDrawer>
            </Fragment>
          )}
        </Fragment>
      )}
    </StyledFlexContainer>
  );
}

const StyledFlexContainer = styled(FlexContainer)`
  height: 100%;
  min-height: calc(100vh - ${sizes.height.header});
`;

BookingsView.propTypes = propTypes;

export default BookingsView;
