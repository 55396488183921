import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Text, theme } from '@freska/freska-ui';

import { getStringTime } from '../../utils/time';
import { colors } from '../../theme/theme';
import { CALENDAR_TIME_RANGE_TYPE } from '../../constants';

const propTypes = {
  weekAvailabilities: PropTypes.instanceOf(Array),
  weekNum: PropTypes.number.isRequired,
};

const defaultProps = {
  weekAvailabilities: [],
};

const timeRangeDivider = 4;
const weekDays = new Array(7);
weekDays.fill([]);

class WeekGraph extends Component {
  reduceCalendarTimeRange = weekAvailabilities => {
    const earliestTime = weekAvailabilities.reduce((currentDay, nextDay) => {
      if (!nextDay.length) return currentDay;
      const earliestTimeOnThisDay = nextDay.reduce(
        (currentDayEvent, nextEvent) => {
          const startTime = getStringTime(nextEvent.startTime);
          return parseInt(startTime, 10) < currentDayEvent
            ? startTime
            : currentDayEvent;
        },
        2400
      );
      return parseInt(earliestTimeOnThisDay, 10) < currentDay
        ? earliestTimeOnThisDay
        : currentDay;
    }, 2400);
    const latestTime = weekAvailabilities.reduce((currentDay, nextDay) => {
      if (!nextDay.length) return currentDay;
      const latestTimeOnThisDay = nextDay.reduce(
        (currentDayEvent, nextEvent) => {
          const endTime = getStringTime(nextEvent.endTime);
          return parseInt(endTime, 10) > currentDayEvent
            ? endTime
            : currentDayEvent;
        },
        600
      );
      return parseInt(latestTimeOnThisDay, 10) > currentDay
        ? latestTimeOnThisDay
        : currentDay;
    }, 600);

    const startAtIndex = CALENDAR_TIME_RANGE_TYPE.indexOf(earliestTime) - 4;
    const endAtIndex = CALENDAR_TIME_RANGE_TYPE.indexOf(latestTime) + 4;
    return CALENDAR_TIME_RANGE_TYPE.slice(startAtIndex, endAtIndex);
  };
  render() {
    const { weekAvailabilities, weekNum } = this.props;
    const reducedCalendarTimeRange = this.reduceCalendarTimeRange(
      weekAvailabilities
    );
    return (
      <GraphGridContainer timeArray={reducedCalendarTimeRange}>
        <TimeMarkers timeArray={reducedCalendarTimeRange} />
        {weekDays.map((day, index) => (
          <DayCol
            key={uuidv4()}
            weekNum={weekNum}
            timeArray={reducedCalendarTimeRange}
          >
            {weekAvailabilities[index] &&
              weekAvailabilities[index].map(availability => (
                <AvailabilityBlock
                  key={availability.id || uuidv4()}
                  weekNum={weekNum}
                  start={getStringTime(availability.startTime)}
                  end={getStringTime(availability.endTime)}
                >
                  <Text as="p" size="smaller" pl="4px" pt="4px">
                    {getStringTime(availability.startTime, true)}
                    <br />
                    {getStringTime(availability.endTime, true)}
                  </Text>
                </AvailabilityBlock>
              ))}
          </DayCol>
        ))}
      </GraphGridContainer>
    );
  }
}

const buildGridLineLabels = (timeArray, weekNum) =>
  timeArray.map(time => `[line-${weekNum}-${time}] 1fr `);

const GraphGridContainer = styled.section`
  background: ${theme.colors.greyLight};
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 0.7fr repeat(7, 1fr);
  min-height: ${props =>
    Math.ceil((props.timeArray.length / timeRangeDivider) * 20)}px;
  position: relative;
`;

const DayCol = styled.section`
  background: ${theme.colors.greyMed};
  display: grid;
  grid-gap: 2px;
  grid-template-rows: ${props =>
    buildGridLineLabels(props.timeArray, props.weekNum)};
  grid-template-columns: 1fr;
  position: relative;
`;

const TimeMarkers = styled.section`
  border-bottom: 1px solid ${theme.colors.greyMed};
  display: grid;
  grid-gap: 2px;
  position: relative;
  grid-template-rows: repeat(
    ${props => Math.ceil(props.timeArray.length / timeRangeDivider)},
    1fr
  );
`;

const AvailabilityBlock = styled.div`
  background: ${colors.background.availability};
  grid-row: ${props =>
    `line-${props.weekNum}-${props.start} / line-${props.weekNum}-${props.end}`};
  grid-column: 1;
`;

WeekGraph.propTypes = propTypes;
WeekGraph.defaultProps = defaultProps;

export default WeekGraph;
