import React, { Component, Fragment } from 'react';
import { shape, object, bool, string, arrayOf } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Spinner } from '@freska/freska-ui';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import ViewGrid from '../../components/Common/ViewGrid';
import withUser from '../../utils/withUser';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import AvailabilityMenu from '../../components/Availability/AvailabilityMenu';
import Drawer from '../../components/Common/Drawer';
import AvailabilityList from '../../components/Availability/AvailabilityList';
import FullCalendar from '../../components/Calendar/FullCalendar';
import {
  GET_AVAILABILITY_CALENDAR_DATA,
  GET_AVAILABILITY_GROUPS,
} from '../../gql/queries';
import { availabilityGroupType, availabilityCalendarType } from '../../types';

const propTypes = {
  intl: shape({}).isRequired,
  user: shape({ home_address: shape({ countrycode: string }) }).isRequired,
  getCalendarData: shape({
    loading: bool,
    error: object,
    getAvailabilityCalendarData: availabilityCalendarType,
  }).isRequired,
  getAvailabilityGroups: shape({
    loading: bool,
    error: object,
    getAvailabilityGroups: arrayOf(availabilityGroupType),
  }).isRequired,
};

class AvailabilityContainer extends Component {
  state = {
    isEditing: false,
    selectedAvailabilityGroup: {},
    maxDate: '',
    minDate: '',
    showDrawer: true,
  };

  toggleEditAvailabilityRule = () => {
    this.setState({
      isEditing: false,
    });
  };

  toggleDrawer = showDrawer => {
    this.setState({
      showDrawer,
    });
  };

  onEdit = ({
    isEditing,
    selectedAvailabilityGroup,
    minDate = null,
    maxDate = null,
  }) => {
    this.setState({
      isEditing,
      selectedAvailabilityGroup,
      maxDate,
      minDate,
    });
  };

  render() {
    const { intl, user, getCalendarData, getAvailabilityGroups } = this.props;
    const {
      isEditing,
      selectedAvailabilityGroup,
      maxDate,
      minDate,
      showDrawer,
    } = this.state;

    if (getCalendarData.loading || getAvailabilityGroups.loading) {
      return (
        <ViewGrid>
          <Box p={3}>
            <Spinner />
          </Box>
        </ViewGrid>
      );
    }

    if (getCalendarData.error || getAvailabilityGroups.error) {
      return (
        <ErrorHandler
          error={getCalendarData.error || getAvailabilityGroups.error}
        />
      );
    }

    const canEditAvailability =
      user && user.home_address.countrycode === 'FI' && !user.employed;

    return isEditing ? (
      <AvailabilityList
        onEdit={this.onEdit}
        availabilityGroup={selectedAvailabilityGroup}
        maxDate={maxDate}
        minDate={minDate}
      />
    ) : (
      <Fragment>
        <FullCalendar
          data={getCalendarData.getAvailabilityCalendarData}
          isUserEmployed={!canEditAvailability}
          toggleDrawer={this.toggleDrawer}
        />
        {canEditAvailability && showDrawer && (
          <Drawer
            collapsedHeight={80}
            isExpandable
            drawerTitle={intl.formatMessage({
              id: 'availability.edit_weekly_availability',
            })}
          >
            <AvailabilityMenu
              onEdit={this.onEdit}
              data={getAvailabilityGroups.getAvailabilityGroups}
            />
          </Drawer>
        )}
      </Fragment>
    );
  }
}

AvailabilityContainer.propTypes = propTypes;

export default withRouter(
  compose(
    graphql(GET_AVAILABILITY_CALENDAR_DATA, {
      name: 'getCalendarData',
    }),
    graphql(GET_AVAILABILITY_GROUPS, {
      name: 'getAvailabilityGroups',
    })
  )(injectIntl(withUser(AvailabilityContainer)))
);
