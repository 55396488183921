import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from '@freska/freska-ui';

const propTypes = {
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
  column: PropTypes.bool,
  tabbable: PropTypes.bool,
  fullScreen: PropTypes.bool,
  alignV: PropTypes.oneOf([
    'flex-end',
    'flex-start',
    'center',
    'space-between',
    'space-around',
    'stretch',
  ]),
  alignH: PropTypes.oneOf([
    'flex-end',
    'flex-start',
    'center',
    'space-between',
    'space-around',
    'stretch',
  ]),
};

const defaultProps = {
  center: false,
  column: false,
  tabbable: false,
  fullScreen: false,
  alignV: 'stretch',
  alignH: 'stretch',
};

const FlexContainer = styled(Box).attrs(({ tabbable }) => ({
  ...(tabbable && { tabIndex: 0 }),
}))`
  align-content: ${props => (props.center ? 'center' : props.alignV)};
  align-items: ${props => (props.center ? 'center' : props.alignV)};
  display: flex;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  justify-content: ${props => (props.center ? 'center' : props.alignH)};

  ${props =>
    props.fullScreen &&
    `
      height: 100%;
      min-height: 100vh;
      width: 100%;
  `};
`;

FlexContainer.propTypes = propTypes;
FlexContainer.defaultProps = defaultProps;

export default FlexContainer;
