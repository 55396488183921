import PropTypes from 'prop-types';
import React from 'react';

const Form = ({
  children,
  name,
  action,
  autocomplete,
  novalidate,
  onSubmit,
  method,
  loading,
}) => (
  <form
    name={name}
    action={action}
    autoComplete={autocomplete ? 'on' : 'off'}
    noValidate={novalidate}
    onSubmit={onSubmit}
    method={method}
  >
    <fieldset disabled={loading} aria-busy={loading}>
      {children}
    </fieldset>
  </form>
);

Form.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  action: PropTypes.string,
  method: PropTypes.string,
  onSubmit: PropTypes.func,
  autocomplete: PropTypes.bool,
  novalidate: PropTypes.bool,
  loading: PropTypes.bool,
};

Form.defaultProps = {
  action: '',
  method: 'POST',
  autocomplete: true,
  novalidate: false,
  loading: false,
  onSubmit: () => false,
};

export default Form;
