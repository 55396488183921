import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

function useNumberOfDaysToRender() {
  const breakpoint248 = useMediaQuery({
    minWidth: 248,
  });
  const breakpoint296 = useMediaQuery({
    minWidth: 296,
  });
  const breakpoint400 = useMediaQuery({
    minWidth: 400,
  });
  const breakpoint420 = useMediaQuery({
    minWidth: 420,
  });
  const breakpoint504 = useMediaQuery({
    minWidth: 504,
  });
  const breakpoint652 = useMediaQuery({
    minWidth: 652,
  });

  const [daysToAddOrSubstract, setDaysToAddOrSubstract] = useState(0);

  useEffect(() => {
    const breakPointFlagArray = [
      breakpoint248,
      breakpoint296,
      breakpoint420,
      breakpoint504,
      breakpoint652,
    ];
    const breakPointIndexDaysMaxFit = breakPointFlagArray.findIndex(
      value => !value
    );

    if (breakPointIndexDaysMaxFit < 0) {
      setDaysToAddOrSubstract(4);
    } else if (breakPointIndexDaysMaxFit === 0) {
      setDaysToAddOrSubstract(0);
    } else {
      setDaysToAddOrSubstract(breakPointIndexDaysMaxFit - 1);
    }
  }, [
    breakpoint248,
    breakpoint296,
    breakpoint400,
    breakpoint420,
    breakpoint504,
    breakpoint652,
  ]);

  return daysToAddOrSubstract;
}

export default useNumberOfDaysToRender;
