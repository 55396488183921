import React, { Fragment } from 'react';
import styled from 'styled-components';
import { IconEdit, Button, Text, theme } from '@freska/freska-ui';
import { FormattedMessage, FormattedDate } from 'react-intl';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { space, sizes } from '../../theme/theme';
import { DAY_ABBREV_TYPE } from '../../constants';
import AvailabilityDay from './AvailabilityDay';
import FlexContainer from '../Common/FlexContainer';

const propTypes = {
  onEdit: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function AvailabilityMenu({ onEdit, data }) {
  if (data.length) {
    return (
      <CardContainer>
        {data.map(availabilityGroup => (
          <AvailabilityGroupContainer key={uuidv4()}>
            <StyledFlexContainer alignV="space-around" alignH="space-between">
              <Text as="span">
                <FormattedDate
                  value={availabilityGroup.valid_from}
                  year="numeric"
                  day="2-digit"
                  month="2-digit"
                />
                {availabilityGroup.valid_to ? (
                  <Text as="span">
                    &nbsp;&ndash;&nbsp;
                    <FormattedDate
                      value={availabilityGroup.valid_to}
                      year="numeric"
                      day="2-digit"
                      month="2-digit"
                    />
                  </Text>
                ) : (
                  <Text as="span">&nbsp;&#8594;&nbsp;</Text>
                )}
              </Text>
              <IconButton
                title="edit"
                variant="tertiary"
                onClick={() =>
                  onEdit({
                    isEditing: true,
                    selectedAvailabilityGroup: availabilityGroup,
                  })
                }
              >
                <IconEdit mr={3} />
              </IconButton>
            </StyledFlexContainer>
            <AvailabilityWeekFlexContainer>
              {availabilityGroup.schedule.map((day, dayIndex) => (
                <AvailabilityDay
                  key={uuidv4()}
                  day={DAY_ABBREV_TYPE[dayIndex]}
                  startTime={day && day.start}
                  endTime={day && day.end}
                />
              ))}
            </AvailabilityWeekFlexContainer>
          </AvailabilityGroupContainer>
        ))}
        <Fragment>
          <Button
            variant="primary"
            loading={false}
            onClick={() =>
              onEdit({
                isEditing: true,
              })
            }
          >
            <FormattedMessage id="availability.create_upcoming_availability" />
          </Button>
        </Fragment>
      </CardContainer>
    );
  }
  return (
    <CardContainer>
      <Button
        variant="primary"
        loading={false}
        onClick={() => onEdit({ isEditing: true })}
      >
        <FormattedMessage id="availability.create_new_availability" />
      </Button>
    </CardContainer>
  );
}

const CardContainer = styled.article`
  background: ${theme.colors.white};

  margin: ${space.md} auto 0;
  padding: 0 ${space.default};
  max-width: ${sizes.width.bookingCardMaxWidth};
`;

const StyledFlexContainer = styled(FlexContainer)`
  margin-bottom: ${space.sm};
`;

const AvailabilityWeekFlexContainer = styled(FlexContainer)`
  border-bottom: 1px solid ${theme.colors.greyMed};
  padding-bottom: ${space.md};
`;

const AvailabilityGroupContainer = styled.div`
  margin-bottom: ${space.default};

  :last-child {
    margin-bottom: 0;
  }
`;

const IconButton = styled.button.attrs(props => ({
  id: 'edit-btn',
  name: 'edit-button',
  title: props.title,
}))`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 24px;
  width: 24px;

  background-color: transparent;
  cursor: pointer;

  svg,
  span {
    height: 24px;
    margin: 0;
    padding: 0;
  }
`;

AvailabilityMenu.propTypes = propTypes;

export default AvailabilityMenu;
