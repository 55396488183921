import React from 'react';
import { bool, string, shape } from 'prop-types';
import { IconPets, Text, Box } from '@freska/freska-ui';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { space } from '../../theme/theme';
import { checkForPets } from './ServicesIconList';
import DataBlock from '../Common/DataBlock';

const propTypes = {
  pets: shape({
    hasDogs: bool,
    hasCats: bool,
    hasOtherPets: bool,
    petsInfo: string,
  }).isRequired,
};

const BookingDetailsPets = ({ pets }) => (
  <DataBlock
    Icon={IconPets}
    title="bookings.card.pets.pets"
    hasContent={checkForPets(pets)}
  >
    <Box mb={1}>
      {pets.hasDogs && (
        <PetType>
          <FormattedMessage id="bookings.card.pets.dog" />
        </PetType>
      )}
      {pets.hasCats && (
        <PetType>
          <FormattedMessage id="bookings.card.pets.cat" />
        </PetType>
      )}
      {pets.hasOtherPets && (
        <PetType>
          <FormattedMessage id="bookings.card.pets.other" />
        </PetType>
      )}
    </Box>
    <Text m={0} as="p">
      {pets.petsInfo}
    </Text>
  </DataBlock>
);

const PetType = styled(Text)`
  margin-bottom: ${space.sm};
  + span {
    &:before {
      content: ', ';
      display: inline;
    }
  }
`;

BookingDetailsPets.propTypes = propTypes;

export default BookingDetailsPets;
