import React from 'react';
import {
  IconPin,
  IconHome,
  IconOffice,
  IconWindow,
  IconMoving,
  IconKeyAttention,
  IconKey,
  IconKeyNotAvailable,
  IconLocalHospital,
  theme,
} from '@freska/freska-ui';

export const getServiceTypeIcon = (
  size,
  bookingType,
  unconfirmed = false,
  isOutsideAvailabilityPeriod = false
) => {
  if (unconfirmed) {
    return <IconPin color="white" size={16} />;
  }
  const color = isOutsideAvailabilityPeriod ? 'white' : 'secondary';
  switch (bookingType) {
    case 'home_cleaning':
    case 1:
      return <IconHome size={size} color={color} />;
    case 'office_cleaning':
    case 2:
      return <IconOffice size={size} color={color} />;
    case 'window_cleaning':
    case 3:
      return <IconWindow size={size} color={color} />;
    case 'moving_cleaning':
    case 36:
    case 4:
      return <IconMoving size={size} color={color} />;
    case 'hospital_cleaning':
    case 38:
    case 6:
      return <IconLocalHospital size={size} color={color} />;
    default:
      return '';
  }
};

export const getRawServiceTypeIcon = (bookingType, unconfirmed = false) => {
  if (unconfirmed) {
    return IconPin;
  }
  switch (bookingType) {
    case 'home_cleaning':
    case 1:
      return IconHome;
    case 'office_cleaning':
    case 2:
      return IconOffice;
    case 'window_cleaning':
    case 3:
      return IconWindow;
    case 'moving_cleaning':
    case 36:
    case 4:
      return IconMoving;
    default:
      return '';
  }
};

export const getRawAccessStatusIcon = (accessStatus, safekeeper) => {
  if (safekeeper === 'at_another_cleaner') return IconKey;
  switch (accessStatus) {
    case 'Key':
      return IconKey;
    case 'KeyNotAvailable':
      return IconKeyNotAvailable;
    case 'KeyAttention':
      return IconKeyAttention;
    default:
      return '';
  }
};

export const getAccessStatusIcon = (accessStatus, size, color) => {
  switch (accessStatus) {
    case 'KeyNotAvailable':
      return <IconKeyNotAvailable color={color || 'secondary'} size={size} />;
    case 'KeyAttention':
    case 'return':
    case 'pick_up':
      return <IconKeyAttention color={color || 'attention'} size={size} />;
    default:
      return <IconKey color={color || 'secondary'} size={size} />;
  }
};

export const getMarkerIcon = (serviceType, color, size = 24) => {
  switch (serviceType) {
    case 'home_cleaning':
    case 1:
      return encodeURIComponent(`
        <svg width="${size}px" height="${size}px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill-rule="nonzero" fill="none">
            <path fill="${theme.colors.white}" d="M20.5 2.987c-4.674-3.983-12.295-3.983-16.968 0-4.704 3.982-4.704 10.476-.031 14.458C8.844 21.815 11.678 24.001 12 24c.32 0 3.143-2.186 8.468-6.555 4.704-3.982 4.704-10.476.031-14.458z" />
            <path fill="${color}" d="M10.4 16.6v-4.8h3.2v4.8h4v-6.4H20L12 3l-8 7.2h2.4v6.4z" />
          </g>
        </svg>
      `);
    case 'office_cleaning':
    case 2:
      return encodeURIComponent(`
        <svg width="${size}px" height="${size}px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill-rule="nonzero" fill="none">
            <path fill="${theme.colors.white}" d="M20.5 2.987c-4.674-3.983-12.295-3.983-16.968 0-4.704 3.982-4.704 10.476-.031 14.458C8.844 21.815 11.678 24.001 12 24c.32 0 3.143-2.186 8.468-6.555 4.704-3.982 4.704-10.476.031-14.458z" />
            <path fill="${color}" d="M12 6.8V4H5v12.6h14V6.8h-7zm-4.2 8.4H6.4v-1.4h1.4v1.4zm0-2.8H6.4V11h1.4v1.4zm0-2.8H6.4V8.2h1.4v1.4zm0-2.8H6.4V5.4h1.4v1.4zm2.8 8.4H9.2v-1.4h1.4v1.4zm0-2.8H9.2V11h1.4v1.4zm0-2.8H9.2V8.2h1.4v1.4zm0-2.8H9.2V5.4h1.4v1.4zm7 8.4H12v-1.4h1.4v-1.4H12V11h1.4V9.6H12V8.2h5.6v7zm-1.4-5.6h-1.4V11h1.4V9.6zm0 2.8h-1.4v1.4h1.4v-1.4z" />
          </g>
        </svg>
      `);
    case 'window_cleaning':
    case 3:
      return encodeURIComponent(`
        <svg width="${size}px" height="${size}px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill-rule="nonzero" fill="none">
            <path fill="${theme.colors.white}" d="M20.5 2.987c-4.674-3.983-12.295-3.983-16.968 0-4.704 3.982-4.704 10.476-.031 14.458C8.844 21.815 11.678 24.001 12 24c.32 0 3.143-2.186 8.468-6.555 4.704-3.982 4.704-10.476.031-14.458z" />
            <path fill="${color}" d="M11.095 10.619h-3.81v4.571h9.144V10.62h-3.81v4.571h-1.524V10.62zm1.524-1.524h3.81V4.524H7.286v4.571h3.81V4.524h1.523v4.571zM5.762 15.19V3h12.19v12.19h.762v1.524H5V15.19h.762zM7.286 19v-1.524h9.143V19H7.286z" />
          </g>
        </svg>
      `);
    case 'moving_cleaning':
    case 36:
    case 4:
      return encodeURIComponent(`
        <svg width="${size}px" height="${size}px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill-rule="nonzero" fill="none">
            <path fill="${theme.colors.white}" d="M20.5 2.987c-4.674-3.983-12.295-3.983-16.968 0-4.704 3.982-4.704 10.476-.031 14.458C8.844 21.815 11.678 24.001 12 24c.32 0 3.143-2.186 8.468-6.555 4.704-3.982 4.704-10.476.031-14.458z" />
            <path fill="${color}" d="M17.818 6.91h-2.182V4H5.455C4.655 4 4 4.655 4 5.455v8h1.455c0 1.207.974 2.181 2.181 2.181a2.179 2.179 0 0 0 2.182-2.181h4.364c0 1.207.974 2.181 2.182 2.181a2.179 2.179 0 0 0 2.181-2.181H20V9.818L17.818 6.91zM7.636 14.544a1.09 1.09 0 0 1-1.09-1.09 1.09 1.09 0 1 1 2.182 0 1.09 1.09 0 0 1-1.092 1.09zM17.455 8l1.425 1.818h-3.244V8h1.819zm-1.091 6.545a1.09 1.09 0 0 1-1.091-1.09 1.09 1.09 0 1 1 2.182 0 1.09 1.09 0 0 1-1.091 1.09z" />
          </g>
        </svg>
      `);
    default:
      return '';
  }
};
