import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { instanceOf, shape } from 'prop-types';
import {
  GoogleMap,
  useLoadScript,
  Polygon,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import {
  theme,
  Text,
  Label,
  IconGroup,
  IconPolygon,
  Spinner,
} from '@freska/freska-ui';
import { FormattedMessage, FormattedDate, useIntl } from 'react-intl';
import styled from 'styled-components';
import { formatDuration } from '../../utils/formatNumber';
import { trackEvent } from '../../utils/tracking';
import { space } from '../../theme/theme';
import LocalStorage from '../../utils/localStorage';
import { getMarkerIcon } from '../../utils/bookingsHelper';
import { useHistory } from 'react-router-dom';
import NotificationBlock from '../Common/NotificationBlock';
import { BOOKING_DETAILS_PATHNAME } from '../../constants';

const propTypes = {
  workingAreas: instanceOf(Array),
  bookings: instanceOf(Array),
  homeAddress: shape({}),
};

const defaultProps = {
  workingAreas: [],
  bookings: [],
  homeAddress: null,
};

const googleMapsLibraries = ['geometry', 'drawing', 'places', 'visualization'];

function goToBookingDetails(bookingId, history) {
  trackEvent('Booking details opened from working area view', {
    category: 'Working areas',
  });

  history.push({
    pathname: BOOKING_DETAILS_PATHNAME,
    search: `?id=${bookingId}`,
    state: { workingArea: true },
  });
}

const WorkingAreasMap = ({ workingAreas, bookings, homeAddress }) => {
  const intl = useIntl();
  const history = useHistory();
  const storedShowCustomerMarkers = LocalStorage.get(
    'is-customers-layer-visible'
  );
  const storedShowWorkingAreas = LocalStorage.get(
    'is-working-area-layer-visible'
  );
  const [openInfoWindowId, setOpenInfoWindowId] = useState(null);
  const [showCustomerMarkers, setShowCustomerMarkers] = useState(
    !!storedShowCustomerMarkers
  );
  const [showWorkingAreas, setShowWorkingAreas] = useState(
    storedShowWorkingAreas === undefined ? true : storedShowWorkingAreas
  );

  useEffect(() => {
    LocalStorage.set('is-working-area-layer-visible', showWorkingAreas);
  }, [showWorkingAreas]);

  useEffect(() => {
    LocalStorage.set('is-customers-layer-visible', showCustomerMarkers);
  }, [showCustomerMarkers]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: googleMapsLibraries,
  });

  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    zoom: 10,
    center: homeAddress || { lat: 60.1699, lng: 24.9384 },
  };

  const onLoad = useCallback(
    map => {
      map.setOptions(mapOptions);
    },
    [mapOptions]
  );

  const workingAreaOptions = {
    strokeColor: theme.colors.success,
    strokeWeight: 2,
    strokeOpacity: 0.8,
    fillColor: theme.colors.success,
    fillOpacity: 0.6,
  };

  const renderMap = () => (
    <Fragment>
      <GoogleMap
        mapContainerStyle={{
          height: '90vh',
          width: '100%',
        }}
        options={mapOptions}
        onLoad={onLoad}
      >
        {homeAddress && (
          <Marker
            position={homeAddress}
            icon={{
              anchor: new window.google.maps.Point(16, 32),
              url: `data:image/svg+xml;utf-8, ${getMarkerIcon(
                1,
                theme.colors.primary,
                32
              )}`,
            }}
            onClick={() => setOpenInfoWindowId(0)}
          >
            {openInfoWindowId === 0 && (
              <InfoWindow onCloseClick={() => setOpenInfoWindowId(null)}>
                <Text size="small" as="p">
                  <FormattedMessage id="areas.home_address_marker_desc" />
                </Text>
              </InfoWindow>
            )}
          </Marker>
        )}
        {bookings?.map(booking => (
          <Marker
            key={booking.id}
            position={booking.address.coordinates}
            icon={{
              anchor: new window.google.maps.Point(24, 24),
              url: `data:image/svg+xml;utf-8, ${getMarkerIcon(
                booking.service.id,
                theme.colors.rating
              )}`,
            }}
            onClick={() => setOpenInfoWindowId(booking.id)}
            visible={showCustomerMarkers}
          >
            {openInfoWindowId === booking.id && (
              <InfoWindow
                onCloseClick={() => setOpenInfoWindowId(null)}
                options={{ maxWidth: 256 }}
              >
                <div>
                  <Label color="secondary">
                    <FormattedMessage
                      id={`bookings.card.service_type.${booking.service.id}`}
                    />
                  </Label>
                  <Text as="p" bold>
                    {`${booking.person.firstname} ${booking.person.lastname}`}
                  </Text>
                  <Address as="p" mb={2}>
                    <span>
                      {booking.address.street_address}
                      &#44;
                    </span>
                    <span>
                      {booking.address.postcode}
                      &nbsp;
                      {booking.address.city}
                    </span>
                  </Address>
                  <Label color="black">
                    <FormattedMessage id="areas.next_booking" />
                  </Label>
                  <Text size="small" as="p">
                    <FormattedDate
                      value={booking.start_time}
                      weekday="short"
                      day="2-digit"
                      month="short"
                      year="numeric"
                      hour="2-digit"
                      minute="2-digit"
                      hour12={false}
                    />
                    {` (${formatDuration(booking.duration, intl)})`}
                  </Text>
                  <Text
                    mt={2}
                    color="primary"
                    as="p"
                    onClick={() => goToBookingDetails(booking.id, history)}
                  >
                    <FormattedMessage id="areas.booking_details_link" />
                  </Text>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
        {workingAreas?.map(area => (
          <Polygon
            key={area.id}
            paths={area.area.coordinates}
            options={workingAreaOptions}
            visible={showWorkingAreas}
          />
        ))}
      </GoogleMap>
      <FunctionPanel>
        <MiniCard
          id="toggleWorkAreaLayer"
          onClick={() => setShowWorkingAreas(!showWorkingAreas)}
        >
          <IconPolygon
            color={
              showWorkingAreas ? theme.colors.success : theme.colors.greyMed
            }
            title={intl.formatMessage(
              {
                id: 'areas.toggle_visibility_working_area_caption',
              },
              { isShown: showWorkingAreas }
            )}
          />
        </MiniCard>
        <MiniCard
          id="toggleCustomersLayer"
          onClick={() => setShowCustomerMarkers(!showCustomerMarkers)}
        >
          <IconGroup
            color={
              showCustomerMarkers ? theme.colors.rating : theme.colors.greyMed
            }
            title={intl.formatMessage(
              {
                id: 'areas.toggle_visibility_customer_markers_caption',
              },
              { isShown: showCustomerMarkers }
            )}
          />
        </MiniCard>
      </FunctionPanel>
    </Fragment>
  );

  if (loadError) {
    return (
      <NotificationBlock hasError>
        <FormattedMessage id="areas.error_loading_map" />
      </NotificationBlock>
    );
  }

  return isLoaded ? renderMap() : <Spinner />;
};

const Address = styled(Text)`
  span {
    display: inline-block;
    padding-right: 4px;
  }
`;

const FunctionPanel = styled.section`
  margin-top: -128px;
  z-index: 10;
  width: 40px;
  position: sticky;
  margin-left: ${space.sm};
`;

const MiniCard = styled.div`
  border-radius: ${theme.borderRadius}px;
  background-color: ${theme.colors.white};
  box-shadow: ${theme.elevation.default};
  padding: ${space.sm};
  z-index: 11;
  height: 40px;
  margin-bottom: ${space.sm};
`;

WorkingAreasMap.propTypes = propTypes;
WorkingAreasMap.defaultProps = defaultProps;

export default React.memo(WorkingAreasMap);
