import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../theme/theme';

const propTypes = {
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

function Switch({ id, isChecked, handleChange }) {
  function handleKeyPress(event) {
    if (event.keyCode === 32 || event.keyCode === 13) {
      event.preventDefault();
      handleChange();
    }
  }

  return (
    <Container tabIndex="0" onKeyDown={handleKeyPress}>
      <label htmlFor={id}>
        <input
          tabIndex={-1}
          id={id}
          checked={isChecked}
          onChange={handleChange}
          className="switch"
          type="checkbox"
        />
        <div>
          <span className="icon icon-toolbar grid-view" />
          <span className="icon icon-toolbar ticket-view" />
          <div />
        </div>
      </label>
    </Container>
  );
}

const Container = styled.div`
  input[type='checkbox'].switch {
    opacity: 0;
  }

  input[type='checkbox'].switch + div {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 10px;
    border-radius: 999px;
    background-color: ${colors.background.default};
    border: 1px solid ${colors.border.default};
    border-left: none;
    transition-duration: 0.4s;
    transition-property: background-color, box-shadow;
    cursor: pointer;
  }

  input[type='checkbox'].switch + div span {
    position: absolute;
    font-size: 1.6rem;
    color: ${colors.white};
    margin-top: 12px;
  }

  input[type='checkbox'].switch + div span:nth-child(1) {
    margin-left: 15px;
  }

  input[type='checkbox'].switch + div span:nth-child(2) {
    margin-left: 55px;
  }

  input[type='checkbox'].switch:checked + div {
    width: 47px;
    background-position: 0 0;
    border: none;
  }

  input[type='checkbox'].switch + div {
    width: 47px;
    height: 24px;
  }

  input[type='checkbox'].switch:checked + div {
    background: ${colors.primary};
    border: none;
  }

  input[type='checkbox'].switch + div > div {
    border: 1px solid ${colors.border.default};
    float: left;
    width: 23px;
    height: 22px;
    border-radius: inherit;
    background: #ffffff;
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
    transition-duration: 0.15s;
    transition-property: transform, background-color;
    pointer-events: none;
  }

  input[type='checkbox'].switch:checked + div > div {
    transform: translate3d(10px, 0, 0);
    background-color: ${colors.white};
  }

  input[type='checkbox'].bigswitch.switch + div > div {
    width: 22px;
    height: 22px;
    margin-top: 1px;
  }

  input[type='checkbox'].switch:checked + div > div {
    transform: translate3d(23px, 0, 0);
    border: none;
    margin-top: 1px;
  }
`;

Switch.propTypes = propTypes;

export default Switch;
