import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { sizes } from '../../theme/theme';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const ContentContainer = ({ children }) => <Wrapper>{children}</Wrapper>;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${sizes.width.maxContent};
  width: 100%;
  min-height: 100vh;
  height: 100%;
`;

ContentContainer.propTypes = propTypes;

export default ContentContainer;
