import flatten from 'flat';
import englishContent from './content/en';
import finnishContent from './content/fi';
import swedishContent from './content/sv';

const formatTranslationMessages = messages => flatten(messages);

export default {
  en: formatTranslationMessages(englishContent),
  fi: formatTranslationMessages(finnishContent),
  sv: formatTranslationMessages(swedishContent),
};
