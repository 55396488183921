import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { typography, theme } from '@freska/freska-ui';

import FlexContainer from '../Common/FlexContainer';
import { space, sizes } from '../../theme/theme';
import LocalStorage from '../../utils/localStorage';
import { bookingsPathname } from '../../utils/urls';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
  isEmployed: PropTypes.bool.isRequired,
};

const NavMenu = ({ isOpen, closeMenu, isEmployed }) => {
  const intl = useIntl();
  return (
    <Navigation>
      <FlexContainer column alignH="space-between">
        <NavList isOpen={isOpen}>
          <MenuItem intl={intl}>
            <NavLink exact to="/" activeClassName="active" onClick={closeMenu}>
              <FormattedMessage id="nav.dashboard" />
            </NavLink>
          </MenuItem>
          <MenuItem intl={intl}>
            <NavLink exact to={bookingsPathname()} activeClassName="active" onClick={closeMenu}>
              <FormattedMessage id="nav.bookings" />
            </NavLink>
          </MenuItem>
          {!isEmployed && (
            <Fragment>
              <MenuItem intl={intl}>
                <NavLink
                  to="/availability"
                  activeClassName="active"
                  onClick={closeMenu}
                >
                  <FormattedMessage id="nav.availability" />
                </NavLink>
              </MenuItem>
              <MenuItem intl={intl}>
                <NavLink
                  to="/invoices"
                  activeClassName="active"
                  onClick={closeMenu}
                >
                  <FormattedMessage id="nav.invoices" />
                </NavLink>
              </MenuItem>
            </Fragment>
          )}
          <MenuItem intl={intl}>
            <NavLink to="/keys" activeClassName="active" onClick={closeMenu}>
              <FormattedMessage id="nav.keys" />
            </NavLink>
          </MenuItem>
          <MenuItem intl={intl}>
            <NavLink to="/areas" activeClassName="active" onClick={closeMenu}>
              <FormattedMessage id="nav.working_area" />
            </NavLink>
          </MenuItem>
          <MenuItem intl={intl}>
            <NavLink to="/ratings" activeClassName="active" onClick={closeMenu}>
              <FormattedMessage id="nav.rating" />
            </NavLink>
          </MenuItem>
          <MenuItem intl={intl}>
            <NavLink to="/profile" activeClassName="active" onClick={closeMenu}>
              <FormattedMessage id="nav.profile" />
            </NavLink>
          </MenuItem>
          <MenuItem intl={intl}>
            <NavLink to="/news" activeClassName="active" onClick={closeMenu}>
              <FormattedMessage id="nav.news" />
            </NavLink>
          </MenuItem>
          <MenuItem intl={intl}>
            <NavLink to="/support" activeClassName="active" onClick={closeMenu}>
              <FormattedMessage id="nav.support" />
            </NavLink>
          </MenuItem>
          {LocalStorage.get('countrycode') === 'NO' && (
            <MenuItem intl={intl}>
              <a href="https://docs.google.com/forms/d/19ZhrOp4oZOirJou_A5Ipf00mkH2FwsXBHBeY48zV7T0/edit" rel="noopener noreferrer" target="_blank" >
              <FormattedMessage id="nav.hse_form" />
              </a>
            </MenuItem>
          )}
        </NavList>
      </FlexContainer>
    </Navigation>
  );
};

const Navigation = styled.nav`
  background: ${theme.colors.white};
  box-shadow: ${theme.elevation.default};
  border-radius: 0 0 10px 10px;
  position: absolute;
  left: 0;
  top: ${sizes.height.header};
  max-width: ${sizes.width.maxContent};
  width: 100%;
  padding: ${space.default} ${space.default} ${space.lg} ${space.default};
  z-index: 9;
`;

const NavList = styled.ul`
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition: opacity 0.2s ease;

  @media (min-width: 1300px) {
    padding: ${space.default} ${space.default * 3};
  }
`;

const MenuItem = styled.li`
  width: 100%;
  margin: 0 0 ${space.default} 0;

  & a {
    ${typography.fontLarge()};
    ${typography.fontBold()};
    color: ${theme.colors.primary};
    text-decoration: none;
  }

  & a.active,
  a:visited {
    color: ${theme.colors.primary};
  }

  & a.active:after {
    content: ' • ${props =>
      props.intl.formatMessage({ id: 'nav.you_are_here' })}'
    ;
    ${typography.fontSmall()};
    color: ${theme.colors.secondary};
    margin-left: ${space.sm};
    display: inline-block;
  }

  &:last-child {
    margin: 0 0 0 0;
  }
`;

NavMenu.propTypes = propTypes;

export default withRouter(NavMenu);
