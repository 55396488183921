import React from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { Box, Spinner } from '@freska/freska-ui';

import { GET_SERVICE_WORKER_KEYS } from '../../gql/queries';
import ViewGrid from '../../components/Common/ViewGrid';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import KeyDetails from '../../components/Keys/KeyDetails';
import KeysList from '../../components/Keys/KeysList';
import { useServiceWorker } from '../../useServiceWorker';
import { NETWORK_STATUS_ENUM } from '../../constants';

function KeysContainer() {
  const { onlineStatus } = useServiceWorker();
  const { networkStatus, error, data } = useQuery(GET_SERVICE_WORKER_KEYS, {
    pollInterval: onlineStatus ? 60000 : 0,
  });

  if (networkStatus === NETWORK_STATUS_ENUM.LOADING) {
    return (
      <ViewGrid>
        <Box p={3}>
          <Spinner />
        </Box>
      </ViewGrid>
    );
  }
  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (data && data.getServiceWorkerKeys) {
    const keys = data.getServiceWorkerKeys;
    const pickUpKeys = keys.filter(key => key.status === 'at_office');
    const returnKeys = keys.filter(key => !!key.return_date);
    const otherKeys = keys.filter(
      key => key.status !== 'at_office' && !key.return_date
    );
    return (
      <Switch>
        {keys.map(key => (
          <Route
            path={`/keys/${key.id}`}
            key={key.id}
            render={() => <KeyDetails keyInfo={key} />}
          />
        ))}
        <Route
          path="/"
          render={() => (
            <ViewGrid>
              {!!pickUpKeys.length && (
                <KeysList keys={pickUpKeys} status="pick_up" />
              )}
              {!!returnKeys.length && (
                <KeysList keys={returnKeys} status="return" />
              )}
              <KeysList keys={otherKeys} status="not_urgent" />
            </ViewGrid>
          )}
        />
      </Switch>
    );
  }
  return null;
}

export default withRouter(KeysContainer);
