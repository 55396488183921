import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Tag, theme } from '@freska/freska-ui';
import { space } from '../../theme/theme';
import FlexContainer from './FlexContainer';

const propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const Blockquote = ({ label, color, children }) => (
  <Fragment>
    <Tag color={color}>{label}</Tag>
    <StyledContainer column borderColor={color}>
      {children}
    </StyledContainer>
  </Fragment>
);

const StyledContainer = styled(FlexContainer)`
  border-left: 2px solid ${props => theme.colors[props.borderColor]};

  margin: ${space.xs} 0 ${space.md};
  padding: ${space.sm};

  &:last-child {
    margin-bottom: 0;
  }
`;

Blockquote.propTypes = propTypes;

export default Blockquote;
