import React, { Component } from 'react';
import { shape, func, oneOf, bool } from 'prop-types';
import styled from 'styled-components';
import { theme, typography, Text, Box, IconReceipt } from '@freska/freska-ui';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { trackEvent } from '../../utils/tracking';
import { formatPrice } from '../../utils/formatNumber';
import withLanguage from '../../utils/withLanguage';
import { LANGUAGES_TYPE } from '../../constants';

import FlexContainer from '../Common/FlexContainer';

import { space, sizes } from '../../theme/theme';
import { invoiceType } from '../../types';

const propTypes = {
  data: invoiceType.isRequired,
  history: shape({ push: func }).isRequired,
  language: oneOf(LANGUAGES_TYPE).isRequired,
  isPending: bool,
};

const defaultProps = {
  isPending: false,
};

class InvoiceCard extends Component {
  card = React.createRef();

  componentDidMount() {
    this.card.current.addEventListener('keypress', this.checkKeyCode);
  }

  componentWillUnmount() {
    this.card.current.removeEventListener('keypress', this.checkKeyCode);
  }

  checkKeyCode = e => {
    const code = e.keyCode;
    if (code === 13) {
      this.goToDetails();
    }
  };

  goToDetails = () => {
    const { data, history } = this.props;
    trackEvent('Invoice details opened', {
      category: 'Invoices',
    });

    history.push({
      pathname: `/invoices/${data.id}`,
      state: { invoiceOrigin: true },
    });
  };

  render() {
    const { data, language, isPending } = this.props;
    return (
      <CardContainer
        ref={this.card}
        onClick={this.goToDetails}
        id={data.id}
        as="article"
        className="card-section"
      >
        <IconContainer isPending={isPending}>
          <IconReceipt color={isPending ? 'white' : 'secondary'} size={16} />
        </IconContainer>
        <CardContentContainer>
          {isPending && (
            <InvoiceStatusLabel>
              <FormattedMessage id={`invoices.status.${data.status}`} />
            </InvoiceStatusLabel>
          )}
          <TimeFlexContainer alignV="center">
            <DetailsWrapper>
              <FormattedDate
                value={data.data.bookings_from}
                year="numeric"
                month="2-digit"
                day="2-digit"
              />
              &ndash;
              <FormattedDate
                value={data.data.bookings_to}
                year="numeric"
                month="2-digit"
                day="2-digit"
              />
            </DetailsWrapper>
            {!isPending && data.status && (
              <DetailsWrapper>
                <FormattedMessage id={`invoices.status.${data.status}`} />
              </DetailsWrapper>
            )}
          </TimeFlexContainer>
          <MainContentContainer>
            <Text as="span">{data.our_reference}</Text>
            <Text as="span" align="right" bold>
              {formatPrice(
                Number(data.total_amount_vat_excluded),
                language,
                data.currency
              )}
            </Text>
          </MainContentContainer>
        </CardContentContainer>
      </CardContainer>
    );
  }
}

const CardContainer = styled(Box).attrs(props => ({
  tabIndex: 0,
  id: props.id,
}))`
  ${typography.fontRegular()};
  display: grid;
  grid-gap: ${space.md};
  grid-template-columns: ${sizes.width.customerTypeIcon} 1fr;
  margin-bottom: ${space.md};
  max-width: ${sizes.width.bookingCardMaxWidth};
`;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1;
  justify-content: center;
  height: ${sizes.width.customerTypeIcon};
  width: ${sizes.width.customerTypeIcon};

  background: ${props =>
    props.isPending ? theme.colors.attention : theme.colors.greyLight};
  border-radius: 50%;
`;

const CardContentContainer = styled.section`
  grid-column: 2;
  border-bottom: 1px solid ${theme.colors.greyMed};
  padding-bottom: ${space.sm};
`;

const InvoiceStatusLabel = styled(FlexContainer)`
  ${typography.fontLabel()};
  color: ${theme.colors.attention};
`;

const TimeFlexContainer = styled(FlexContainer)`
  ${typography.fontSmall()};
  color: ${theme.colors.secondary};
  margin-bottom: ${space.xs};

  justify-content: flex-start;
  display: inline-flex;
  flex-wrap: wrap;
`;

const DetailsWrapper = styled.div`
  &:before {
    content: '•';
    padding: 0 ${space.sm};
  }

  &:first-child:before {
    content: '';
    padding: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const MainContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${space.sm};
`;

InvoiceCard.propTypes = propTypes;
InvoiceCard.defaultProps = defaultProps;

export default withRouter(withLanguage(InvoiceCard));
