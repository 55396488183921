import React, { Component } from 'react';
import { shape, func, number } from 'prop-types';
import styled from 'styled-components';
import { Button, TextArea, theme, Checkbox, Link } from '@freska/freska-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import {
  isSameDay,
  format,
  setISODay,
  getDay,
  parseISO,
  endOfISOWeek,
} from 'date-fns';

import {
  GET_BOOKINGS,
  GET_BOOKINGS_VIEW_DATA,
  GET_BOOKING,
} from '../../gql/queries';
import { trackEvent } from '../../utils/tracking';
import validateData, { checkForError } from '../../utils/validate';
import withUser from '../../utils/withUser';
import schema from './schema';
import { CONFIRM_BOOKING } from '../../gql/mutations';
import { BOOKING_STATUS_ENUM } from '../../constants';
import FlexContainer from '../Common/FlexContainer';
import Form from '../Common/Form';
import { space } from '../../theme/theme';
import { ServiceWorkerContext } from '../../useServiceWorker';

const propTypes = {
  intl: shape({}).isRequired,
  data: shape({ id: number }).isRequired,
  mutate: func.isRequired,
  user: shape({}).isRequired,
  history: shape({ push: func }).isRequired,
};

const QUALITY_RATING_THRESHOLD = 4.7;
const NEW_WORKER_THRESHOLD = 12;

class BookingDetailsUnconfirmed extends Component {
  state = {
    comment: '',
    isLoading: false,
    errors: {},
    isDisabled: true
  };

  handleSubmit = e => {
    e.preventDefault();
    const { data } = this.props;

    trackEvent('Bookings: Booking confirmed', {
      category: 'Bookings',
      bookingId: data.id,
    });
    const { comment } = this.state;
    this.setState({ isLoading: true });
    // load for a bit to be more user friendly
    setTimeout(() => {
      validateData({ comment }, schema).then(res =>
        this.setState(
          { errors: res },
          () => Object.keys(res).length === 0 && this.processSubmit()
        )
      );
    }, 500);
  };

  onChange = e => {
    this.setState({
      comment: e.target.value,
    });
  };

  processSubmit() {
    const { mutate, data: unconfirmedBooking } = this.props;
    const { comment } = this.state;

    mutate({
      variables: {
        bookingId: unconfirmedBooking.id,
        comment,
      },
      awaitRefetchQueries: true,
      refetchQueries: () => [
        { query: GET_BOOKING, variables: { bookingId: unconfirmedBooking.id } },
      ],
      update: (store, { data: { confirmBooking } }) => {
        // remove now confirmed booking from unconfirmed bookings
        try {
          const unconfirmedBookingsQueryVariables = {
            bookingStatus: BOOKING_STATUS_ENUM.UNCONFIRMED,
          };
          const dataUnconfirmed = store.readQuery({
            query: GET_BOOKINGS,
            variables: unconfirmedBookingsQueryVariables,
          });

          dataUnconfirmed.getBookings.forEach((bookingsByDate, index) => {
            dataUnconfirmed.getBookings[
              index
            ].bookings = bookingsByDate.bookings.filter(
              booking => booking.id !== confirmBooking.booking_id
            );
          });
          store.writeQuery({
            query: GET_BOOKINGS,
            variables: unconfirmedBookingsQueryVariables,
            data: dataUnconfirmed,
          });
        } catch (err) {
          // that query was not called so it cannot be read. Just do nothing
        }

        // add that booking to bookings list
        try {
          const startDate = format(
            setISODay(
              parseISO(unconfirmedBooking.start_time),
              getDay(new Date())
            ),
            'yyyy-MM-dd'
          );
          const allBookingsQueryVariables = {
            startDate,
            endDate: format(endOfISOWeek(parseISO(startDate)), 'yyyy-MM-dd'),
          };
          const dataAll = store.readQuery({
            query: GET_BOOKINGS_VIEW_DATA,
            variables: allBookingsQueryVariables,
          });
          const bookingsFromTheSameDay = dataAll.getBookingsViewData.bookings.findIndex(
            ({ date }) =>
              isSameDay(parseISO(date), parseISO(unconfirmedBooking.start_time))
          );
          const bookingIndex = dataAll.getBookingsViewData.bookings[
            bookingsFromTheSameDay
          ].bookings.findIndex(
            booking => booking.id === confirmBooking.booking_id
          );

          dataAll.getBookingsViewData.bookings[bookingsFromTheSameDay].bookings[
            bookingIndex
          ].isUnconfirmed = false;

          store.writeQuery({
            query: GET_BOOKINGS_VIEW_DATA,
            variables: allBookingsQueryVariables,
            data: dataAll,
          });
        } catch (err) {
          // that query was not called so it cannot be read. Just do nothing
        }
      },
      optimisticResponse: {
        confirmBooking: {
          booking_id: unconfirmedBooking.id,
          internal_comments: null,
          id: 1,
          comments: comment,
          __typename: 'WorkReport',
        },
      },
    }).catch(() => { });
  }

  render() {
    const { intl, user } = this.props;
    const { comment, isLoading, errors, isDisabled } = this.state;

    const isInFinland = user.region.countrycode === 'FI';
    const requiresCheckList = isInFinland && (user.average_rating_details.workmanship < QUALITY_RATING_THRESHOLD || user.past_bookings_count < NEW_WORKER_THRESHOLD);
    return (
      <ServiceWorkerContext.Consumer>
        {({ onlineStatus }) => (
          <UnconfirmedBookingContainer>
            <Form name="unconfirmed-booking">
              <TextArea
                label={intl.formatMessage({
                  id: 'bookings.unconfirmed.comments_to_freska',
                })}
                placeholder={intl.formatMessage({
                  id: 'bookings.unconfirmed.comments_to_freska_placeholder',
                })}
                name="freska-comments"
                rows={5}
                hasError={checkForError(errors, 'comment')}
                mb={2}
                value={comment}
                onChange={this.onChange}
                disabled={!onlineStatus}
              />
              {requiresCheckList && (
                <>
                  <Link
                    as="button"
                    color="primary"
                    underline
                    onClick={() => {
                      trackEvent('Cleaning checklist opened', {
                        category: 'Bookings',
                      });
                      this.props.history.push("/support/cleaning-checklist");
                    }}
                    mb={1}
                  >
                    Cleaning checklist
                  </Link>
                  <Checkbox
                    id="checked-checklist"
                    onChange={() => this.setState({ ...this.state, isDisabled: !isDisabled })}
                    checked={!isDisabled}
                    name="Continuous"
                    label="I went through the cleaning checklist"
                    mt={1}
                    mb={2}
                  />
                </>
              )}
              <FlexContainer>
                <Button
                  mb={3}
                  type="submit"
                  loading={isLoading}
                  onClick={this.handleSubmit}
                  disabled={!onlineStatus || (requiresCheckList && isDisabled)}
                >
                  <FormattedMessage id="bookings.unconfirmed.confirm_as_completed" />
                </Button>
              </FlexContainer>
            </Form>
          </UnconfirmedBookingContainer>
        )
        }
      </ServiceWorkerContext.Consumer>
    );
  }
}

const UnconfirmedBookingContainer = styled.section`
  border-bottom: 1px solid ${theme.colors.greyMed};
  margin-bottom: ${space.default};
`;

BookingDetailsUnconfirmed.propTypes = propTypes;

export default graphql(CONFIRM_BOOKING)(withRouter((withUser(injectIntl(BookingDetailsUnconfirmed)))));
