import React from 'react';
import { func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from '@freska/freska-ui';

const propTypes = {
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  titleId: string.isRequired,
  messageId: string.isRequired,
  buttonCaptionId: string.isRequired,
};

const ConfirmationModal = ({
  onClose,
  onConfirm,
  titleId,
  messageId,
  buttonCaptionId,
}) => (
  <Modal onClose={onClose} maxWidth="327px" m={0}>
    <Modal.Title>
      <FormattedMessage id={titleId} />
    </Modal.Title>
    <Modal.Description>
      <FormattedMessage id={messageId} />
    </Modal.Description>
    <Modal.Content
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Button variant="destructive" onClick={onConfirm}>
        <FormattedMessage id={buttonCaptionId} />
      </Button>
    </Modal.Content>
  </Modal>
);

ConfirmationModal.propTypes = propTypes;

export default ConfirmationModal;
