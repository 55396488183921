import {
  shape,
  string,
  number,
  bool,
  arrayOf,
  instanceOf,
  oneOfType,
} from 'prop-types';

export const ratingType = shape({
  average: number,
  behavior: number,
  overall: number,
  punctuality: number,
  workmanship: number,
  comments: string,
});

export const serviceWorkerType = shape({
  id: number,
  firstname: string,
  lastname: string,
});

export const companyType = shape({
  name: string,
});

export const personType = shape({
  firstname: string,
  lastname: string,
  phonenumber: string,
  email: string,
});

export const coordinatesType = shape({
  lat: number,
  lng: number,
});

export const addressType = shape({
  street_address: string,
  postcode: string,
  city: string,
  coordinates: coordinatesType,
});

export const keyType = shape({
  id: number,
  label: string,
  notes: string,
  return_date: string,
  service_worker_id: number,
  status: string,
});

export const accessType = shape({
  booking_id: number,
  door_code: string,
  key: keyType,
  key_id: string,
  notes: string,
  status: string,
});

export const serviceType = shape({
  id: number,
  name: string,
});

export const workReportType = shape({
  internal_comments: string,
});

export const petsType = shape({
  hasCats: bool,
  hasDogs: bool,
  hasOtherPets: bool,
  petsInfo: string,
});

export const additionalServiceType = shape({
  id: number,
  name: string,
});

export const detailSummaryType = shape({
  extendedDuration: number,
  accessStatus: string,
  additionalServices: arrayOf(additionalServiceType),
  addressNotes: string,
  customerNotes: string,
  needsCleaningSupplies: bool,
  pets: petsType,
});

export const windowCleaningDetailsType = shape({
  balcony: bool,
  balcony_panes: number,
  blinds: bool,
  number_of_layers: number,
  single_windows: number,
  ventilation_windows: number,
  window_groups: number,
});

export const availabilityType = shape({
  availabilityId: number,
  end_time: string,
  id: oneOfType([number, string]),
  start_time: string,
  duration: number,
});

export const workingScheduleType = shape({
  date: string,
  day_start: string,
  day_end: string,
  lunch_break_start: string,
  lunch_break_end: string,
  lunchBreakDuration: number,
  locked: bool,
});

export const bookingType = shape({
  access: accessType,
  address: addressType,
  company: companyType,
  customer_id: number,
  detailSummary: detailSummaryType,
  duration: number,
  end_time: string,
  frequency: string,
  id: number,
  invoiceable_duration: number,
  isUnconfirmed: bool,
  person: personType,
  rating: ratingType,
  recurring_booking_id: number,
  service: serviceType,
  service_worker: serviceWorkerType,
  start_time: string,
  status: string,
  window_cleaning_details: windowCleaningDetailsType,
  work_report: workReportType,
});

export const serviceWorkerKeysType = shape({
  id: number,
  label: string,
  notes: string,
  return_date: string,
  service_worker_id: number,
  status: string,
  door_code: string,
  customer_id: number,
  nextBooking: bookingType,
});

export const supportContentType = shape({
  audience: string,
  content: string,
  description: string,
  slug: string,
  topic: string,
});

export const feedbackBookingType = shape({
  id: number,
  start_time: string,
  recurring_booking_id: number,
  rating: shape({
    average: number,
    comments: string,
  }),
});

export const vendorType = shape({
  active: bool,
  business_id: string,
  created_at: instanceOf(Date),
  customer_id: number,
  e_invoice_address: string,
  e_invoice_edi_id: string,
  e_invoice_operator: string,
  id: number,
  invoice_email: string,
  invoice_method: string,
  name: string,
  order_reference: string,
  payment_term_net_days: number,
  updated_at: instanceOf(Date),
  vat_number: string,
});

export const purchaseInvoiceType = shape({
  accepted: bool,
  accepted_at: instanceOf(Date),
  automatically_matched: bool,
  automatically_matched_at: instanceOf(Date),
  created_at: instanceOf(Date),
  currency: string,
  date: string,
  due_date: string,
  id: number,
  invoice_number: string,
  manually_matched: bool,
  manually_matched_at: instanceOf(Date),
  matching_notes: string,
  our_reference: string,
  paid: bool,
  paid_on: instanceOf(Date),
  purchase_order_ids: arrayOf(number),
  region_id: number,
  total_amount: string,
  total_amount_vat_excluded: string,
  total_vat_amount: string,
  updated_at: instanceOf(Date),
  vendor: vendorType,
  your_reference: string,
});

export const invoiceType = shape({
  id: number,
  allowed_due_date: string,
  countrycode: string,
  currency: string,
  date: string,
  dates_editable: bool,
  invoiced: bool,
  may_be_sent_to_ukko: bool,
  ordered: bool,
  our_reference: string,
  paid: bool,
  payment_term: number,
  region_id: number,
  total_amount: string,
  total_amount_vat_excluded: string,
  total_vat_amount: string,
  service_worker: shape({
    id: string,
    firstname: string,
    lastname: string,
  }),
  data: shape({
    bookings_to: string,
    bookings_from: string,
    service_worker_id: number,
  }),
  lines: arrayOf(
    shape({
      booking: bookingType,
      data: shape({
        booking_id: number,
        service_worker_id: number,
      }),
      description: string,
      id: number,
      quantity: string,
      total_amount: string,
      total_amount_vat_excluded: string,
      unit: string,
      unit_price: string,
      vat_amount: string,
    })
  ),
  purchase_invoices: arrayOf(purchaseInvoiceType),
  vendor: vendorType,
});

export const availabilityGroupType = shape({
  id: oneOfType([string, number]),
  service_worker_id: number,
  valid_to: string,
  valid_from: string,
  only_one_offs: bool,
  schedule: arrayOf(shape({ start: string, end: string })),
});

export const availabilityCalendarType = shape({
  bookings: arrayOf(
    shape({
      date: string,
      bookings: arrayOf(
        shape({
          id: number,
          start_time: string,
          end_time: string,
          isUnconfirmed: bool,
        })
      ),
    })
  ),
  availabilities: arrayOf(availabilityType),
});

export const areaType = shape({
  type: string,
  coordinates: arrayOf(coordinatesType),
});

export const workingAreaType = shape({
  id: number,
  created_at: string,
  updated_at: string,
  service_worker_id: number,
  area: areaType,
  labelPosition: coordinatesType,
  working_area_template_id: number,
});

export const workingAreaViewType = shape({
  areasOfDemand: arrayOf(
    shape({
      id: number,
      name: string,
      region_id: number,
      area: areaType,
      workingAreas: workingAreaType,
      bookings: arrayOf(
        shape({
          id: number,
          start_time: string,
          end_time: string,
          isUnconfirmed: bool,
        })
      ),
    })
  ),
  bookings: arrayOf(
    shape({
      date: string,
      bookings: arrayOf(bookingType),
    })
  ),
  homeAddress: coordinatesType,
});

export const thumbImageFile = shape({
  fileName: string,
  contentType: string,
  details: shape({
    image: shape({ width: number, height: number }),
    size: number,
  }),
  url: string,
});

export const thumbImage = shape({
  fields: shape({ title: string, file: thumbImageFile }),
});

export const newsContentType = shape({
  country: arrayOf(string),
  audience: string,
  slug: string,
  title: string,
  thumbImage,
  body: string,
  publishedAt: string,
  updatedAt: string,
});
