import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { Box, Card, IconChevronRight,  Spinner, Text, theme } from '@freska/freska-ui';

import {  sizes } from '../../theme/theme';

const propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
}

function LatestNews({ items, loading, error }) {
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Text bold ml={2}>
          <FormattedMessage id="dashboard.latest_news"/>
        </Text>
        <StyledLink to="/news">
          <Box display="flex" alignItems="center">
          <Text color="primary">
            <FormattedMessage id="dashboard.all_news" />
          </Text>
          <IconChevronRight color="primary" size={24} />
          </Box>
        </StyledLink>
      </Box>
      <Card mt={1} mb={3} p={0} pb={2}>

        {loading && <Box pt={2}><Spinner size={24} /></Box>}

        {error && <Text pt={2} size="small" textAlign="center" color="alert"><FormattedMessage id="dashboard.news_fetch_failed" /></Text>}

        {items?.sort((prev,next) => new Date(next.publishOn) - new Date(prev.publishOn)).map((item, index, array) => (
          <StyledLink to={`latest-news/${item.slug}`} key={item.slug}>
            <CardContainer px={2} pt={2} className="card-ss">
              {item.thumbImage && (
                <ThumbImage imageURL={`https:${item.thumbImage.fields.file.url}`} />
              )}
              <Box flex={1}>
                <Text as="p" bold mb="4px" color="primary">
                  {item.title}
                </Text>
                <Text as="p" size="small" color="secondary">
                  <FormattedMessage
                    id="dashboard.published"
                    values={{
                      date: format(parseISO(item.publishOn), 'MMM d, yyyy'),
                      time: format(parseISO(item.publishOn), 'HH:mm'),
                    }}
                  />
                </Text>
              </Box>
            </CardContainer>
            {index !== array?.length -1 && <Divider mt={2} mx={2} /> }
          </StyledLink>
        ))}
      </Card>
    </>
  );
}

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;


const Divider = styled(Box)`
  background: ${theme.colors.greyMed};
  height: 1px;
`;

const ThumbImage = styled.div`
  flex-shrink: 0;
  background-image: url(${props => props.imageURL});
  background-position: center;
  background-size: cover;
  clip-path: inset(0% 0% 0% 0% round ${theme.borderRadius}px);
  width: 100%;
  max-width: 66px;
  height: 88px;

  margin-right: ${theme.space[2]}px;
`;

const CardContainer = styled(Box)`
  display: flex;
  flex-direction: row;

  max-width: ${sizes.width.bookingCardMaxWidth};
  width: calc(100% - calc(${theme.space[2]}px));
  cursor: pointer;
`;

LatestNews.propTypes = propTypes;

export default LatestNews;