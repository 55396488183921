import React from 'react';
import PropTypes, { string } from 'prop-types';
import { IntlProvider } from 'react-intl';

import languageContent from './index';
import withLanguage from '../utils/withLanguage';

export const LANGUAGE = Object.freeze(['fi', 'en', 'sv']);

const propTypes = {
  children: PropTypes.node,
  language: string,
};

const defaultProps = {
  children: {},
};

const LanguageProvider = ({ children }) => {
  // TODO: Uncomment when launching
  // const locale = LANGUAGE.find(lang => language.startsWith(lang));
  return (
    <IntlProvider locale={'en'} key={'en'} messages={languageContent['en']}>
      {React.Children.only(children)}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = propTypes;
LanguageProvider.defaultProps = defaultProps;

export default withLanguage(LanguageProvider);
