import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Animate } from 'react-animate-mount';
import { theme } from '@freska/freska-ui';
import { space } from '../../theme/theme';

const propTypes = {
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  weekNum: PropTypes.number.isRequired,
  isShown: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isBookingsCalendar: PropTypes.bool,
};

const defaultProps = {
  isShown: false,
  isBookingsCalendar: false,
};

class WeekGraphWrapper extends PureComponent {
  render() {
    const {
      forwardRef,
      isShown,
      weekNum,
      children,
      isBookingsCalendar,
    } = this.props;
    return (
      <WeekGraphDisplay
        show={isShown}
        ref={forwardRef}
        weekNum={weekNum}
        isBookingsCalendar={isBookingsCalendar}
      >
        <Animate show={isShown} duration={225}>
          {children}
        </Animate>
      </WeekGraphDisplay>
    );
  }
}

const WeekGraphDisplay = styled.section`
  grid-area: ${props => `pre-${props.weekNum}`};
  margin-top: -${space.xs};
  margin-bottom: ${space.xs};
  ${props =>
    props.show &&
    !props.isBookingsCalendar &&
    `border-bottom: 1px solid ${theme.colors.secondary}`};
`;

WeekGraphWrapper.propTypes = propTypes;
WeekGraphWrapper.defaultProps = defaultProps;

export default WeekGraphWrapper;
