import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as tracking from './tracking';
import checkAuth from './checkAuth';

// eslint-disable-next-line react/prop-types
export const TrackedRoute = ({ render, ...rest }) => {
  tracking.pageView();
  return <Route {...rest} render={render} />;
};

// eslint-disable-next-line react/prop-types
export const ResetPasswordRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = checkAuth();
  const params = new URLSearchParams(window.location.search);
  const headers = {
    'access-token': params.get('token'),
    uid: params.get('uid'),
    client: params.get('client_id'),
    expiry: params.get('expiry'),
  };
  const areHeadersValid = Object.values(headers).every(header => !!header);
  return (
    <TrackedRoute
      {...rest}
      render={props =>
        !isAuthenticated && areHeadersValid ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

// eslint-disable-next-line react/prop-types
export const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const isAuthenticated = checkAuth();
  return (
    <TrackedRoute
      {...rest}
      render={props =>
        isAuthenticated ? (
          rest.render ? (
            rest.render(props)
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  );
};
