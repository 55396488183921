import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Text, theme } from '@freska/freska-ui';
import { v4 as uuidv4 } from 'uuid';

import { sizes, space } from '../../theme/theme';
import { DAY_ABBREV_TYPE } from '../../constants';

const DaysRow = () => (
  <Row>
    <span />
    {DAY_ABBREV_TYPE.map(day => (
      <Text key={uuidv4()} as="span" size="smaller" align="center">
        <FormattedMessage id={`dates.${day}`} />
      </Text>
    ))}
  </Row>
);

const Row = styled.section`
  background: ${theme.colors.greyLight};

  display: grid;
  grid-template-columns: 0.7fr repeat(7, 1fr);
  padding: ${space.md} ${space.sm} ${space.xs} 0;
  margin-bottom: 2px;
  position: sticky;
  top: ${sizes.height.header};
  z-index: 2;

  @media (min-width: 600px) {
    padding: ${space.md} ${space.default} ${space.xs} 0;
  }
`;

export default DaysRow;
