import React, { PureComponent } from 'react';
import { instanceOf, shape, bool } from 'prop-types';
import styled from 'styled-components';
import {
  eachDayOfInterval,
  startOfISOWeek,
  endOfISOWeek,
  getISOWeek,
  getDate,
  getMonth,
  format,
  differenceInSeconds,
  parseISO,
} from 'date-fns';
import { Box } from '@freska/freska-ui';
import withLanguage from '../../utils/withLanguage';
import DayItem from '../Calendar/DayItem';
import WeekGraphWrapper from '../Calendar/WeekGraphWrapper';
import BookingWeekGraph from './BookingWeekGraph';
import { space } from '../../theme/theme';

const propTypes = {
  data: shape({}).isRequired,
  startDate: instanceOf(Date),
  showWeekGraph: bool,
};

const defaultProps = {
  startDate: null,
  showWeekGraph: false,
};

class BookingWeekPreview extends PureComponent {
  getWeekdays = () => {
    const { startDate } = this.props;
    const weekDays = eachDayOfInterval({
      start: startOfISOWeek(startDate),
      end: endOfISOWeek(startDate),
    });
    return weekDays;
  };

  findBookingsOnDay = (bookings, day) => {
    const bookingsOnDay = bookings.find(
      av => av.date === format(day, 'yyyy-MM-dd')
    );
    return bookingsOnDay ? bookingsOnDay.bookings : [];
  };

  render() {
    const { data, startDate, showWeekGraph } = this.props;
    const weekDays = this.getWeekdays();
    const weekNum = getISOWeek(startDate);

    return (
      <WeekRow key={`week-${weekNum}`} weekNum={weekNum}>
        {weekDays.map((day, dayIndex) => (
          <DayItem
            day={day}
            bookingsArray={this.findBookingsOnDay(
              data.bookings,
              day
            ).sort((previous, next) =>
              differenceInSeconds(
                parseISO(previous.start_time),
                parseISO(next.start_time)
              )
            )}
            isAvailable={
              !!data.availabilities.find(
                av => av.date === format(day, 'yyyy-MM-dd')
              )
            }
            key={`day-${getDate(day)}`}
            weekIndex={0}
            dayIndex={dayIndex}
            date={getDate(day)}
            month={getMonth(day)}
            isBookingsCalendar
            originBookingsView
          />
        ))}
        {showWeekGraph && (
          <WeekGraphWrapper
            isShown
            forwardRef={el => {
              this[`week-graph-${weekNum}`] = el;
            }}
            weekNum={weekNum}
            isBookingsCalendar
          >
            <BookingWeekGraph
              data={data}
              weekNum={weekNum}
              weekDays={weekDays}
            />
          </WeekGraphWrapper>
        )}
      </WeekRow>
    );
  }
}

const WeekRow = styled(Box)`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(7, 1fr);
  grid-template-areas:
  '. . . . . . .'
  '${props =>
    `pre-${props.weekNum} pre-${props.weekNum} pre-${props.weekNum} pre-${props.weekNum} pre-${props.weekNum} pre-${props.weekNum} pre-${props.weekNum}`}'
    ;
  padding: 0 ${space.xs} ${space.xs};

  @media (min-width: 600px) {
    padding: 0 ${space.sm} ${space.default};
  }
`;

BookingWeekPreview.propTypes = propTypes;
BookingWeekPreview.defaultProps = defaultProps;

export default withLanguage(BookingWeekPreview);
