import React, { Component } from 'react';
import { shape, bool, object, arrayOf } from 'prop-types';
import { graphql } from 'react-apollo';
import { Box, Spinner } from '@freska/freska-ui';
import { GET_INVOICES } from '../../gql/queries';

import ViewGrid from '../../components/Common/ViewGrid';
import InvoicesList from '../../components/Invoices/InvoicesList';
import CreateNewInvoice from '../../components/Invoices/CreateNewInvoice';
import InvoiceableBookingsList from '../../components/Invoices/InvoiceableBookingsList';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import NoContent from '../../components/Common/NoContent';
import { invoiceType, bookingType } from '../../types';

const propTypes = {
  data: shape({
    loading: bool,
    error: object,
    getInvoices: shape({
      invoices: arrayOf(invoiceType),
      uninvoicedBookings: arrayOf(bookingType),
    }),
  }).isRequired,
};

class InvoicesContainer extends Component {
  state = {
    startDate: '',
    endDate: '',
    bookings: [],
  };

  onCreateNewInvoice = ({ startDate = '', endDate = '', bookings = [] }) => {
    this.setState({
      startDate,
      endDate,
      bookings,
    });
  };

  render() {
    const {
      data: { loading, error, getInvoices },
    } = this.props;
    const { startDate, endDate, bookings } = this.state;

    if (loading) {
      return (
        <ViewGrid>
          <Box p={3}>
            <Spinner />
          </Box>
        </ViewGrid>
      );
    }

    if (error) {
      return <ErrorHandler error={error} />;
    }

    if (
      !getInvoices.invoices.length &&
      !getInvoices.uninvoicedBookings.length
    ) {
      return <NoContent messageId="invoices.no_invoices" />;
    }

    const pendingInvoices = getInvoices.invoices.filter(
      invoice => !invoice.invoiced
    );
    const invoices = getInvoices.invoices.filter(invoice => invoice.invoiced);

    if (startDate) {
      return (
        <CreateNewInvoice
          startDate={startDate}
          endDate={endDate}
          bookings={bookings}
          onGoBack={this.onCreateNewInvoice}
        />
      );
    }

    return (
      <ViewGrid>
        {!!getInvoices.uninvoicedBookings.length && (
          <InvoiceableBookingsList
            bookings={getInvoices.uninvoicedBookings}
            onCreateNewInvoice={this.onCreateNewInvoice}
          />
        )}
        <InvoicesList invoices={pendingInvoices} isPending />
        <InvoicesList invoices={invoices} />
      </ViewGrid>
    );
  }
}

InvoicesContainer.propTypes = propTypes;

export default graphql(GET_INVOICES)(InvoicesContainer);
