import React from 'react';
import styled from 'styled-components';
import { arrayOf, shape, func, string, bool } from 'prop-types';
import NavBar from './NavBar';
import { FreskaProBanner } from '../FreskaProBanner';
import { sizes } from '../../theme/theme';

const propTypes = {
  actions: arrayOf(
    shape({ handler: func, component: func, iconTooltip: string })
  ),
  hasBanner: bool,
};

const defaultProps = {
  actions: [],
  hasBanner: false,
};

const Header = ({ actions, hasBanner }) => (
  <>
    {hasBanner && <FreskaProBanner />}
    <HeaderContainer id="AppHeader" top={hasBanner ? '60px' : '0'} >
      <NavBar actions={actions} />
    </HeaderContainer>
  </>
);

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: ${sizes.height.header};
  width: 100%;
  position: sticky;
  top: ${props => props.top};
  z-index: 9;
`;

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
