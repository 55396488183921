import React from 'react';
import { shape, number, bool, instanceOf, func } from 'prop-types';
import styled from 'styled-components';
import { Text, theme } from '@freska/freska-ui';
import { isToday, getDayOfYear } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import FlexContainer from '../Common/FlexContainer';
import { colors, space } from '../../theme/theme';
import { MONTH_ABBREV_TYPE, DAY_ABBREV_TYPE } from '../../constants/index';

const propTypes = {
  day: shape({}).isRequired,
  date: number.isRequired,
  month: number.isRequired,
  weekIndex: number.isRequired,
  dayIndex: number.isRequired,
  isAvailable: bool.isRequired,
  bookingsArray: instanceOf(Array),
  clickHandler: func,
  isBookingsCalendar: bool,
  handleOutsideAvailabilityPeriod: func,
};

const defaultProps = {
  clickHandler: null,
  isBookingsCalendar: false,
  bookingsArray: [],
  handleOutsideAvailabilityPeriod: () => {},
};

const DayItem = ({
  day,
  date,
  month,
  weekIndex,
  dayIndex,
  clickHandler,
  bookingsArray,
  isAvailable,
  isBookingsCalendar,
  handleOutsideAvailabilityPeriod,
}) => (
  <CalendarDay
    key={`day-${getDayOfYear(day)}`}
    onClick={clickHandler}
    isAvailable={isAvailable}
    isBookingsCalendar={isBookingsCalendar}
  >
    <CalendarDayFlexContainer
      isBookingsCalendar={isBookingsCalendar}
      column
      alignH="flex-start"
      alignV="flex-start"
    >
      {!isBookingsCalendar && (
        <TopLabel as="span" size="small" align="center">
          {((weekIndex === 0 && dayIndex === 0) || date === 1) && (
            <FormattedMessage id={`dates.${MONTH_ABBREV_TYPE[month]}`} />
          )}
        </TopLabel>
      )}
      {isBookingsCalendar && (
        <TopLabel size="small" align="center" isBookingView>
          <FormattedMessage id={`dates.${DAY_ABBREV_TYPE[dayIndex]}`} />
        </TopLabel>
      )}
      <StyledDay as="span" today={isToday(day)} align="center" bold>
        {date}
      </StyledDay>
      {isBookingsCalendar && (
        <BottomLabel as="span" size="smaller" align="center" color="secondary">
          <FormattedMessage id={`dates.${MONTH_ABBREV_TYPE[month]}`} />
        </BottomLabel>
      )}
      <BookingDotFlexContainer row center>
        {bookingsArray.map(booking => (
          <BookingDot
            key={booking.id}
            mt={2}
            isAvailable={isAvailable}
            isHiglighted={
              isBookingsCalendar
                ? booking.isUnconfirmed
                : handleOutsideAvailabilityPeriod(booking)
            }
          />
        ))}
      </BookingDotFlexContainer>
    </CalendarDayFlexContainer>
  </CalendarDay>
);

const CalendarDay = styled.button`
  border: 0;
  border-bottom: 1px solid ${theme.colors.greyMed};
  border-radius: 4px;

  position: relative;
  padding: 0;

  background: ${props =>
    props.isAvailable
      ? colors.background.availabilityLight
      : theme.colors.white};
`;

const TopLabel = styled(Text)`
  display: inline-block;
  width: 100%;
  height: 19px;
  margin-top: ${space.xs};
`;

const BottomLabel = styled(Text)`
  display: inline-block;
  margin-bottom: ${space.xs};
  width: 100%;
`;

const StyledDay = styled(Text)`
  display: inline-block;
  width: 100%;
  ${props =>
    props.today &&
    `
      color: ${theme.colors.attention};
  `};
`;

const CalendarDayFlexContainer = styled(FlexContainer)`
  width: 100%;
  flex-wrap: wrap;
`;

const BookingDotFlexContainer = styled(FlexContainer)`
  width: 100%;
  height: 8px;
  margin-bottom: 8px;
  display: inline-flex;
`;

const BookingDot = styled.div`
  background: ${props =>
    props.isHiglighted ? theme.colors.attention : theme.colors.primary};

  border-radius: 50%;
  border: 1px solid
    ${props =>
      props.isAvailable
        ? colors.background.availabilityLight
        : theme.colors.white};

  height: 7px;
  width: 7px;
  margin-left: -2px;

  :first-child {
    margin: 0;
  }
`;

DayItem.propTypes = propTypes;
DayItem.defaultProps = defaultProps;

export default DayItem;
