export const colors = {
  primary: '#000552',
  secondary: '#FF8B1A',
  tertiary: '#fbd43b',
  positive: '#7ED321',
  negative: '#fb4c3e',
  outline: '#c0c0c0',
  placeholder: '#fbad91',
  black: '#000',
  white: '#fff',

  sspSecondary: '#9fabb8',
  greyMed: '#dfe0e7',
  greyLight: '#f0f1f4',

  attention: '#fbad91', // peach
  alert: '#fb4c3e', // tangerine
  success: '#b6dfec', // duck egg
  rating: '#fbc800', // yellow

  text: {
    default: '#333',
    light: '',
    dark: '',
  },
  background: {
    default: '#F0F1F4',
    disabled: 'rgba(0, 0, 0, 0.03)',
    light: '#f6f6f6',
    mid: '#F5F7FA',
    dark: 'rgba(0,0,0,.3)',
    booking: '#B6DFEC',
    bookingLight: '#ebf7fa',
    availabilityLight: '#EFF8EF',
    availability: '#C7E6C7',
    unconfirmed: '#FBAD91',
    unconfirmedLight: '#FEE9E1',
  },
  border: {
    default: '#d5d4cb',
    disabled: 'rgba(0, 0, 0, 0.11)',
    light: '#e3e0dd',
    dark: '#979797',
    nav: '#383c77',
  },
  confetti: ['#fb4c3e', '#fbad91', '#cbfbdb', '#b6dfec'],
};

export const sizes = {
  headers: {
    h1: '2rem',
    h2: '1.15rem',
  },
  height: {
    header: '64px',
    tabBar: '57px',
  },
  text: {
    default: 16,
    xs: '0.7rem',
    sm: '0.9rem',
    md: '1.125rem', // 18px
    lg: '1.5rem',
    xl: '1.75rem',
  },
  width: {
    bookingCardMaxWidth: '750px',
    customerTypeIcon: '30px',
    maxContent: '798px',
  },
};

export const space = {
  default: '24px',
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '32px',
  xl: '64px',
};
