import React, { Component } from 'react';
import { shape, instanceOf, func, number } from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Text, theme } from '@freska/freska-ui';
import { format } from 'date-fns';
import { withRouter } from 'react-router-dom';
import { FormattedTime } from 'react-intl';

import { trackEvent } from '../../utils/tracking';
import { getStringTime } from '../../utils/time';
import { colors, space } from '../../theme/theme';
import { BOOKING_DETAILS_PATHNAME, CALENDAR_TIME_RANGE_TYPE } from '../../constants';

const propTypes = {
  data: shape({
    availabilities: instanceOf(Array),
    bookings: instanceOf(Array),
  }),
  weekNum: number.isRequired,
  weekDays: instanceOf(Array).isRequired,
  history: shape({ push: func }).isRequired,
};

const defaultProps = {
  data: {},
};

const timeRangeDivider = 4;

class BookingWeekGraph extends Component {
  reduceCalendarTimeRange = () => {
    const {
      data: { availabilities, bookings },
    } = this.props;
    const mergedArray = availabilities.concat(bookings);

    const earliestTime = mergedArray.reduce((currentDay, nextDay) => {
      const events = nextDay.availabilities || nextDay.bookings;
      const earliestTimeOfThisDay = events.reduce((current, next) => {
        const startTime = getStringTime(next.start_time);
        return parseInt(startTime, 10) < current ? startTime : current;
      }, 2400);
      return parseInt(earliestTimeOfThisDay, 10) < currentDay
        ? earliestTimeOfThisDay
        : currentDay;
    }, 2400);

    const latestTime = mergedArray.reduce((currentDay, nextDay) => {
      const events = nextDay.availabilities || nextDay.bookings;
      const latestTimeOfDay = events.reduce((current, next) => {
        const endTime = getStringTime(next.end_time);
        return parseInt(endTime, 10) > current ? endTime : current;
      }, 600);
      return parseInt(latestTimeOfDay, 10) > currentDay
        ? latestTimeOfDay
        : currentDay;
    }, 600);

    const startAtIndex = CALENDAR_TIME_RANGE_TYPE.indexOf(earliestTime) - 4;
    const endAtIndex = CALENDAR_TIME_RANGE_TYPE.indexOf(latestTime) + 4;
    return CALENDAR_TIME_RANGE_TYPE.slice(startAtIndex, endAtIndex);
  };

  goToDetails = id => {
    const { history } = this.props;
    trackEvent('Booking details opened from bookings view week graph', {
      category: 'Bookings',
    });

    history.push({
      pathname: BOOKING_DETAILS_PATHNAME,
      search: `?id=${id}`,
      state: { origin: true },
    });
  };

  renderAvailabilities(day, weekNum) {
    const {
      data: { availabilities },
    } = this.props;
    const availabilitiesOnTheDay = availabilities.find(
      av => av.date === format(day, 'yyyy-MM-dd')
    );
    return (
      !!availabilitiesOnTheDay &&
      availabilitiesOnTheDay.availabilities.map(availability => (
        <AvailabilityBlock
          key={availability.id}
          weekNum={weekNum}
          start={getStringTime(availability.start_time)}
          end={getStringTime(availability.end_time)}
        />
      ))
    );
  }

  renderBookings(day, weekNum) {
    const {
      data: { bookings },
    } = this.props;
    const bookingsOnTheDay = bookings.find(
      av => av.date === format(day, 'yyyy-MM-dd')
    );

    return (
      !!bookingsOnTheDay &&
      bookingsOnTheDay.bookings.map(booking => (
        <BookingBlock
          key={booking.id}
          weekNum={weekNum}
          start={getStringTime(booking.start_time)}
          end={getStringTime(booking.end_time)}
          isUnconfirmed={booking.isUnconfirmed}
          onClick={() => this.goToDetails(booking.id)}
        >
          <Text as="p" size="smaller" pl={space.xs} pt={space.xs}>
            <FormattedTime
              value={booking.start_time}
              hour="2-digit"
              minute="2-digit"
              hour12={false}
            />
            <br />
            <FormattedTime
              value={booking.end_time}
              hour="2-digit"
              minute="2-digit"
              hour12={false}
            />
          </Text>
        </BookingBlock>
      ))
    );
  }

  render() {
    const { weekNum, weekDays } = this.props;
    const timesArray = this.reduceCalendarTimeRange();

    return (
      <GraphGridContainer timesArray={timesArray}>
        {weekDays.map(day => (
          <DayCol key={uuidv4()} weekNum={weekNum} timesArray={timesArray}>
            {this.renderAvailabilities(day, weekNum)}
            {this.renderBookings(day, weekNum)}
          </DayCol>
        ))}
      </GraphGridContainer>
    );
  }
}

const buildGridLineLabels = (weekNum, timesArray) =>
  timesArray.map(time => `[line-${weekNum}-${time}] 1fr `);

const GraphGridContainer = styled.section`
  background: ${theme.colors.greyLight};
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(7, 1fr);
  min-height: ${props =>
    Math.ceil((props.timesArray.length / timeRangeDivider) * 24)}px;
  position: relative;
`;

const DayCol = styled.section`
  background: ${theme.colors.greyMed};
  display: grid;
  grid-gap: 2px;
  grid-template-rows: ${props =>
    buildGridLineLabels(props.weekNum, props.timesArray)};
  grid-template-columns: 1fr;
  position: relative;
  max-height: 408px;
`;

const AvailabilityBlock = styled.div`
  background: ${colors.background.availability};
  grid-column: 1;
  grid-row: ${props =>
    `line-${props.weekNum}-${props.start} / line-${props.weekNum}-${props.end}`};
`;

const BookingBlock = styled.div`
  border-left: 3px solid
    ${props =>
      props.isUnconfirmed
        ? colors.background.unconfirmed
        : colors.background.booking};
  background: ${props =>
    props.isUnconfirmed
      ? colors.background.unconfirmedLight
      : colors.background.bookingLight};
  grid-column: 1;
  grid-row: ${props =>
    `line-${props.weekNum}-${props.start} / line-${props.weekNum}-${props.end}`};
  margin-right: 3px;
`;

BookingWeekGraph.propTypes = propTypes;
BookingWeekGraph.defaultProps = defaultProps;

export default withRouter(BookingWeekGraph);
