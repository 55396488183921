import * as Joi from 'joi-browser';

const specificPeriodSchema = Joi.object().keys({
  startDate: Joi.date()
    .required()
    .when('endDate', {
      is: Joi.date(),
      then: Joi.date().max(Joi.ref('endDate')),
    }),
  endDate: Joi.date().min(Joi.ref('startDate')),
});

export default specificPeriodSchema;
