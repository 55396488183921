import React from 'react';
import styled from 'styled-components';
import { Card, Text } from '@freska/freska-ui';
import { space } from '../../theme/theme';
import { supportContentType } from '../../types';

const propTypes = {
  entry: supportContentType.isRequired,
};

const SupportCard = ({ entry }) => (
  <CardContainer className="card-section" key={entry.topic}>
    <Title as="p" size="large" bold>
      {entry.topic}
    </Title>
    <Text as="p" size="small">
      {entry.description}
    </Text>
  </CardContainer>
);

const Title = styled(Text)`
  margin-bottom: ${space.xs};
`;

const CardContainer = styled(Card)`
  margin-bottom: 12px;
  cursor: pointer;
`;

SupportCard.propTypes = propTypes;

export default SupportCard;
