import React from 'react';
import { shape, oneOfType, bool } from 'prop-types';
import styled from 'styled-components';
import { Button, theme, Text, Box } from '@freska/freska-ui';
import { FormattedMessage } from 'react-intl';
import { withApollo } from 'react-apollo';
import CloudOffRoundedIcon from '@material-ui/icons/CloudOffRounded';
import { configureScope } from '@sentry/react';
import { Redirect } from 'react-router-dom';

import ErrorGif from '../../assets/images/cleaning-gif.gif';
import logout from '../../utils/logout';
import { useServiceWorker } from '../../useServiceWorker';

import localStorage from '../../utils/localStorage';


const propTypes = {
  client: shape({}).isRequired,
  error: oneOfType([shape({}), bool]).isRequired,
};

const ErrorHandler = ({ client, error }) => {
  const { onlineStatus } = useServiceWorker();

  if (
    error &&
    error.graphQLErrors &&
    error.graphQLErrors.some(({ message }) => message === 'Unauthorized')
  ) {
    localStorage.remove('freska-auth');
    client.resetStore();
    configureScope(scope => scope.setUser(null));

    return <Redirect push to="/login" />
  }

  if (onlineStatus === false) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <CloudOffRoundedIcon
          style={{ color: theme.colors.attention, fontSize: '64px' }}
        />
        <Text size="large" textAlign="center" bold my={2}>
          <FormattedMessage id="error.offline_header" />
        </Text>
        <Text textAlign="center">
          <FormattedMessage id="error.offline_description" />
        </Text>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Text size="large" align="center" bold my={3}>
        <FormattedMessage id="error.whoops" />
      </Text>
      <ImageWrapper />
      <Text textAlign="center" bold>
        <FormattedMessage id="error.something_went_wrong" />
      </Text>
      <Text textAlign="center">
        <FormattedMessage id="error.contact_support" />
      </Text>
      <Button
        variant="secondary"
        mt={3}
        onClick={() => {
          logout({ history, client });
        }}
      >
        <FormattedMessage id="error.start_over" />
      </Button>
    </Box>
  );
};

const ImageWrapper = styled.div`
  background: url(${ErrorGif}) no-repeat ${theme.colors.greyLight};
  background-blend-mode: darken;
  background-size: 100%;
  height: 0;
  max-width: 500px;
  padding-top: 50%;
  width: 100%;
  @media screen and (min-width: 520px) {
    height: 250px;
    padding-top: 0;
  }
`;

ErrorHandler.propTypes = propTypes;

export default withApollo(ErrorHandler);
