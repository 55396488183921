import React from 'react';
import { Button, typography } from '@freska/freska-ui';
import styled from 'styled-components';
import { node } from 'prop-types';

const propTypes = {
  children: node.isRequired,
};

const ButtonLink = ({ children, ...rest }) => {
  return (
    <StyledButton variant="secondary" {...rest}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  ${typography.link};

  background: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  margin: 0;
  padding: 0;

  :hover,
  :focus,
  :visited,
  :active {
    background: transparent;
    border: 0;
    box-shadow: none;
  }
`;
ButtonLink.propTypes = propTypes;
export default ButtonLink;
