/* eslint-disable no-console */
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { LANGUAGES_ENUM } from '../constants';

import LocalStorage from '../utils/localStorage';
import Endpoints from './Endpoints';
import Mutations from './Mutations';

const data = {
  language: LANGUAGES_ENUM.EN,
  user: null,
};

const defaultOptions = {
  watchQuery: {
    errorPolicy: 'all',
  },
  query: {
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

export default function getClient(headers) {
  const cache = new InMemoryCache({});

  const requestLink = new ApolloLink((operation, forward) => {
    const { token, ...restHeaders } =
      headers || LocalStorage.get('freska-auth') || {};
    operation.setContext({
      headers: {
        'access-token': token,
        ...restHeaders,
      },
    });
    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError, ...rest }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }

    if (!graphQLErrors && !networkError)
      console.error(`[Other ApolloClient error]: ${rest}`);
  });

  const httpLink = new HttpLink({
    uri: Endpoints[process.env.REACT_APP_BUILD_ENV],
  });

  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, requestLink, httpLink]),
    cache,
    resolvers: {
      Mutation: Mutations,
    },
    defaultOptions,
  });

  cache.writeData({ data });
  client.onResetStore(() => cache.writeData({ data }));

  return client;
}
