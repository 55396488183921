import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { sizes, space } from '../../theme/theme';
import FlexContainer from './FlexContainer';
import SuppliesIMG from '../../assets/images/supplies.png';

const propTypes = {
  messageId: PropTypes.string.isRequired,
};

const NoContent = ({ messageId }) => (
  <FlexContainer center column p={3}>
    <ImageWrapper>
      <Image />
    </ImageWrapper>
    <NoContentText>
      <FormattedMessage id={messageId} />
    </NoContentText>
  </FlexContainer>
);

const NoContentText = styled.p`
  font-size: ${sizes.text.md};
`;

const ImageWrapper = styled.div`
  max-width: 500px;
`;

const Image = styled.img.attrs(() => ({
  src: SuppliesIMG,
}))`
  margin-bottom: ${space.default};
  width: 100%;
`;

NoContent.propTypes = propTypes;

export default NoContent;
