import * as Joi from 'joi-browser';

const weeklySchema = Joi.object().keys({
  only_one_offs: Joi.boolean().required(),
  schedule: Joi.array()
    .items(
      Joi.object()
        .keys({
          start: Joi.number().max(Joi.ref('end')),
          end: Joi.number().min(Joi.ref('start')),
          startTime: Joi.string().regex(/^([0-9]{2}):([0-9]{2})$/),
          endTime: Joi.string().regex(/^([0-9]{2}):([0-9]{2})$/),
        })
        .optional()
        .allow(null)
    )
    .required(),
  service_worker_id: Joi.number().required(),
  valid_from: Joi.date()
    .required()
    .when('valid_to', {
      is: Joi.date(),
      then: Joi.date().less(Joi.ref('valid_to')),
    }),
  valid_to: Joi.date()
    .greater(Joi.ref('valid_from'))
    .optional()
    .allow(null),
});

export default weeklySchema;
