import React from 'react';
import PropTypes from 'prop-types';
import { IconComment, Text } from '@freska/freska-ui';

import DataBlock from '../Common/DataBlock';

const propTypes = {
  comment: PropTypes.string,
};

const defaultProps = {
  comment: '',
};

function BookingDetailsCustomerFeedback({ comment }) {
  return (
    <DataBlock
      Icon={IconComment}
      title="bookings.details.sw_comment_to_freska"
      hasContent={!!comment}
    >
      <Text m={0} as="p" className="swFeedbackToFreska">
        {comment}
      </Text>
    </DataBlock>
  );
}

BookingDetailsCustomerFeedback.propTypes = propTypes;
BookingDetailsCustomerFeedback.defaultProps = defaultProps;

export default BookingDetailsCustomerFeedback;
