import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from '@freska/freska-ui';
import withLanguage from '../../utils/withLanguage';

import DataBlock from '../Common/DataBlock';
import { space } from '../../theme/theme';

const propTypes = {
  Icon: PropTypes.func.isRequired,
  totalHours: PropTypes.node,
  totalAmount: PropTypes.node,
};

const defaultProps = {
  totalHours: null,
  totalAmount: null,
};

const TotalPerServiceType = ({ Icon, totalHours, totalAmount }) =>
  totalHours ? (
    <StyledDataBlock Icon={Icon} hasContent>
      <Grid>
        <Text as="p">{totalHours}</Text>
        <Text align="right" bold as="p">
          {totalAmount}
        </Text>
      </Grid>
    </StyledDataBlock>
  ) : null;

const Grid = styled.div`
  grid-gap: ${space.md};
  align-items: start;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const StyledDataBlock = styled(DataBlock)`
  margin-bottom: ${space.md};
`;

TotalPerServiceType.propTypes = propTypes;
TotalPerServiceType.defaultProps = defaultProps;

export default withLanguage(TotalPerServiceType);
