import React from 'react';
import {
  Box,
  Card,
  IconWarning,
  Text,
  theme,
} from '@freska/freska-ui';
import styled from 'styled-components';
import { rgba } from 'polished';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { string, oneOf, element } from 'prop-types';

const propTypes = {
  message: string,
  title: string,
  severity: oneOf(['success', 'info', 'warning']),
  action: element,
};

const defaultProps = {
  title: '',
  severity: 'info',
};

const severityColor = () => ({
  info: rgba(theme.colors.completed, 0.3),
  warning: rgba(theme.colors.attention, 0.3),
  success: rgba(theme.colors.success, 0.3),
});

const severityIcon = () => ({
  info: (
    <ErrorOutlineIcon
      style={{
        fontSize: 20,
        color: theme.colors.completed,
        marginRight: theme.space[1],
      }}
      />
    ),
  warning: <IconWarning color="attention" size={20} mr={1} />,
  success: (
    <CheckCircleOutlineIcon
      style={{
        fontSize: 20,
        color: theme.colors.success,
        marginRight: theme.space[1],
      }}
    />
  ),
});

function Alert({ message, severity = "info", title, action, ...rest }) {
  return (
    <StyledCard severity={severity} p={2} {...rest}>
      <Box display="flex" alignItems="flex-start" justifyContent="space-between">
        <Box display="flex" alignItems="flex-start">
          {severityIcon()[severity]}
          <Box>
            {title && (
              <Text size="small" bold>
                {title}
              </Text>
            )}
            {message && (
              <Text size="small">{message}</Text>
            )}
          </Box>
        </Box>
        {action && action}
      </Box>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  background: ${(props) => severityColor()[props.severity]};
  box-shadow: none;
  text-align: left;
`;

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

export default Alert;