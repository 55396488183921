import React from 'react';
import { number, string } from 'prop-types';
import styled from 'styled-components';
import { Text, Label, Tag } from '@freska/freska-ui';
import { useIntl, FormattedMessage } from 'react-intl';
import { format, parseISO } from 'date-fns';

import { accessType } from '../../types';
import { getRawAccessStatusIcon } from '../../utils/bookingsHelper';
import { space } from '../../theme/theme';

import DataBlock from '../Common/DataBlock';

const propTypes = {
  access: accessType.isRequired,
  accessIconStatus: string.isRequired,
  serviceWorkerId: number.isRequired,
};

function renderKeyInfoItem(labelId, content, gridColumn = 'auto') {
  return (
    <div style={{ gridColumn }}>
      <Text size="small" bold as="p" mb={space.xs}>
        <FormattedMessage id={`keys.labels.${labelId}`} />
      </Text>
      <Text size="small" as="p">
        {content}
      </Text>
    </div>
  );
}

function getSafekeeper(key, serviceWorkerId) {
  if (!key) return null;
  let safekeeper = key.status;
  if (safekeeper === 'at_cleaner') {
    if (key.service_worker_id === serviceWorkerId) {
      safekeeper = 'at_you';
    } else {
      safekeeper = 'at_another_cleaner';
    }
  }
  return safekeeper;
}

function renderKeyInfo(intl, key, serviceWorkerId) {
  const safekeeper = getSafekeeper(key, serviceWorkerId);
  return (
    <Grid>
      {(!!key.return_date || key.status === 'at_office') &&
        safekeeper !== 'at_another_cleaner' && (
          <div style={{ gridColumn: '1/3' }}>
            <Tag color="attention" mb={2}>
              <FormattedMessage
                id={`keys.tags.${key.return_date ? 'return' : 'pick_up'}`}
              />
            </Tag>
          </div>
        )}
      {!!key.return_date &&
        renderKeyInfoItem(
          'return_date',
          format(parseISO(key.return_date), 'yyyy-MM-dd'),
          '1/3'
        )}
      {renderKeyInfoItem('label', key.label)}
      {renderKeyInfoItem(
        'safekeeper',
        intl.formatMessage({
          id: `keys.status.${safekeeper}`,
        })
      )}
      {!!key.notes && renderKeyInfoItem('key_notes', key.notes, '1/3')}
    </Grid>
  );
}

function renderAccessInfoItem(labelId, content) {
  return (
    <AccessInfoItem>
      <Label mb={1}>
        <FormattedMessage id={`access.labels.${labelId}`} />
      </Label>
      <Text as="span">{content}</Text>
    </AccessInfoItem>
  );
}

const BookingDetailsAccess = ({
  access,
  accessIconStatus,
  serviceWorkerId,
}) => {
  const intl = useIntl();
  return (
    <StyledDataBlock
      Icon={getRawAccessStatusIcon(
        accessIconStatus,
        getSafekeeper(access.key, serviceWorkerId)
      )}
      withAttention={accessIconStatus === 'KeyAttention'}
      title="bookings.card.access"
      hasContent
    >
      <Text as="p" mb={1}>
        <FormattedMessage id={`access.status.${access.status}`} />
      </Text>
      {!!access.key && renderKeyInfo(intl, access.key, serviceWorkerId)}
      {!!access.door_code &&
        renderAccessInfoItem('door_code', access.door_code)}
      {!!access.notes && renderAccessInfoItem('access_notes', access.notes)}
    </StyledDataBlock>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: ${space.sm};
  margin-bottom: ${space.md};
`;

const AccessInfoItem = styled.div`
  margin-bottom: ${space.md};
`;

const StyledDataBlock = styled(DataBlock)`
  div:last-child {
    margin-bottom: 0;
  }
`;

BookingDetailsAccess.propTypes = propTypes;

export default BookingDetailsAccess;
