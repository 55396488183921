import React from 'react';
import {
  Box,
  Button,
  Text,
} from '@freska/freska-ui';

export const FreskaProBanner = () => {
  return (
    <Box
      position="fixed"
      top={0}
      right={0}
      zIndex={1000}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height="60px"
      width="100%"
      px={2}
      backgroundColor="primary"
    >
      <Text size="small" color="white" py={1} pr="4px">
        The Freska Pro app is now available and will replace the Superheroes.
      </Text>
      <Button size="small" variant="secondary" href="https://freskaworker.page.link/banner">Install</Button>
    </Box>
  );
}
