import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { IconAddCircle, Text } from '@freska/freska-ui';
import styled from 'styled-components';

import { useIntl } from 'react-intl';
import DataBlock from '../Common/DataBlock';
import { formatDuration } from '../../utils/formatNumber';

const propTypes = {
  additionalServices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function styleName(name, totalAmount, intl) {
  const cleanName = name.replace('_', ' ');

  return (
    <Fragment key={name}>
      <AdditionalServiceText as="span">
        {`${cleanName[0].toUpperCase()}${cleanName.slice(1)} (`}
        {formatDuration(totalAmount, intl)}
      </AdditionalServiceText>
    </Fragment>
  );
}

const BookingDetailsServices = ({ additionalServices }) => {
  const intl = useIntl();
  return (
    <DataBlock
      Icon={IconAddCircle}
      title="bookings.card.additional_services"
      hasContent={!!additionalServices.length}
    >
      <Text m={0} as="p">
        {additionalServices.map(s => styleName(s.name, s.total_amount, intl))}
      </Text>
    </DataBlock>
  );
};

const AdditionalServiceText = styled(Text)`
  :after {
    content: '), ';
  }
  :last-child {
    :after {
      content: ') ';
    }
  }
`;

BookingDetailsServices.propTypes = propTypes;

export default BookingDetailsServices;
