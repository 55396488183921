import React from 'react';
import {
  Box,
  theme,
  Text,
  Link,
  IconHealing,
  IconBeachAccess,
  IconOtherAbsence,
} from '@freska/freska-ui';
import { parseISO } from 'date-fns';
import { func, string, shape } from 'prop-types';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { lighten } from 'polished';
import styled, { css } from 'styled-components';

const propTypes = {
  type: string.isRequired,
  absence: shape({
    id: string,
    reason: shape({ key: string }),
    end_time: string,
    start_time: string,
  }).isRequired,
  setSelectedSickLeave: func,
  setToggleReportFitDialog: func,
};

const defaultProps = {
  setSelectedSickLeave: null,
  setToggleReportFitDialog: null,
};

function mapAbsenceTypeToIcon(absenceType, color) {
  switch (absenceType) {
    case 'sick_leave':
      return <IconHealing color={color} />;
    case 'vacation':
      return <IconBeachAccess color={color} />;
    default:
      return <IconOtherAbsence color={color} />;
  }
}

function AbsenceBox({
  type,
  absence,
  setSelectedSickLeave,
  setToggleReportFitDialog,
}) {
  return (
    <StyledBox type={type}>
      <Box display="flex" alignItems="flex-start">
        <Box flexShrink="0" mr={1}>
          {mapAbsenceTypeToIcon(
            absence.reason.key,
            type === 'sickLeaveWithoutEndTime' ? 'white' : 'completed'
          )}
        </Box>
        <Box>
          <Text
            size="large"
            bold
            color={type === 'sickLeaveWithoutEndTime' ? 'white' : 'black'}
          >
            <FormattedMessage id={`absence.absence_types.${absence.reason.key}`} />
          </Text>
          {type === 'absenceWithEndTime' && (
            <Text>
              <FormattedMessage id="absence.last_day_of_absence" />
              <FormattedDate
                value={parseISO(absence.end_time)}
                month="long"
                day="numeric"
                weekday="long"
              />
            </Text>
          )}
        </Box>
      </Box>
      {type === 'sickLeaveWithoutEndTime' && (
        <Link
          as="button"
          color="white"
          underline
          onClick={() => {
            setSelectedSickLeave(absence);
            setToggleReportFitDialog(true);
          }}
        >
          <Text as="span" size="large" color="white">
            <FormattedMessage id="absence.report_fit_link_caption" />
          </Text>
        </Link>
      )}
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding: ${theme.space[2]}px;
  border-radius: ${theme.borderRadius}px;
  margin-bottom: ${theme.space[1]}px;
  &:last-child {
    margin-bottom: ${theme.space[3]}px;
  }
  ${props =>
    props.type === 'sickLeaveWithoutEndTime'
      ? css`
          justify-content: space-between;
          background-color: ${theme.colors.alert};
        `
      : css`
          justify-content: flex-start;
          background-color: ${lighten(0.9, theme.colors.completed)};
          border: 1px solid ${theme.colors.completed};
        `}
`;

AbsenceBox.propTypes = propTypes;
AbsenceBox.defaultProps = defaultProps;

export default AbsenceBox;
