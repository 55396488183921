export const formatNumber = (num, locale) =>
  new Intl.NumberFormat(`${locale}`, {
    minimumFractionDigits: 2,
  }).format(Math.round(num * 100) / 100);

export const formatAndRoundDownNumber = (num, locale) =>
  new Intl.NumberFormat(`${locale}`, {
    minimumFractionDigits: 2,
  }).format(Math.floor(num * 100) / 100);

export const formatRating = (num, locale) =>
  num
    ? new Intl.NumberFormat(`${locale}`, {
        minimumFractionDigits: 2,
      }).format(Math.round(num * 100) / 100)
    : '-';

export const formatPrice = (num, locale, currency) =>
  num
    ? new Intl.NumberFormat(`${locale}`, {
        minimumFractionDigits: 2,
        style: 'currency',
        currency,
        currencyDisplay: 'code',
      }).format(Math.round(num * 100) / 100)
    : '-';

export const formatUnitPrice = (num, locale, currency) =>
  num
    ? `${new Intl.NumberFormat(`${locale}`, {
        minimumFractionDigits: 2,
      }).format(Math.round(num * 100) / 100)}\xa0${currency}/h`
    : '-';

export const formatDuration = (
  durationInt,
  intl,
  length = 'short',
  withSpace = false
) => {
  const durationHours =
    durationInt > 0 ? Math.floor(durationInt) : Math.ceil(durationInt);

  const durationMinutes =
    Math.round((durationInt * 60) % 60) * (durationHours < 0 ? -1 : 1);

  const durationHoursString = durationHours
    ? intl.formatMessage(
        { id: `time.duration_hours.${length}` },
        { hours: durationHours }
      )
    : '';

  const durationMinutesString = durationMinutes
    ? intl.formatMessage(
        {
          id: `time.duration_minutes.${length}`,
        },
        { minutes: durationMinutes }
      )
    : '';

  return `${durationHoursString}${
    withSpace && durationHours && durationMinutes ? ' ' : ''
  }${durationMinutesString}`;
};
