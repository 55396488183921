import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { makeStyles, styled } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { theme } from '@freska/freska-ui';
import { lighten } from 'polished';

import { TrackedRoute, ResetPasswordRoute } from '../../utils/routes';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import LoginContainer from '../LoginContainer/LoginContainer';
import ResetPasswordContainer from '../ResetPasswordContainer/ResetPasswordContainer';
import SiteGrid from '../../components/Common/SiteGrid';
import SvgIcon from '@material-ui/core/SvgIcon';

// TO DO: Replace with global theme once we introduce Freska UI v3 based on Material UI
const defaultFontStyle = {
  font: "400 0.875rem/1.4 'Nunito Sans', sans-serif",
  letterSpacing: '-0.2px',
  color: theme.colors.black,
};

// Override Material UI default theme styles for Notistack classes
const useSnackbarStyles = makeStyles({
  success: {
    backgroundColor: lighten(0.25, theme.colors.success),
    ...defaultFontStyle,
  },
  info: { backgroundColor: theme.colors.completed, ...defaultFontStyle },
  error: {
    backgroundColor: lighten(0.3, theme.colors.alert),
    ...defaultFontStyle,
  },
  warning: {
    backgroundColor: lighten(0.15, theme.colors.attention),
    ...defaultFontStyle,
  },
});

function HappyFaceIcon(props) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <circle fill="#76D2A0" cx="12" cy="12" r="12" />
        <path
          d="M16.5 14.998c0 .975-.775 4.002-4.5 4.002s-4.5-3.027-4.5-4.002c0-.974 1.522-.498 4.5-.498 2.978 0 4.5-.476 4.5.498zM5.5 10a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm13 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
          fill="#000"
        />
      </g>
    </SvgIcon>
  );
}

function SadFaceIcon(props) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <circle fill="#FBAD91" cx="12" cy="12" r="12" />
        <path
          d="M12 15c2.005 0 3 1.839 3 2.43 0 .593-1.015.134-3 .134s-3 .459-3-.133C9 16.839 9.995 15 12 15zm-6.5-3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm13 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
          fill="#000"
        />
      </g>
    </SvgIcon>
  );
}

const SiteContainer = () => {
  const classes = useSnackbarStyles();
  return (
    <BrowserRouter>
      <ScrollToTop>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          preventDuplicate={true}
          iconVariant={{
            success: <StyledHappyFaceIcon />,
            error: <StyledSadFaceIcon />,
            warning: <StyledSadFaceIcon />,
            info: <StyledInfoOutlinedIcon />,
          }}
          classes={{
            variantSuccess: classes.success,
            variantInfo: classes.info,
            variantError: classes.error,
            variantWarning: classes.warning,
          }}
        >
          <Switch>
            <TrackedRoute
              exact
              path="/login"
              render={props => <LoginContainer {...props} />}
            />
            <ResetPasswordRoute
              exact
              path="/reset-password"
              component={ResetPasswordContainer}
            />
            <SiteGrid />
          </Switch>
        </SnackbarProvider>
      </ScrollToTop>
    </BrowserRouter>
  );
};

const StyledSadFaceIcon = styled(SadFaceIcon)({
  marginRight: `${theme.space[1]}px`,
});

const StyledHappyFaceIcon = styled(HappyFaceIcon)({
  marginRight: `${theme.space[1]}px`,
});

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)({
  color: theme.colors.black,
  marginRight: `${theme.space[1]}px`,
});

export default SiteContainer;
