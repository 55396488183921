import gql from 'graphql-tag';

export const UPDATE_LANGUAGE = gql`
  mutation updateLanguage($language: String!) {
    updateLanguage(language: $language) @client
  }
`;

export const LOGIN = gql`
  mutation LogIn($email: String!, $password: String!) {
    logIn(email: $email, password: $password) {
      authData {
        token
        client
        uid
      }
      userData {
        id
        email
        name
        roles
      }
      serviceWorkerData {
        uuid
        id
        email
        firstname
        lastname
        phonenumber
        locale
        average_rating
        average_rating_last_three_months
        average_rating_last_six_months
        past_bookings_count
        past_bookings_hours
        employed
        home_address {
          id
          street_address
          postcode
          city
          countrycode
          coordinates {
            lat
            lng
          }
        }
        region {
          id
          key
          name
          countrycode
          currency
          timezone
        }
        workingAreas {
          id
          created_at
          updated_at
          service_worker_id
          area {
            type
            coordinates {
              lat
              lng
            }
          }
        }
        average_rating_details {
          workmanship
          behavior
          punctuality
          overall
        }
      }
    }
  }
`;

export const CONFIRM_BOOKING = gql`
  mutation confirmBooking($bookingId: Int!, $comment: String) {
    confirmBooking(bookingId: $bookingId, comment: $comment) {
      booking_id
      id
      comments
      internal_comments
    }
  }
`;

export const CREATE_AVAILABILITY_GROUP = gql`
  mutation CreateAvailabilityGroup($group: AvailabilityGroupInput!) {
    createAvailabilityGroup(group: $group) {
      id
      service_worker_id
      valid_from
      valid_to
      only_one_offs
      schedule {
        start
        end
      }
    }
  }
`;

export const EDIT_AVAILABILITY_GROUP = gql`
  mutation EditAvailabilityGroup($id: Int!, $group: AvailabilityGroupInput!) {
    editAvailabilityGroup(id: $id, group: $group) {
      id
      service_worker_id
      valid_from
      valid_to
      only_one_offs
      schedule {
        start
        end
      }
    }
  }
`;

export const DELETE_AVAILABILITY_GROUP = gql`
  mutation DeleteAvailabilityGroup($id: Int!) {
    deleteAvailabilityGroup(id: $id)
  }
`;

export const CREATE_AVAILABILITY = gql`
  mutation createAvailability($availability: AvailabilityInput!) {
    createAvailability(availability: $availability) {
      id
      end_time
      frequency
      service_worker_id
      start_time
      valid_to
      valid_from
    }
  }
`;

export const EDIT_AVAILABILITY = gql`
  mutation editAvailability($id: Int!, $availability: AvailabilityInput!) {
    editAvailability(id: $id, availability: $availability) {
      id
      availability_group_id
      end_time
      frequency
      service_worker_id
      start_time
      valid_to
      valid_from
    }
  }
`;

export const DELETE_AVAILABILITY = gql`
  mutation deleteAvailability($id: Int!) {
    deleteAvailability(id: $id)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($password: String!, $passwordConfirmation: String!) {
    changePassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      success
      message
    }
  }
`;

export const SEND_RESET_PASSWORD_EMAIL = gql`
  mutation resetPassword($email: String!, $redirectUrl: String!) {
    resetPassword(email: $email, redirectUrl: $redirectUrl) {
      success
      message
    }
  }
`;

export const CREATE_INVOICE = gql`
  mutation createInvoice($startDate: String!, $endDate: String!) {
    createInvoice(startDate: $startDate, endDate: $endDate) {
      id
      countrycode
      currency
      date
      invoiced
      status
      our_reference
      paid
      payment_term
      region_id
      total_amount
      total_amount_vat_excluded
      total_vat_amount
      service_worker {
        id
        firstname
        lastname
      }
      data {
        bookings_to
        bookings_from
        service_worker_id
      }
      lines {
        booking {
          id
          start_time
          end_time
          duration
          invoiceable_duration
        }
        description
        id
        quantity
        total_amount
        total_amount_vat_excluded
        unit_price
      }
      purchase_invoices {
        invoice_number
        paid_on
      }
    }
  }
`;

export const UPDATE_ABSENCE_BY_ID = gql`
  mutation updateAbsenceById($absenceId: Int!, $absence: AbsenceInput!) {
    updateAbsenceById(absenceId: $absenceId, absence: $absence) {
      id
      service_worker_id
      start_time
      end_time
      reason {
        id
        key
      }
    }
  }
`;

export const UPDATE_WORKER_NOTE = gql`
  mutation editWorkerNote(
    $id: String!
    $content: String!
    $creatorId: String!
    $bookingId: Int!
  ) {
    editWorkerNote(
      id: $id
      content: $content
      creatorId: $creatorId
      bookingId: $bookingId
    ) {
      id
      customer_id
      service_name
      content
      created_at
      creator_id
      updated_at
      updater_id
    }
  }
`;

export const DELETE_WORKER_NOTE = gql`
  mutation deleteWorkerNote(
    $id: String!
    $creatorId: String!
    $bookingId: Int!
  ) {
    deleteWorkerNote(id: $id, creatorId: $creatorId, bookingId: $bookingId)
  }
`;

export const ADD_WORKER_NOTE = gql`
  mutation createWorkerNote($bookingId: Int!, $content: String!) {
    createWorkerNote(bookingId: $bookingId, content: $content) {
      id
      customer_id
      service_name
      content
      created_at
      creator_id
      updated_at
      updater_id
      canUpdate
      canDelete
    }
  }
`;
