import React, { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { Button, Input } from '@freska/freska-ui';
import { setUser } from '@sentry/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { withApollo, useMutation } from 'react-apollo';
import { withRouter } from 'react-router';

import NotificationBlock from '../Common/NotificationBlock';
import FlexContainer from '../Common/FlexContainer';
import Form from '../Common/Form';
import LocalStorage from '../../utils/localStorage';
import { LOGIN } from '../../gql/mutations';
import validateData, { checkForError } from '../../utils/validate';
import schema from './schemas/loginSchema';
import { trackEvent, identify } from '../../utils/tracking';

const propTypes = {
  client: shape({}).isRequired,
  toggleForgotPassword: func.isRequired,
  history: shape({ push: func }).isRequired,
  origin: string.isRequired,
};

function LoginForm({ toggleForgotPassword, history, origin, client }) {
  const intl = useIntl();
  const [errors, setErrors] = useState({});
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [login, { loading, error }] = useMutation(LOGIN, {
    onCompleted(data) {
      if (data) {
        const { authData, userData, serviceWorkerData } = data.logIn;
        setUser({ email: userData.email });

        const canEditAvailability =
          serviceWorkerData &&
          serviceWorkerData.home_address.countrycode === 'FI' &&
          !serviceWorkerData.employed;

        LocalStorage.set('freska-auth', authData);
        LocalStorage.set('user-email', userData.email);
        LocalStorage.set('is-employed', !canEditAvailability);
        LocalStorage.set('countrycode', serviceWorkerData?.region.countrycode || null);


        Promise.resolve(client.resetStore()).then(() => {
          history.push(origin);
        });

        trackEvent('Logged in', {
          category: 'Account',
        });
        identify(userData);
      }
    },
  });

  const getErrorMessage = graphQLErrors => {
    if (graphQLErrors?.some(({ message }) => message === 'Unauthorized')) {
      return 'incorrect_credentials';
    } else {
      return 'default';
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    validateData(loginData, schema).then(res => setErrors(res));
    login({ variables: loginData });
  }

  return (
    <Form name="login-form" onSubmit={e => handleSubmit(e)} loading={loading}>
      {error && (
        <NotificationBlock hasError>
          <FormattedMessage
            id={`error.${getErrorMessage(error.graphQLErrors)}`}
          />
        </NotificationBlock>
      )}
      <Input
        id="login-email"
        name="login-email"
        type="email"
        label={intl.formatMessage({ id: 'login.email' })}
        placeholder={intl.formatMessage({ id: 'login.email' })}
        onChange={e => setLoginData({ ...loginData, email: e.target.value })}
        value={loginData.email}
        disabled={loading}
        error={checkForError(errors, 'email')}
        mb={2}
        hasBorder
      />
      <Input
        id="login-password"
        name="login-password"
        type="password"
        label={intl.formatMessage({ id: 'login.password' })}
        placeholder={intl.formatMessage({ id: 'login.password' })}
        onChange={e => setLoginData({ ...loginData, password: e.target.value })}
        value={loginData.password}
        disabled={loading}
        error={checkForError(errors, 'password')}
        hasBorder
        mb={2}
      />
      <FlexContainer alignH="space-between" alignV="flex-start" row>
        <Button
          loading={loading}
          variant="primary"
          type="submit"
          id="login-btn"
        >
          <FormattedMessage id="login.login" />
        </Button>
        <Button variant="secondary" onClick={toggleForgotPassword}>
          <FormattedMessage id="login.forgot_password" />
        </Button>
      </FlexContainer>
    </Form>
  );
}

LoginForm.propTypes = propTypes;

export default withApollo(withRouter(LoginForm));
