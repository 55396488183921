import React from 'react';
import { shape } from 'prop-types';
import { injectIntl } from 'react-intl';
import { startOfDay } from 'date-fns';
import { IconDateRange, IconNote } from '@freska/freska-ui';
import { useHistory, useParams } from 'react-router';

import LocalStorage from '../../utils/localStorage';
import BookingsView from '../../components/Bookings/BookingsView';
import ViewGrid from '../../components/Common/ViewGrid';
import BookingCalendarView from '../../components/BookingsCalendar/BookingCalendarView';
import AbsenceNotifier from '../../components/Absences/AbsenceNotifier';
import { BOOKINGS_VIEW_TYPES_ENUM, BOOKINGS_VIEW_TYPE_KEY } from '../../constants';
import { useSearchParams } from '../../hooks/useSearchParams';

const propTypes = {
  match: shape({ params: shape({}) }).isRequired,
};

const BookingsContainer = () => {
  const today = new Date();
  const history = useHistory();
  const { type } = useParams();
  const startDate = useSearchParams()?.date;

  const weekStartDate = startDate ? new Date(startDate) : today;
  const isBookingsListView = type === BOOKINGS_VIEW_TYPES_ENUM.LIST;

  const headerActions = [
    {
      handler: () => {
        const newType = isBookingsListView ? BOOKINGS_VIEW_TYPES_ENUM.CALENDAR : BOOKINGS_VIEW_TYPES_ENUM.LIST;
        LocalStorage.set(BOOKINGS_VIEW_TYPE_KEY, newType);
        history.replace(`/bookings/${newType}${window.location.search}`);
      },
      component: isBookingsListView ? IconDateRange : IconNote,
      iconTooltip: isBookingsListView
        ? 'bookings.actions.show_calendar_view'
        : 'bookings.actions.show_list_view',
    },
  ];

  return (
    <ViewGrid headerActions={headerActions}>
      <AbsenceNotifier />
      {type === BOOKINGS_VIEW_TYPES_ENUM.CALENDAR ? (
        <BookingCalendarView weekStartDate={startOfDay(weekStartDate)} />    
      ) : (
        <BookingsView weekStartDate={startOfDay(weekStartDate)} />
      )}
    </ViewGrid>
  );
};

BookingsContainer.propTypes = propTypes;

export default injectIntl(BookingsContainer);
