import React from 'react';
import { shape, bool, object } from 'prop-types';
import { graphql } from 'react-apollo';

import { Box, Spinner } from '@freska/freska-ui';
import { GET_WORKING_AREAS_VIEW_DATA } from '../../gql/queries';
import WorkingAreasMap from '../../components/WorkingAreasMap/WorkingAreasMap';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import { workingAreaViewType } from '../../types';
import ViewGrid from '../../components/Common/ViewGrid';

const propTypes = {
  data: shape({
    getWorkingAreaViewData: workingAreaViewType,
    loading: bool,
    error: object,
  }).isRequired,
};

const WorkingAreasContainer = ({
  data: { getWorkingAreaViewData, loading, error },
}) => {
  if (error) {
    return <ErrorHandler error={error} />;
  }
  if (loading) {
    return (
      <ViewGrid>
        <Box p={3}>
          <Spinner />
        </Box>
      </ViewGrid>
    );
  }
  if (!getWorkingAreaViewData) {
    return <div />;
  }
  return (
    <ViewGrid>
      <WorkingAreasMap
        workingAreas={getWorkingAreaViewData.workingAreas}
        homeAddress={getWorkingAreaViewData.homeAddress}
        bookings={getWorkingAreaViewData.bookings}
      />
    </ViewGrid>
  );
};

WorkingAreasContainer.propTypes = propTypes;

export default graphql(GET_WORKING_AREAS_VIEW_DATA)(WorkingAreasContainer);
