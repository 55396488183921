import React from 'react';
import { Query } from 'react-apollo';
import { Box, Spinner } from '@freska/freska-ui';

import { GET_WORKER } from '../gql/queries';
import ErrorHandler from '../components/ErrorHandler/ErrorHandler';

function withUser(Wrapped) {
  return function WrappedComponent(props) {
    return (
      <Query query={GET_WORKER}>
        {({ loading, data, error }) => {
          if (loading)
            return (
              <Box p={3}>
                <Spinner />
              </Box>
            );
          if (
            error &&
            error.graphQLErrors.some(
              ({ message }) => message === 'Request failed with status code 404'
            )
          ) {
            return <Wrapped user={{}} {...props} />;
          }
          if (error) {
            return <ErrorHandler error={error} />;
          }
          return <Wrapped user={data.getServiceWorkerData || {}} {...props} />;
        }}
      </Query>
    );
  };
}

export default withUser;
