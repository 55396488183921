import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { theme } from '@freska/freska-ui';
import { space } from '../../theme/theme';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const StickyTop = ({ children }) => <StickySection>{children}</StickySection>;

const StickySection = styled.section`
  background: ${theme.colors.white};
  padding: ${space.default} ${space.default} ${space.sm};
  position: sticky;
  margin-bottom: ${space.md};
  top: 0;
  z-index: 2;
`;

StickyTop.propTypes = propTypes;

export default StickyTop;
