export function trackEvent(eventName, properties) {
  if (window.analytics) {
    window.analytics.track(eventName, properties);
  }
}

export function pageView() {
  if (window.analytics) {
    window.analytics.page();
  }
}

export function identify(cleaner) {
  window.analytics.identify(cleaner.id, {
    email: cleaner.email,
    name: cleaner.name,
  });
}
