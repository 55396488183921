import React from 'react';
import PropTypes from 'prop-types';
import { IconLocation, Text } from '@freska/freska-ui';
import DataBlock from '../Common/DataBlock';

const propTypes = {
  addressNote: PropTypes.string,
};

const defaultProps = {
  addressNote: '',
};

const BookingDetailsAddress = ({ addressNote }) => (
  <DataBlock
    Icon={IconLocation}
    title="bookings.card.address_notes"
    hasContent={!!addressNote}
  >
    <Text as="p" m={0}>
      {addressNote}
    </Text>
  </DataBlock>
);

BookingDetailsAddress.propTypes = propTypes;
BookingDetailsAddress.defaultProps = defaultProps;

export default BookingDetailsAddress;
