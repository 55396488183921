import React from 'react';
import { Text, Label } from '@freska/freska-ui';
import { FormattedMessage } from 'react-intl';

function InvoiceReceiptDetail(titleId, content) {
  return (
    <div>
      <Label>
        <FormattedMessage id={titleId} />
      </Label>
      <Text>{content}</Text>
    </div>
  );
}

export default InvoiceReceiptDetail;
