import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import styled from 'styled-components';
import groupBy from 'lodash.groupby';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import { Box, Spinner, theme } from '@freska/freska-ui';

import { GET_SUPPORT_MATERIAL } from '../../gql/queries';
import ViewGrid from '../../components/Common/ViewGrid';
import withUser from '../../utils/withUser';
import SupportCard from '../../components/Contentful/SupportCard';
import ContentfulEntry from '../../components/Contentful/ContentfulEntry';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';

const propTypes = {
  match: shape({ params: shape({ slug: string }) }),
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function Support({ match: { params } }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const { loading, data, error } = useQuery(GET_SUPPORT_MATERIAL);

  if (loading) {
    return (
      <ViewGrid>
        <Box p={3}>
          <Spinner />
        </Box>
      </ViewGrid>
    );
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (params.slug) {
    const item = data.getSupportEntries.find(item => params.slug === item.slug);
    return <ContentfulEntry item={item} slug="support" />;
  }

  const supportItems = data.getSupportEntries.map(item => {
    if (!item.category) {
      item.category = 'General';
    }
    return item;
  });

  const categoriesWithItems = Object.entries(groupBy(supportItems, 'category'));
  const isCategoriesEnabled = categoriesWithItems.length > 1;

  const sortedCategories = [
    ...categoriesWithItems.filter(item => item[0] === 'General'),
    ...categoriesWithItems
      .filter(item => item[0] !== 'General')
      .sort((a, b) => a[0].localeCompare(b[0])),
  ];

  return (
    <ViewGrid>
      {isCategoriesEnabled ? (
        <>
          <Tabs
            value={activeIndex}
            onChange={(_, newActiveIndex) => setActiveIndex(newActiveIndex)}
            variant="scrollable"
            scrollButtons="on"
            aria-label="scrollable tabs"
          >
            {sortedCategories.map(([category], index) => (
              <Tab key={category} label={category} {...a11yProps(index)} />
            ))}
          </Tabs>

          {sortedCategories.map(([category, items], index) => (
            <TabPanel
              key={category}
              role="tabpanel"
              hidden={activeIndex !== index}
              id={`scrollable-auto-tabpanel-${index}`}
              aria-labelledby={`scrollable-auto-tab-${index}`}
            >
              {items.map((item, index) => (
                <StyledLink
                  to={`/support/${item.slug}`}
                  key={`support-${item.slug}-${index}`}
                >
                  <SupportCard entry={item} />
                </StyledLink>
              ))}
            </TabPanel>
          ))}
        </>
      ) : (
        <>
          {data.getSupportEntries.map((item, index) => (
            <StyledLink
              to={`/support/${item.slug}`}
              key={`support-${item.slug}-${index}`}
            >
              <SupportCard entry={item} />
            </StyledLink>
          ))}
        </>
      )}
    </ViewGrid>
  );
}

const Tab = withStyles({
  root: {
    textTransform: 'capitalize',
    font: '400 1.063rem/1.4 Nunito Sans, sans-serif',
    letterSpacing: '-0.2px',
    marginTop: '4px',
    marginBottom: '4px',
  },
  selected: {
    color: theme.colors.primary,
    fontWeight: 800,
  },
})(MuiTab);

const Tabs = withStyles({
  root: {
    backgroundColor: theme.colors.greyLight,
    marginLeft: `-${theme.space[1]}px`,
    marginRight: `-${theme.space[1]}px`,
    borderBottom: `1px solid ${theme.colors.greyMed}`,
    boxShadow: theme.elevation.default,
  },
  indicator: {
    backgroundColor: theme.colors.primary,
  },
})(MuiTabs);

const TabPanel = styled.div`
  margin-top: ${theme.space[2]}px;
  padding-right: ${theme.space[1]}px;
  padding-left: ${theme.space[1]}px;
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

Support.propTypes = propTypes;

export default withUser(Support);
