import React, { Fragment, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Box,
  Spinner,
  Heading,
  theme,
  // IconFeedback,
  IconNote,
  IconAssignment,
} from '@freska/freska-ui';

import { GET_BOOKING } from '../../gql/queries';
import { space } from '../../theme/theme';
import { BOOKING_DETAILS_PATHNAME, NETWORK_STATUS_ENUM } from '../../constants';
import { useServiceWorker } from '../../useServiceWorker';

import BackLink from '../Common/BackLink';
import StickyTop from '../Common/StickyTop';
import FullscreenContentContainer from '../Common/FullscreenContentContainer';
import BottomNavTab from '../Common/BottomNavTab';

import ErrorHandler from '../ErrorHandler/ErrorHandler';
import BookingDetailsTabInstructions from './BookingDetailsTabInstructions';
// import BookingDetailsPastFeedback from './BookingDetailsTabPastFeedback';
import BookingDetailsTabDetails from './BookingDetailsTabDetails';

import { useSearchParams } from '../../hooks/useSearchParams';
import { bookingsPathname } from '../../utils/urls';

function goToOverview(history, location, bookingId) {
  const pathname = bookingsPathname();
  if (location.state && location.state.workingArea) {
    history.push({
      pathname: `/areas`,
      state: { bookingId },
    });
  } else if (location.state && location.state.bookingCalendar) {
    history.push({
      pathname,
      state: {
        startDate: location.state.startDate,
        endDate: location.state.endDate,
      },
    });
  } else if (location.state && location.state.origin) {
    history.push(pathname);
  } else {
    history.goBack();
  }
}

function BookingDetails() {
  const history = useHistory();
  const location = useLocation();
  const { tab } = useParams();
  const { id } = useSearchParams();
  const onlineStatus = useServiceWorker();

  const { data, networkStatus, error } = useQuery(GET_BOOKING, {
    variables: {
      bookingId: Number(id),
    },
    pollInterval: onlineStatus ? 60000 : 0,
  });
  const booking = data?.getBooking;
  const loading = networkStatus === NETWORK_STATUS_ENUM.LOADING;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tab]);

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <Fragment>
      <FullscreenContentContainer>
        {loading && (
          <Box p={4}>
            <Spinner />
          </Box>
        )}
        {booking && (
          <Fragment>
            <StickyTop>
              <BackLink
                onClick={() => goToOverview(history, location, booking.id)}
                captionIntlId="bookings.details.back_link"
              />
              <Heading level={2} mb={1}>
                <FormattedMessage
                  id={`bookings.card.service_type.${booking.service.id}`}
                />
              </Heading>
            </StickyTop>
            <Wrapper>
              {tab === 'details' && (
                <BookingDetailsTabDetails booking={booking} />
              )}
              {tab === 'instructions' && (
                <BookingDetailsTabInstructions
                  customerNote={booking.detailSummary.customerNotes}
                  bookingId={booking.id}
                  serviceId={booking.service.id}
                />
              )}
              {/* {tab === 'feedback' &&
                !!booking.recurring_booking_id && (
                  <BookingDetailsPastFeedback
                    recurringBookingId={booking.recurring_booking_id}
                  />
                )} */}
            </Wrapper>
          </Fragment>
        )}
      </FullscreenContentContainer>
      {booking && (
        <FixedBottomNav>
          <BottomNavTab
            onClick={() => history.replace(`${BOOKING_DETAILS_PATHNAME}${location.search}`)}
            labelId="details"
            selected={tab === 'details'}
            Icon={IconNote}
          />
          <BottomNavTab
            onClick={() => history.replace(`/booking/instructions${location.search}`)}
            labelId="instructions"
            selected={tab === 'instructions'}
            Icon={IconAssignment}
          />
          {/* {!!booking?.recurring_booking_id && (
            <BottomNavTab
              onClick={() => history.replace(`/booking/feedback${location.search}`)}
              labelId="feedback"
              selected={tab === 'feedback'}
              Icon={IconFeedback}
            />
          )} */}
        </FixedBottomNav>
      )}
    </Fragment>
  );
}

const Wrapper = styled.section`
  padding: 0 ${space.default} ${space.default};
  height: 100%;
  z-index: 1;
`;

const FixedBottomNav = styled(Box)`
  background: ${theme.colors.white};
  box-shadow: ${theme.elevation.top};
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  position: sticky;
  bottom: 0;
  z-index: 4;
`;

export default BookingDetails;
