import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import {
  isThisWeek,
  isBefore,
  isAfter,
  isToday,
  differenceInSeconds,
  differenceInCalendarISOWeeks,
  parseISO,
} from 'date-fns';
import { Button, Text } from '@freska/freska-ui';
import { FormattedMessage } from 'react-intl';
import withLanguage from '../../utils/withLanguage';
import { formatNumber } from '../../utils/formatNumber';

import BookingsDayGroup from './BookingsDayGroup';
import NoContent from '../Common/NoContent';
import {
  BOOKING_STATUS_ENUM,
  BOOKING_STATUS_TYPE,
  LANGUAGES_TYPE,
} from '../../constants';
import { space, sizes } from '../../theme/theme';

const propTypes = {
  language: PropTypes.oneOf(LANGUAGES_TYPE).isRequired,
  bookingStatus: PropTypes.oneOf(BOOKING_STATUS_TYPE).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  startDate: PropTypes.instanceOf(Date),
  showNoBookings: PropTypes.bool,
};

const defaultProps = {
  startDate: null,
  showNoBookings: true,
};

class BookingsList extends PureComponent {
  state = {
    showPastBookings: false,
  };

  onClick = () => {
    this.setState(prevState => ({
      showPastBookings: !prevState.showPastBookings,
    }));
  };

  getTotalHoursWorked = () => {
    const { data, language: locale } = this.props;

    const totalHoursWorked = data.reduce(
      (currentDay, nextDay) =>
        currentDay +
        nextDay.bookings.reduce(
          (currentBooking, nextBooking) =>
            currentBooking + nextBooking.duration,
          0
        ),
      0
    );
    return formatNumber(totalHoursWorked, locale);
  };

  getTotalBookingsThisWeek = () => {
    const { data } = this.props;

    const totalBookingsThisWeek = data.reduce(
      (currentDay, nextDay) => currentDay + nextDay.bookings.length,
      0
    );
    return Number(totalBookingsThisWeek);
  };

  getPastAndUpcomingBookings = () => {
    const { data } = this.props;

    const pastBookingDayGroups = this.splitThisWeeksBookings(
      data,
      isBefore
    ).sort((previous, next) =>
      differenceInSeconds(parseISO(previous.date), parseISO(next.date))
    );

    const upcomingBookingDayGroups = this.splitThisWeeksBookings(
      data,
      isAfter,
      true
    ).sort((previous, next) =>
      differenceInSeconds(parseISO(previous.date), parseISO(next.date))
    );

    return { pastBookingDayGroups, upcomingBookingDayGroups };
  };

  splitThisWeeksBookings = (bookings, method, isUpcoming = false) => {
    const today = new Date();

    return bookings
      .filter(
        ({ date }) => method(parseISO(date), today) && !isToday(parseISO(date))
      )
      .concat(
        this.filterTodaysBookings(
          bookings.find(({ date }) => isToday(parseISO(date))),
          today,
          isUpcoming
        )
      )
      .filter(date => !!date.bookings.length);
  };

  filterTodaysBookings = ({ date, bookings = [] } = {}, today, isUpcoming) => ({
    bookings: bookings.filter(booking =>
      isUpcoming
        ? differenceInSeconds(parseISO(booking.end_time), today) > 0
        : differenceInSeconds(parseISO(booking.end_time), today) < 0
    ),
    date,
  });

  renderSummary = () => (
    <SummarySection>
      <Text as="span" size="large" bold mx={3}>
        <FormattedMessage id="bookings.summary" />
      </Text>
      <Text as="p" mb={4} mt={2} mx={3}>
        <FormattedMessage
          id="bookings.total_time"
          values={{
            totalBookings: this.getTotalBookingsThisWeek(),
            totalHours: this.getTotalHoursWorked(),
          }}
        />
      </Text>
    </SummarySection>
  );

  renderBookingsByStatus = () => {
    const { bookingStatus, data, startDate } = this.props;
    const { showPastBookings } = this.state;
    const isFromThisWeek = isThisWeek(startDate, { weekStartsOn: 1 });

    if (bookingStatus === BOOKING_STATUS_ENUM.UNCONFIRMED) {
      const flatData = [];
      data.map(day => day.bookings.map(booking => flatData.push(booking)));

      if (flatData.length) {
        return <BookingsDayGroup bookings={flatData} unconfirmed />;
      }
      return null;
    }

    if (isFromThisWeek) {
      const {
        pastBookingDayGroups,
        upcomingBookingDayGroups,
      } = this.getPastAndUpcomingBookings();
      if (pastBookingDayGroups.length || upcomingBookingDayGroups.length)
        return (
          <Fragment>
            {showPastBookings &&
              pastBookingDayGroups.map(({ date, bookings }) => (
                <BookingsDayGroup
                  date={date}
                  bookings={bookings}
                  key={uuidv4()}
                />
              ))}
            {!!pastBookingDayGroups.length && (
              <StyledButton
                variant="secondary"
                onClick={this.onClick}
                id="pastBookings-btn"
              >
                <FormattedMessage
                  id={`bookings.${
                    showPastBookings
                      ? 'hide_past_bookings'
                      : 'show_past_bookings'
                  }`}
                />
              </StyledButton>
            )}
            {upcomingBookingDayGroups.map(({ date, bookings }) => (
              <BookingsDayGroup
                date={date}
                bookings={bookings}
                key={uuidv4()}
              />
            ))}
            {this.renderSummary()}
          </Fragment>
        );
    }

    const arePastBookings =
      differenceInCalendarISOWeeks(startDate, new Date()) < 0;
    const sortedBookings = arePastBookings
      ? data.sort((previous, next) =>
          differenceInSeconds(parseISO(next.date), parseISO(previous.date))
        )
      : data;
    return (
      <Fragment>
        {sortedBookings.map(({ date, bookings }) => (
          <BookingsDayGroup date={date} bookings={bookings} key={uuidv4()} />
        ))}
        {!!data.length && this.renderSummary()}
      </Fragment>
    );
  };

  render() {
    const { data, showNoBookings } = this.props;
    if (showNoBookings && !data.length) {
      return <NoContent messageId="bookings.no_bookings" />;
    }
    return this.renderBookingsByStatus();
  }
}

const StyledButton = styled(Button)`
  background: transparent;
  box-shadow: none;
  :focus,
  :hover {
    box-shadow: none;
    background: transparent;
  }
  margin-left: calc(${space.md} + ${space.xs});
  padding-left: 0;
`;

const SummarySection = styled.section`
  margin: ${space.default} auto 0;
  max-width: ${sizes.width.bookingCardMaxWidth};
  width: 100%;
`;

BookingsList.propTypes = propTypes;
BookingsList.defaultProps = defaultProps;

export default withLanguage(BookingsList);
