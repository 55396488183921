import React, { useState, Fragment } from 'react';
import {
  Box,
  theme,
  Text,
  Button,
  Heading,
  IconWarning,
} from '@freska/freska-ui';
import { FormattedMessage } from 'react-intl';
import { useQuery, useMutation } from 'react-apollo';
import { endOfDay, addDays, format, isBefore } from 'date-fns';

import { GET_ABSENCES } from '../../gql/queries';
import { UPDATE_ABSENCE_BY_ID } from '../../gql/mutations';
import Modal from '../Common/Modal';
import ButtonLink from '../Common/ButtonLink';
import NotificationBlock from '../Common/NotificationBlock';
import AbsenceBox from './AbsenceBox';
import { useServiceWorker } from '../../useServiceWorker';
import AbsenceTypeIcon from '../Common/AbsenceTypeIcon';

const REPORT_FIT_BEFORE_TIME = new Date(
  new Date(new Date().setUTCHours(13)).setUTCMinutes(0)
);
const NOW_BEFORE_THRESHOLD = isBefore(new Date(), REPORT_FIT_BEFORE_TIME);

const AbsenceNotifier = () => {
  const [toggleReportFitDialog, setToggleReportFitDialog] = useState(false);
  const [selectedSickLeave, setSelectedSickLeave] = useState(false);
  const { onlineStatus } = useServiceWorker();

  const { data, loading, error } = useQuery(GET_ABSENCES, {
    variables: { filter: 'ongoing' },
    notifyOnNetworkStatusChange: true,
    pollInterval: onlineStatus ? 60000 : 0,
  });

  const [
    updateAbsence,
    { loading: loadingUpdateAbsence, error: errorUpdateAbsence },
  ] = useMutation(UPDATE_ABSENCE_BY_ID);

  function handleFitForWork(sickLeave) {
    const lastDayOfAbsence = endOfDay(
      NOW_BEFORE_THRESHOLD ? new Date() : addDays(new Date(), 1)
    );
    updateAbsence({
      variables: {
        absenceId: Number(sickLeave.id),
        absence: {
          id: Number(sickLeave.id),
          end_time: lastDayOfAbsence,
          start_time: sickLeave.start_time,
        },
      },
    }).then(() => {
      if (!errorUpdateAbsence) setToggleReportFitDialog(false);
    });
  }

  const absences = data && data.getAbsences;

  if (loading) return null;

  if (error) {
    return (
      <NotificationBlock hasError>
        <FormattedMessage id="absence.error_fetching_ongoing_absences" />
      </NotificationBlock>
    );
  }

  if (absences && absences.length > 0) {
    return (
      <Box>
        {toggleReportFitDialog && (
          <Modal size="small">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              backgroundColor={theme.colors.alert}
              py={2}
              borderRadius={`${theme.borderRadius}px ${theme.borderRadius}px, 0 0`}
              mb={3}
            >
              {errorUpdateAbsence ? (
                <IconWarning color="white" />
              ) : (
                <AbsenceTypeIcon
                  type={selectedSickLeave.reason.key}
                  color="white"
                />
              )}
            </Box>
            <Box
              mx={3}
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              flexDirection="column"
            >
              {errorUpdateAbsence ? (
                <Fragment>
                  <Text size="large" bold textAlign="center" mb={3}>
                    <FormattedMessage id="absence.error_updating_absence" />
                  </Text>
                  <ButtonLink onClick={() => setToggleReportFitDialog(false)}>
                    <FormattedMessage id="absence.modal_close" />
                  </ButtonLink>
                </Fragment>
              ) : (
                <Fragment>
                  <Heading level={3} mb={1} textAlign="center">
                    <FormattedMessage id="absence.modal_heading" />
                  </Heading>
                  <Text size="large" textAlign="center" mb={1}>
                    <FormattedMessage
                      id={`absence.modal_text_intro_${
                        NOW_BEFORE_THRESHOLD ? 'before' : 'after'
                      }_threshold`}
                      values={{
                        time: format(REPORT_FIT_BEFORE_TIME, 'H:mm'),
                        // eslint-disable-next-line react/display-name
                        b: children => <b>{children}</b>,
                      }}
                    />
                  </Text>
                  <Text textAlign="center" mb={1}>
                    <FormattedMessage
                      id={`absence.modal_text_details_${
                        NOW_BEFORE_THRESHOLD ? 'before' : 'after'
                      }_threshold`}
                      values={{
                        // eslint-disable-next-line react/display-name
                        b: children => <b>{children}</b>,
                      }}
                    />
                  </Text>
                  <Text textAlign="center" mb={3}>
                    <FormattedMessage id="absence.modal_text_details_still_sick" />
                  </Text>
                  <Button
                    onClick={() => handleFitForWork(selectedSickLeave)}
                    mb={2}
                    loading={loadingUpdateAbsence}
                  >
                    <FormattedMessage id="absence.action_fit_for_work" />
                  </Button>
                  <ButtonLink onClick={() => setToggleReportFitDialog(false)}>
                    <FormattedMessage id="absence.still_sick" />
                  </ButtonLink>
                </Fragment>
              )}
            </Box>
          </Modal>
        )}
        {!!absences.length &&
          absences.map(absence => (
            <AbsenceBox
              key={absence.id}
              type={
                absence.end_time === null && absence.reason.key === 'sick_leave'
                  ? 'sickLeaveWithoutEndTime'
                  : 'absenceWithEndTime'
              }
              absence={absence}
              setSelectedSickLeave={setSelectedSickLeave}
              setToggleReportFitDialog={setToggleReportFitDialog}
            />
          ))}
      </Box>
    );
  }
  return null;
};

export default AbsenceNotifier;
