import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { set } from 'date-fns';
import PropTypes from 'prop-types';
import { Text, Label } from '@freska/freska-ui';
import { space, colors } from '../../theme/theme';

const propTypes = {
  day: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};

const defaultProps = {
  startTime: null,
  endTime: null,
};

function AvailabilityDay({ day, startTime, endTime }) {
  const today = new Date();
  const getHoursAndMinutesIntegers = timeString =>
    timeString.split(':').map(time => parseInt(time, 10));

  return (
    <Wrapper isOn={startTime}>
      <Label mb={1}>
        <FormattedMessage key={day} id={`dates.${day}`} />
      </Label>
      {startTime ? (
        <Text size="smaller" as="p">
          <FormattedTime
            value={set(today, {
              hours: getHoursAndMinutesIntegers(startTime)[0],
              minutes: getHoursAndMinutesIntegers(startTime)[1],
            })}
            hour12={false}
          />
          <br />
          <FormattedTime
            value={set(today, {
              hours: getHoursAndMinutesIntegers(endTime)[0],
              minutes: getHoursAndMinutesIntegers(endTime)[1],
            })}
            hour12={false}
          />
        </Text>
      ) : (
        <Text size="smaller" as="p">
          <FormattedMessage id="availability.off" />
        </Text>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  text-align: center;
  flex-basis: 0;
  flex-grow: 1;
  padding: ${space.xs} 0 ${space.sm};
  margin-right: ${space.xs};
  border-radius: 4px;

  ${props =>
    props.isOn && `background-color: ${colors.background.availabilityLight};`};

  :last-child {
    margin-right: 0;
  }
`;

AvailabilityDay.propTypes = propTypes;
AvailabilityDay.defaultProps = defaultProps;

export default AvailabilityDay;
