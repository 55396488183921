import React, { Component } from 'react';
import styled from 'styled-components';
import { func, number, shape, string, arrayOf, bool } from 'prop-types';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  differenceInCalendarISOWeeks,
  parseISO,
  isBefore,
  format,
} from 'date-fns';
import {
  Box,
  Spinner,
  Button,
  Modal,
  theme,
  Heading,
  IconClose,
} from '@freska/freska-ui';
import { space, sizes } from '../../theme/theme';
import { DAY_ABBREV_TYPE } from '../../constants';

import {
  CREATE_AVAILABILITY_GROUP,
  EDIT_AVAILABILITY_GROUP,
  DELETE_AVAILABILITY_GROUP,
} from '../../gql/mutations';
import {
  GET_AVAILABILITY_CALENDAR_DATA,
  GET_BOOKINGS_VIEW_DATA,
  GET_AVAILABILITY_GROUPS,
} from '../../gql/queries';
import { trackEvent } from '../../utils/tracking';
import { getClosestMonday } from '../../utils/time';
import withUser from '../../utils/withUser';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import DateRange from './DateRange';
import Time from './Time';
import FlexContainer from '../Common/FlexContainer';
import Blockquote from '../Common/Blockquote';
import FullscreenContentContainer from '../Common/FullscreenContentContainer';
import weeklySchema from './weeklySchema';
import validateData from '../../utils/validate';
import { ServiceWorkerContext } from '../../useServiceWorker';
import ConfirmationModal from '../Common/ConfirmationModal';

const propTypes = {
  intl: shape({}).isRequired,
  user: shape({ id: string }).isRequired,
  onEdit: func.isRequired,
  create: func.isRequired,
  edit: func.isRequired,
  remove: func.isRequired,
  availabilityGroup: shape({
    id: number,
    valid_from: string,
    valid_to: string,
    only_one_offs: bool,
    schedule: arrayOf(
      shape({
        start: string,
        end: string,
      })
    ),
  }),
  defaultSchedule: shape({}),
  defaultDay: shape({
    start: string,
    end: string,
  }),
};

const defaultProps = {
  defaultSchedule: {
    0: { start: '08:00', end: '18:00' },
    1: { start: '08:00', end: '18:00' },
    2: { start: '08:00', end: '18:00' },
    3: { start: '08:00', end: '18:00' },
    4: { start: '08:00', end: '18:00' },
    5: null,
    6: null,
  },
  defaultDay: { start: '08:00', end: '18:00' },
  availabilityGroup: {
    valid_from: getClosestMonday(),
  },
};

class AvailabilityList extends Component {
  state = {
    startTime: '',
    endTime: '',
    isOneOff: false,
    isContinuous: true,
    start: [],
    end: [],
    isSelected: [],
    isTimeSet: false,
    loading: false,
    responseError: null,
    showOneOffDialog: false,
    showDeleteDialog: false,
    errors: {},
  };

  componentDidMount() {
    trackEvent('Availability: Rules: Availability Edit: Opened', {
      category: 'Availability',
    });
    this.fillFormFields();
  }

  fillFormFields = () => {
    const {
      defaultSchedule,
      availabilityGroup: {
        schedule,
        valid_from: validFrom,
        valid_to: validTo,
        only_one_offs: isOneOff,
      },
    } = this.props;
    if (!schedule) {
      this.setState({
        startTime: format(parseISO(validFrom), 'yyyy-MM-dd'),
        endTime: '',
        isOneOff: isOneOff || false,
      });
    } else {
      this.setState({
        startTime: format(parseISO(validFrom), 'yyyy-MM-dd'),
        endTime: validTo ? format(parseISO(validTo), 'yyyy-MM-dd') : '',
        isContinuous: !validTo,
        isOneOff: isOneOff || false,
      });
    }
    this.setDaysTime(schedule || defaultSchedule);
  };

  setDaysTime = schedule => {
    const { defaultDay } = this.props;
    const {
      start: startState,
      end: endState,
      isSelected: isSelectedState,
    } = this.state;
    for (let i = 0; i < 7; i += 1) {
      const scheduleDay = schedule[i] || defaultDay;
      startState[i] = scheduleDay.start;
      endState[i] = scheduleDay.end;
      isSelectedState[i] = !!schedule[i];
    }
    this.setState(
      {
        start: startState,
        end: endState,
        isSelected: isSelectedState,
      },
      () => this.setState({ isTimeSet: true })
    );
  };

  handleDateChange = (e, type) => {
    const { endTime } = this.state;
    if (type === 'startTime' && e.target.value > endTime) {
      this.setState({ endTime: '' });
    }
    this.setState({ [type]: e.target.value });
  };

  handleTimeChange = (e, type, dayNo) => {
    const { start, end } = this.state;
    const times = type === 'start' ? [...start] : [...end];
    times[dayNo] = e.target.value;
    this.setState({ [type]: times });
  };

  handleContinuousCheck = () => {
    const { startTime } = this.state;
    this.setState(prevState => ({
      isContinuous: !prevState.isContinuous,
    }));
    this.setState({
      endTime: isBefore(parseISO(startTime), new Date())
        ? format(new Date(), 'yyyy-MM-dd')
        : startTime,
    });
  };

  handleOneOffSwitch = () => {
    this.setState(
      prevState => ({
        isOneOff: !prevState.isOneOff,
      }),
      () => this.showOneOffConfirmation()
    );
  };

  getFormattedSchedule = (start, end, isSelected) => {
    const schedule = [];
    for (let i = 0; i < 7; i += 1) {
      schedule[i] = isSelected[i] ? { start: start[i], end: end[i] } : null;
    }
    return schedule;
  };

  onDaySelect = dayIndex => {
    const { isSelected: isSelectedState } = this.state;
    isSelectedState[dayIndex] = !isSelectedState[dayIndex];
    this.setState({ isSelected: isSelectedState });
  };

  showOneOffConfirmation = () => {
    const { isOneOff } = this.state;

    if (isOneOff) {
      this.toggleDialog('showOneOffDialog');
    }
  };

  toggleDialog = dialog => {
    this.setState(prevState => ({
      [dialog]: !prevState[dialog],
    }));
  };

  handleDelete = () => {
    trackEvent(
      'Availability: Rules: Availability Edit: Delete button clicked',
      {
        category: 'Availability',
      }
    );

    const { remove, availabilityGroup } = this.props;
    this.setState({
      loading: true,
    });
    this.mutate(remove, {
      id: availabilityGroup.id,
    });
  };

  validate = () => {
    const { user } = this.props;
    const {
      startTime,
      endTime,
      isOneOff,
      start,
      end,
      isSelected,
      isContinuous,
    } = this.state;

    const group = {
      only_one_offs: isOneOff,
      schedule: this.getFormattedSchedule(start, end, isSelected),
      service_worker_id: parseInt(user.id, 10),
      valid_from: startTime,
      valid_to: isContinuous ? null : endTime,
    };
    const newSchedule = group.schedule.map(scheduleDay =>
      scheduleDay
        ? {
            start: parseInt(scheduleDay.start.split(':')[0], 10),
            end: parseInt(scheduleDay.end.split(':')[0], 10),
            startTime: scheduleDay.start,
            endTime: scheduleDay.end,
          }
        : null
    );
    validateData({ ...group, schedule: newSchedule }, weeklySchema).then(
      res => {
        this.setState(
          { errors: res },
          () => Object.keys(res).length === 0 && this.handleSave(group)
        );
      }
    );
  };

  handleCancel = () => {
    const { onEdit } = this.props;
    trackEvent(
      'Availability: Rules: Availability Edit: Cancel button clicked',
      {
        category: 'Availability',
      }
    );
    onEdit({ isEditing: false });
  };

  handleSave(group) {
    const { create, edit, availabilityGroup } = this.props;
    trackEvent('Availability: Rules: Availability Edit: Save button clicked', {
      category: 'Availability',
    });

    this.setState({
      loading: true,
    });

    if (!availabilityGroup.id) {
      this.mutate(create, {
        group,
      });
    } else {
      this.mutate(edit, {
        id: availabilityGroup.id,
        group,
      });
    }
  }

  mutate(mutation, variables) {
    const { onEdit } = this.props;
    mutation({
      variables,
      notifyOnNetworkStatusChange: true,
      awaitRefetchQueries: true,
      refetchQueries: () => [
        { query: GET_AVAILABILITY_CALENDAR_DATA },
        { query: GET_AVAILABILITY_GROUPS },
        { query: GET_BOOKINGS_VIEW_DATA },
      ],
    })
      .then(() => {
        this.setState(
          {
            loading: false,
            responseError: null,
          },
          () => {
            onEdit({ isEditing: false });
          }
        );
      })
      .catch(error => {
        this.setState(
          {
            loading: false,
            responseError: error,
          },
          () => {
            onEdit({ isEditing: false });
          }
        );
      });
  }

  render() {
    const {
      startTime,
      endTime,
      start,
      end,
      isSelected,
      isOneOff,
      isContinuous,
      isTimeSet,
      loading,
      responseError,
      showOneOffDialog,
      showDeleteDialog,
      errors,
    } = this.state;
    const { availabilityGroup, intl } = this.props;
    if (!isTimeSet || loading) {
      return (
        <Box p={3}>
          <Spinner />
        </Box>
      );
    }

    if (responseError) {
      return <ErrorHandler error={responseError} />;
    }

    return (
      <ServiceWorkerContext.Consumer>
        {({ onlineStatus }) => (
          <FullscreenContentContainer>
            {showOneOffDialog && (
              <Modal
                onClose={() => {
                  this.handleOneOffSwitch();
                  this.toggleDialog('showOneOffDialog');
                }}
                maxWidth="327px"
              >
                <Modal.Title>
                  <FormattedMessage id="availability.one_off_modal.title" />
                </Modal.Title>
                <Modal.Description>
                  <FormattedMessage id="availability.one_off_modal.message" />
                </Modal.Description>
                <Modal.Content
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                >
                  <Box>
                    <Button
                      onClick={() => this.toggleDialog('showOneOffDialog')}
                    >
                      <FormattedMessage id="availability.one_off_modal.confirm" />
                    </Button>
                  </Box>
                </Modal.Content>
              </Modal>
            )}
            {showDeleteDialog && (
              <ConfirmationModal
                onConfirm={this.handleDelete}
                onClose={() => this.toggleDialog('showDeleteDialog')}
                titleId="availability.delete_modal.title"
                messageId="availability.delete_modal.message"
                buttonCaptionId="availability.delete_modal.confirm"
              />
            )}
            <CardContainer>
              <HeaderGrid mb={3}>
                <Heading level={2} m={0}>
                  <FormattedMessage id="availability.edit_rule" />
                </Heading>
                <IconClose size={24} onClick={this.handleCancel} />
              </HeaderGrid>
              {endTime &&
                differenceInCalendarISOWeeks(
                  parseISO(endTime),
                  parseISO(startTime)
                ) < 12 &&
                !isContinuous && (
                  <Blockquote
                    label={intl.formatMessage({
                      id: 'availability.please_note',
                    })}
                    color="attention"
                  >
                    <FormattedMessage id="availability.stable_availability" />
                  </Blockquote>
                )}
              <DateRange
                id="range"
                handleDateChange={this.handleDateChange}
                startValue={startTime}
                endValue={endTime}
                isContinuous={isContinuous}
                isOneOffChecked={isOneOff}
                handleContinuousCheck={this.handleContinuousCheck}
                handleOneOffSwitch={this.handleOneOffSwitch}
                errors={errors}
              />
              {DAY_ABBREV_TYPE.map((day, index) => (
                <Time
                  key={day}
                  id={day}
                  title={intl.formatMessage({ id: `pluralDates.${day}` })}
                  index={index}
                  handleInputChange={(e, type) =>
                    this.handleTimeChange(e, type, index)
                  }
                  handleSwitch={() => this.onDaySelect(index)}
                  isChecked={isSelected[index]}
                  startValue={start[index]}
                  endValue={end[index]}
                  errors={errors}
                />
              ))}
              <FlexContainer alignH="space-between" pt={2}>
                <Button
                  variant="primary"
                  onClick={() => this.validate()}
                  disabled={(!isContinuous && !endTime) || !onlineStatus}
                >
                  <FormattedMessage id="app.save" />
                </Button>
                {availabilityGroup.id && (
                  <Button
                    variant="destructive"
                    onClick={() => this.toggleDialog('showDeleteDialog')}
                    disabled={!onlineStatus}
                  >
                    <FormattedMessage id="app.delete" />
                  </Button>
                )}
              </FlexContainer>
            </CardContainer>
          </FullscreenContentContainer>
        )}
      </ServiceWorkerContext.Consumer>
    );
  }
}

const CardContainer = styled.section`
  position: relative;
  background: ${theme.colors.white};
  margin: 0 auto;
  padding: ${space.default};
  max-width: ${sizes.width.bookingCardMaxWidth};

  @media (min-width: 375px) {
    padding-left: ${space.default};
    padding-right: ${space.default};
  }
`;

const HeaderGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 24px;
  align-items: center;
`;

AvailabilityList.propTypes = propTypes;
AvailabilityList.defaultProps = defaultProps;

export default compose(
  graphql(CREATE_AVAILABILITY_GROUP, {
    name: 'create',
  }),
  graphql(EDIT_AVAILABILITY_GROUP, {
    name: 'edit',
  }),
  graphql(DELETE_AVAILABILITY_GROUP, {
    name: 'remove',
  })
)(withUser(injectIntl(AvailabilityList)));
