import React, { Component, Fragment } from 'react';
import { shape, oneOf, func, arrayOf, string, instanceOf } from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Heading,
  Text,
  Box,
  Button,
  Spinner,
  Checkbox,
  IconReceipt,
} from '@freska/freska-ui';
import { getYear, startOfDay, endOfYear, startOfYear } from 'date-fns';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import withLanguage from '../../utils/withLanguage';
import {
  formatPrice,
  formatNumber,
  formatUnitPrice,
} from '../../utils/formatNumber';
import { trackEvent } from '../../utils/tracking';
import { getRawServiceTypeIcon } from '../../utils/bookingsHelper';
import { LANGUAGES_TYPE, SERVICE_TYPES } from '../../constants';

import { GET_PRICE_LEVEL, GET_INVOICES } from '../../gql/queries';
import { CREATE_INVOICE } from '../../gql/mutations';

import StickyTop from '../Common/StickyTop';
import DataBlock from '../Common/DataBlock';
import Blockquote from '../Common/Blockquote';
import FlexContainer from '../Common/FlexContainer';
import FullscreenContentContainer from '../Common/FullscreenContentContainer';
import BackLink from '../Common/BackLink';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import NotificationBlock from '../Common/NotificationBlock';
import InvoicedBookingCard from './InvoicedBookingCard';
import TotalPerServiceType from './TotalPerServiceType';
import renderInvoiceDetail from './InvoiceReceiptDetail';
import { ServiceWorkerContext } from '../../useServiceWorker';

import { space } from '../../theme/theme';
import { InfoBox } from './InfoBox';

const propTypes = {
  intl: shape({}).isRequired,
  history: shape({ push: func }).isRequired,
  language: oneOf(LANGUAGES_TYPE).isRequired,
  priceLevel: shape({
    getPriceLevel: shape({ currency: string, price: string }),
  }).isRequired,
  createInvoice: func.isRequired,
  bookings: arrayOf(shape({})).isRequired,
  startDate: instanceOf(Date).isRequired,
  endDate: instanceOf(Date).isRequired,
  onGoBack: func.isRequired,
};

const svgSuccess = (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none">
      <path d="M0 0h24v24H0z" />
      <rect fill="#76d2a0" width="24" height="24" rx="12" />
      <path
        d="M17.625 13.248c0 1.218-.97 5.002-5.625 5.002-4.656 0-5.625-3.784-5.625-5.002s1.903-.623 5.625-.623 5.625-.595 5.625.623zM3.875 7a1.875 1.875 0 110 3.75 1.875 1.875 0 010-3.75zm16.25 0a1.875 1.875 0 110 3.75 1.875 1.875 0 010-3.75z"
        fill="#000"
      />
    </g>
  </svg>
);

class InvoiceDetails extends Component {
  state = {
    agreed: false,
    isCreatingInvoice: false,
    didCreateInvoiceFail: false,
    createdPeriods: [],
  };

  getTotalHours = bookings => {
    const { language } = this.props;
    const totalHours = bookings.reduce(
      (prev, current) =>
        prev + Number(current.invoiceable_duration || current.duration),
      0
    );
    return totalHours ? formatNumber(totalHours, language) : null;
  };

  getServiceTotalHours = (service, bookings) => {
    const totalHours = this.getTotalHours(
      bookings.filter(booking => booking.service.name === service)
    );

    return totalHours ? (
      <FormattedMessage id="invoices.hours" values={{ hours: totalHours }} />
    ) : null;
  };

  getServiceTotalAmount = (service, bookings) => {
    const {
      language,
      priceLevel: {
        getPriceLevel: { currency, price },
      },
    } = this.props;
    const totalAmount = bookings
      .filter(booking => booking.service.name === service)
      .reduce(
        (accumulator, current) =>
          accumulator +
          Number(current.invoiceable_duration || current.duration) * price,
        0
      );
    return formatPrice(totalAmount, language, currency);
  };

  handleInvoiceCreation = ({ start, end }) => {
    const { bookings, createInvoice } = this.props;

    return createInvoice({
      variables: { startDate: start, endDate: end },
      notifyOnNetworkStatusChange: true,
      update: (store, { data: { createInvoice: invoice } }) => {
        const data = store.readQuery({
          query: GET_INVOICES,
        });

        // remove those bookings from uninvoiced
        data.getInvoices.uninvoicedBookings = data.getInvoices.uninvoicedBookings.filter(
          uninvoicedBooking =>
            !bookings.find(b => b.id === uninvoicedBooking.id)
        );

        // add them to pending
        data.getInvoices.invoices.push(invoice);

        store.writeQuery({
          query: GET_INVOICES,
          data,
        });
      },
    });
  };

  onCreateInvoice = async e => {
    const { startDate, endDate } = this.props;
    this.setState({ isCreatingInvoice: true });
    e.preventDefault();

    const endYear = getYear(endDate);
    // Create multiple invoices only if there's bookings from 2021 and 2022 because of company ID change
    const isMultipleInvoices =
      getYear(startDate) !== endYear && endYear === 2022;

    if (isMultipleInvoices) {
      let createdInvoicePeriods = [];
      const firstEndDate = startOfDay(endOfYear(startDate));
      const secondStartTime = startOfYear(endDate);
      await this.handleInvoiceCreation({ start: startDate, end: firstEndDate })
        .then(response => {
          trackEvent('Invoice period created', {
            category: 'Invoices',
          });

          createdInvoicePeriods.push({
            id: response.data.createInvoice.id,
            reference: response.data.createInvoice.our_reference,
          });
        })
        .catch(error => {
          this.setState({
            didCreateInvoiceFail: true,
            isCreatingInvoice: false,
          });
          // eslint-disable-next-line no-console
          console.error('Error creating invoice', error);
        });

      await this.handleInvoiceCreation({ start: secondStartTime, end: endDate })
        .then(response => {
          trackEvent('Invoice period created', {
            category: 'Invoices',
          });

          createdInvoicePeriods.push({
            id: response.data.createInvoice.id,
            reference: response.data.createInvoice.our_reference,
          });
        })
        .catch(error => {
          this.setState({
            didCreateInvoiceFail: true,
            isCreatingInvoice: false,
          });
          // eslint-disable-next-line no-console
          console.error('Error creating invoice', error);
        });
      this.setState({ createdPeriods: createdInvoicePeriods });
    } else {
      this.handleInvoiceCreation({ start: startDate, end: endDate })
        .then(response => {
          trackEvent('Invoice period created', {
            category: 'Invoices',
          });
          history.push({
            pathname: `/invoices/${response.data.createInvoice.id}`,
          });
        })
        .catch(error => {
          this.setState({
            didCreateInvoiceFail: true,
            isCreatingInvoice: false,
          });
          // eslint-disable-next-line no-console
          console.error('Error creating invoice', error);
        });
    }
  };

  render() {
    const {
      intl,
      language,
      startDate,
      endDate,
      bookings,
      priceLevel: { loading, error, getPriceLevel },
      onGoBack,
    } = this.props;
    const {
      agreed,
      isCreatingInvoice,
      didCreateInvoiceFail,
      createdPeriods,
    } = this.state;
    const endYear = getYear(endDate);
    // Create multiple invoices only if there's bookings from 2021 and 2022 because of company ID change
    const isMultipleInvoices =
      getYear(startDate) !== endYear && endYear === 2022;

    if (error) {
      return <ErrorHandler error={error} />;
    }

    return (
      <ServiceWorkerContext.Consumer>
        {({ onlineStatus }) => (
          <FullscreenContentContainer>
            {loading ? (
              <Box p={3}>
                <Spinner />
              </Box>
            ) : (
              <Fragment>
                <StickyTop>
                  <BackLink
                    onClick={onGoBack}
                    captionIntlId="invoices.back_link"
                  />
                  {!createdPeriods.length && (
                    <>
                      <Heading level={2} mb={2} align="left">
                        <FormattedMessage id="invoices.invoice_period" />
                      </Heading>
                      <Blockquote
                        label={intl.formatMessage({
                          id: 'invoices.invoice_period_note',
                        })}
                        color="attention"
                      >
                        <FormattedMessage id="invoices.invoice_period_disclaimer" />
                      </Blockquote>
                      <Text as="p" color="black" bold mb={0}>
                        <FormattedMessage id="invoices.all_invoiceable_bookings" />
                      </Text>
                      <TimeFlexContainer alignV="center">
                        {`${this.getTotalHours(bookings)} hours`}
                        <DetailsWrapper>
                          <FormattedDate
                            value={startDate}
                            year="numeric"
                            month="2-digit"
                            day="2-digit"
                          />
                          &nbsp;&ndash;&nbsp;
                          <FormattedDate
                            value={endDate}
                            year="numeric"
                            month="2-digit"
                            day="2-digit"
                          />
                        </DetailsWrapper>
                      </TimeFlexContainer>
                    </>
                  )}
                </StickyTop>
                {createdPeriods.length ? (
                  <Box
                    alignItems="center"
                    justifyContent="center"
                    flexWrap="wrap"
                    minWidth={256}
                  >
                    <Emotion>{svgSuccess}</Emotion>
                    <Box
                      p={2}
                      alignItems="center"
                      justifyContent="center"
                      flexWrap="wrap"
                      textAlign="center"
                      width="100%"
                    >
                      <Text bold>
                        <FormattedMessage id="invoice_periods_created" />
                      </Text>
                      <Text>
                        <FormattedMessage id="multiple_invoices_created" />
                      </Text>
                      {createdPeriods.map(invoicePeriod => (
                        <StyledLink
                          key={invoicePeriod.id}
                          to={`/invoices/${invoicePeriod.id}`}
                        >
                          {invoicePeriod.reference}
                        </StyledLink>
                      ))}
                    </Box>
                  </Box>
                ) : (
                  <Wrapper>
                    {didCreateInvoiceFail && (
                      <NotificationBlock hasError={didCreateInvoiceFail}>
                        <FormattedMessage id="invoices.errors.invoice_create_fail" />
                      </NotificationBlock>
                    )}
                    <Section>
                      <DataBlock Icon={IconReceipt} hasContent>
                        <Grid>
                          {renderInvoiceDetail(
                            'invoices.ukko.unit_price',
                            formatUnitPrice(
                              getPriceLevel.price,
                              language,
                              getPriceLevel.currency
                            )
                          )}
                          {renderInvoiceDetail(
                            'invoices.ukko.total_amount',
                            formatPrice(
                              this.getTotalHours(bookings) *
                                getPriceLevel.price,
                              language,
                              getPriceLevel.currency
                            )
                          )}
                        </Grid>
                      </DataBlock>
                    </Section>
                    <Section>
                      <Heading level={3} mb={2}>
                        <FormattedMessage id="invoices.totals_per_service_type" />
                      </Heading>
                      {SERVICE_TYPES.map(serviceType => (
                        <TotalPerServiceType
                          key={serviceType}
                          Icon={getRawServiceTypeIcon(serviceType)}
                          totalHours={this.getServiceTotalHours(
                            serviceType,
                            bookings
                          )}
                          totalAmount={this.getServiceTotalAmount(
                            serviceType,
                            bookings
                          )}
                        />
                      ))}
                    </Section>
                    <Section>
                      <Heading level={3} mb={2}>
                        <FormattedMessage id="invoices.included_bookings" />
                      </Heading>
                      {bookings.map(booking => (
                        <InvoicedBookingCard
                          key={booking.id}
                          booking={booking}
                          serviceType={booking.service.name}
                          unitPrice={Number(getPriceLevel.price)}
                          totalPrice={
                            Number(getPriceLevel.price) *
                            (booking.invoiceable_duration || booking.duration)
                          }
                          currency={getPriceLevel.currency}
                        />
                      ))}
                    </Section>
                    <Section>
                      <Checkbox
                        my={2}
                        label={intl.formatMessage({
                          id: 'invoices.agreement',
                        })}
                        id="invoice-agreement"
                        name="invoice-agreement"
                        onChange={() =>
                          this.setState(prevState => ({
                            agreed: !prevState.agreed,
                          }))
                        }
                        checked={agreed}
                        required
                      />
                      {isMultipleInvoices && (
                        <InfoBox
                          mb={2}
                          messageId="multiple_invoices_will_be_created"
                        />
                      )}
                      <Button
                        variant="primary"
                        disabled={!agreed || isCreatingInvoice || !onlineStatus}
                        onClick={this.onCreateInvoice}
                        loading={isCreatingInvoice}
                      >
                        <FormattedMessage
                          id={`invoices.create_invoice_period${
                            isMultipleInvoices ? 's' : ''
                          }`}
                        />
                      </Button>
                    </Section>
                  </Wrapper>
                )}
              </Fragment>
            )}
          </FullscreenContentContainer>
        )}
      </ServiceWorkerContext.Consumer>
    );
  }
}

const StyledLink = styled(Link)`
  color: black;
  display: block;
  text-decoration: underline;
`;

const Emotion = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  width: 100%;
  border-radius: 8px 8px 0 0;
`;

const Section = styled.section`
  margin-bottom: ${space.lg};
`;

const Wrapper = styled.section`
  padding: 0 ${space.default} ${space.default};
`;

const TimeFlexContainer = styled(FlexContainer)`
  margin-bottom: ${space.xs};
  justify-content: flert;
  display: inline-flex;
  flex-wrap: wrap;
`;

const DetailsWrapper = styled.div`
  :before {
    content: '•';
    padding: 0 ${space.sm};
  }
`;

const Grid = styled.div`
  grid-gap: ${space.default};
  align-items: start;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

InvoiceDetails.propTypes = propTypes;

export default compose(
  graphql(GET_PRICE_LEVEL, {
    name: 'priceLevel',
  }),
  graphql(CREATE_INVOICE, {
    name: 'createInvoice',
  })
)(withRouter(injectIntl(withLanguage(InvoiceDetails))));
