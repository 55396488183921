import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  FormattedMessage,
  FormattedDate,
  FormattedTime,
  useIntl,
} from 'react-intl';
import {
  Box,
  Card,
  IconKeyPickUp,
  IconKeyReturn,
  IconPin,
  Label,
  Spinner,
  Text,
  theme,
} from '@freska/freska-ui';
import { bookingDetailsURL } from '../../utils/urls';

const propTypes = {
  keys: PropTypes.array,
  bookings: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default function ActionRequired({ keys, bookings, isLoading }) {
  const intl = useIntl();

  const dot = <>&nbsp;&nbsp;&#8226;&nbsp;&nbsp;</>;
  return (
    <>
      <Text bold ml={2}>
        <FormattedMessage id="dashboard.action_required" />
      </Text>
      <Card mt={1} mb={3} p={2} pb={0}>
        {isLoading ? (
          <Box pb={2}>
            <Spinner size={24} />
          </Box>
        ) : (
          <>
            {!bookings?.length && !keys?.length && (
              <Text pb={2} color="secondary">
                <FormattedMessage id="dashboard.up_to_date" />
              </Text>
            )}

            {bookings?.map((booking, index, array) => (
              <StyledLink to={bookingDetailsURL(booking.id)} key={booking.id}>
                <Box display="flex" pb={2}>
                  <IconContainer>
                    <IconPin color="white" size={16} />
                  </IconContainer>
                  <Box>
                    <Label m={0} mb="4px" color="attention">
                      <FormattedMessage id="dashboard.booking_status" />
                    </Label>
                    <Text size="small" color="secondary">
                      <FormattedDate
                        value={booking.start_time}
                        weekday="short"
                        day="2-digit"
                        month="short"
                      />
                      {dot}
                      <time>
                        <FormattedTime
                          value={booking.start_time}
                          hour="2-digit"
                          minute="2-digit"
                          hour12={false}
                        />
                        &ndash;
                        <FormattedTime
                          value={booking.end_time}
                          hour="2-digit"
                          minute="2-digit"
                          hour12={false}
                        />
                      </time>
                      {dot}
                      <FormattedMessage
                        id={`bookings.card.frequency.${
                          booking.isSubstituteCleaner
                            ? 'substitute'
                            : booking.frequency
                        }`}
                      />
                    </Text>
                    <CustomerName bold>
                      {booking.company?.name ||
                        `${booking.person.firstname} ${booking.person.lastname}`}
                    </CustomerName>
                    <Address>
                      <span>
                        {booking.address.street_address}
                        &#44;
                      </span>
                      <span>
                        {booking.address.postcode}
                        &nbsp;
                        {booking.address.city}
                      </span>
                    </Address>
                  </Box>
                </Box>
                {(index !== array?.length - 1 || !!keys?.length) && <Divider />}
              </StyledLink>
            ))}
            {keys?.map((key, index, array) => (
              <StyledLink to={`/keys/${key.id}`} key={key.id}>
                <Box display="flex" pb={2}>
                  <IconContainer>
                    {key.return_date ? (
                      <IconKeyReturn color="white" size={16} />
                    ) : (
                      <IconKeyPickUp color="white" size={16} />
                    )}
                  </IconContainer>
                  <Box>
                    <Label m={0} mb="4px" color="attention">
                      <FormattedMessage
                        id={`dashboard.key_status.${key.status}`}
                      />
                    </Label>
                    <Text bold>
                      #{key.label}
                      {dot}
                      {key.nextBooking &&
                        `${key.nextBooking.person.firstname} ${key.nextBooking.person.lastname}`}
                    </Text>
                    {key.nextBooking && (
                      <Text>
                        {key.nextBooking.address.street_address}{' '}
                        {key.nextBooking.address.postcode}{' '}
                        {key.nextBooking.address.city}
                      </Text>
                    )}
                    {key.return_date || key.nextBooking && (
                      <Text size="small" color="secondary">
                        {key.return_date ? (
                          <FormattedMessage
                            id="keys.return_latest_by_date"
                            values={{
                              date: intl.formatDate(key.return_date, {
                                weekday: 'short',
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                              }),
                            }}
                          />
                        ) : (
                          <FormattedMessage
                            id="keys.pick_up_before_date"
                            values={{
                              date: intl.formatDate(key.nextBooking.start_time, {
                                weekday: 'short',
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                              }),
                            }}
                          />
                        )}
                      </Text>
                    )}
                  </Box>
                </Box>
                {index !== array?.length - 1 && <Divider />}
              </StyledLink>
            ))}
          </>
        )}
      </Card>
    </>
  );
}

const Divider = styled(Box)`
  background: ${theme.colors.greyMed};
  height: 1px;
  margin-right: ${theme.space[1]}px;
  margin-bottom: ${theme.space[2]}px;
  margin-left: ${theme.space[6]}px;
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1;
  justify-content: center;
  height: ${theme.space[4]}px;
  width: ${theme.space[4]}px;
  margin-right: ${theme.space[3]}px;

  background: ${theme.colors.attention};
  border-radius: 50%;
`;

const CustomerName = styled(Text)`
  text-transform: capitalize;
`;

const Address = styled(Text)`
  span {
    display: inline-block;
    padding-right: 4px;
  }
`;

ActionRequired.propTypes = propTypes;
