import React from 'react';
import { string } from 'prop-types';
import {
  IconHealing,
  IconBeachAccess,
  IconOtherAbsence,
} from '@freska/freska-ui';
import { useIntl } from 'react-intl';

const propTypes = {
  type: string.isRequired,
};

function AbsenceTypeIcon({ type, ...rest }) {
  const intl = useIntl();

  switch (type) {
    case 'sick_leave':
      return (
        <IconHealing
          title={intl.formatMessage({
            id: `absence.absence_types.${type}`,
          })}
          {...rest}
        />
      );
    case 'vacation':
      return (
        <IconBeachAccess
          title={intl.formatMessage({
            id: `absence.absence_types.${type}`,
          })}
          {...rest}
        />
      );
    default:
      return (
        <IconOtherAbsence
          title={intl.formatMessage({
            id: `absence.absence_types.${type}`,
          })}
          {...rest}
        />
      );
  }
}

AbsenceTypeIcon.propTypes = propTypes;

export default AbsenceTypeIcon;
