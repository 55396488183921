import React from 'react';
import styled, { css } from 'styled-components';
import { shape, func, instanceOf, bool } from 'prop-types';
import { Text, theme, Card, Label, IconFeedback, Box } from '@freska/freska-ui';
import { withRouter } from 'react-router-dom';
import { FormattedTime, FormattedMessage } from 'react-intl';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import Rating from '@material-ui/lab/Rating';
import { BOOKING_DETAILS_PATHNAME, RATING_QUALITY } from '../../constants';
import { bookingType } from '../../types';
import { trackEvent } from '../../utils/tracking';
import ServicesIconList from '../Bookings/ServicesIconList';

const propTypes = {
  booking: bookingType.isRequired,
  history: shape({}).isRequired,
  formatTimeForGrid: func.isRequired,
  startDate: instanceOf(Date).isRequired,
  endDate: instanceOf(Date).isRequired,
  isOutsideAvailability: bool.isRequired,
};

function getServiceTypeIntlId(
  { status, isUnconfirmed, service },
  isOutsideAvailability
) {
  if (status === 'canceled') return 'bookings.status.canceled';
  if (isUnconfirmed) return 'bookings.status.unconfirmed';
  if (isOutsideAvailability)
    return 'bookings.status.outside_availability_period';
  return `bookings.card.service_type.${service.id}`;
}

function EventCard({
  booking,
  history,
  formatTimeForGrid,
  startDate,
  endDate,
  isOutsideAvailability,
}) {
  const requiresAttention = booking.isUnconfirmed || isOutsideAvailability;
  function goToDetails(id) {
    trackEvent('Booking details opened from bookings view calendar', {
      category: 'Bookings',
    });

    history.push({
      pathname: BOOKING_DETAILS_PATHNAME,
      search: `?id=${id}`,
      state: { bookingCalendar: true, startDate, endDate },
    });
  }

  return (
    <EventCardStyle
      as="button"
      requiresAttention={requiresAttention}
      px={1}
      pt={0}
      pb={1}
      onClick={() => goToDetails(booking.id)}
      gridStart={formatTimeForGrid(booking.start_time)}
      gridEnd={formatTimeForGrid(booking.end_time)}
      isCancelled={booking.status === 'canceled'}
    >
      <Box>
        <SmallLabel
          requiresAttention={requiresAttention}
          isCancelled={booking.status === 'canceled'}
          mb={1}
          mt="4px"
        >
          <FormattedMessage
            id={getServiceTypeIntlId(booking, isOutsideAvailability)}
          />
        </SmallLabel>
        <Text size="smaller" bold>
          {booking.company
            ? booking.company.name
            : `${booking.person.firstname} ${booking.person.lastname}`}
        </Text>
        <Text size="smaller" as="p" mb={1}>
          <span>
            {booking.address.street_address}
            &#44;&nbsp;
            {booking.address.city}
          </span>
        </Text>

        {!booking.rating || !booking.rating.average ? (
          <Box display="flex">
            <ServicesIconList
              services={booking.detailSummary}
              size={12}
              marginRight="4px"
            />
          {booking.detailSummary.extendedDuration > 0 && (
            <FiberNewIcon style={{ fill: theme.colors.attention, fontSize: '12px' }} />
          )}
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            mb={1}
          >
            {booking.rating && booking.rating.comments && (
              <Box mr={1}>
                <IconFeedback size={12} />
              </Box>
            )}
            <Rating
              value={booking.rating.average}
              precision={0.1}
              size="small"
              readOnly
              style={{
                fontSize: '16px',
                color:
                  theme.colors[
                    booking.rating.average >= RATING_QUALITY.THRESHOLD
                      ? 'rating'
                      : 'attention'
                  ],
              }}
            />
          </Box>
        )}
      </Box>
      <Box>
        <SmallLabel>
          <FormattedTime
            value={booking.start_time}
            hour="2-digit"
            minute="2-digit"
            hour12={false}
          />
          &ndash;
          <FormattedTime
            value={booking.end_time}
            hour="2-digit"
            minute="2-digit"
            hour12={false}
          />
        </SmallLabel>
      </Box>
    </EventCardStyle>
  );
}

const EventCardStyle = styled(Card)`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  min-width: 72px;
  margin: 0 ${theme.space[1]}px;
  overflow: hidden;
  
  grid-row: ${props => `time-${props.gridStart} / time-${props.gridEnd}`};
  grid-column: 1;

  background: ${theme.colors.white};
  border-radius: 4px;
  border-top: 4px solid ${theme.colors.white};
  text-align: left;
  
  ${props =>
    props.requiresAttention &&
    css`
      border-top-color: ${theme.colors.attention};
    `}
    ${props =>
      props.isCancelled &&
      css`
        border-top-color: ${theme.colors.alert};
      `}
  
  opacity: ${props => (props.isCancelled ? 0.3 : 1)};
  z-index: ${props => (props.isCancelled ? 2 : 3)};
`;

const SmallLabel = styled(Label)`
  display: inline-block;
  font-size: 9px;
  color: ${props =>
    props.requiresAttention ? theme.colors.attention : theme.colors.secondary};
  ${props =>
    props.isCancelled &&
    css`
      color: ${theme.colors.alert};
    `}
`;

EventCard.propTypes = propTypes;

export default withRouter(EventCard);
