import {
  BOOKINGS_VIEW_TYPES_ENUM,
  BOOKINGS_VIEW_TYPE_KEY,
  BOOKING_DETAILS_PATHNAME,
  BOOKING_INSTRUCTIONS_PATHNAME,
} from '../constants';

import LocalStorage from './localStorage';

export const bookingsPathname = () =>
  `/bookings/${LocalStorage.get(BOOKINGS_VIEW_TYPE_KEY) ||
    BOOKINGS_VIEW_TYPES_ENUM.LIST}`;
export const bookingDetailsURL = id => `${BOOKING_DETAILS_PATHNAME}?id=${id}`;
export const bookingIntstructionsURL = id =>
  `${BOOKING_INSTRUCTIONS_PATHNAME}?id=${id}`;
