import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { shape, string } from 'prop-types';
import styled from 'styled-components';
import { Heading, Logo, Card } from '@freska/freska-ui';
import { withRouter } from 'react-router';

import LoginForm from '../../components/Forms/LoginForm';
import ForgotPasswordForm from '../../components/Forms/ForgotPasswordForm';
import FlexContainer from '../../components/Common/FlexContainer';
import BackLink from '../../components/Common/BackLink';
import { space } from '../../theme/theme';

const propTypes = {
  location: shape({ state: shape({ from: shape({ pathname: string }) }) }),
};

const defaultProps = {
  location: {
    state: {},
  },
};

class LoginContainer extends PureComponent {
  state = {
    forgotPassword: false,
  };

  toggleForgotPassword = e => {
    e.preventDefault();
    this.setState(prevState => ({ forgotPassword: !prevState.forgotPassword }));
  };

  render() {
    const { forgotPassword } = this.state;
    const {
      location: { state },
    } = this.props;

    return (
      <FlexContainer center fullScreen column>
        <FlexContainer center>
          <Logo size={40} mb={4} />
        </FlexContainer>
        <FormWrapper>
          {forgotPassword && (
            <BackLink
              onClick={this.toggleForgotPassword}
              id="return-to-login-btn"
              captionIntlId="login.return_to_login"
            />
          )}
          <StyledHeading level={2}>
            <FormattedMessage
              id={`login.${forgotPassword ? 'forgot_password' : 'login'}`}
            />
          </StyledHeading>
          {forgotPassword ? (
            <ForgotPasswordForm
              toggleForgotPassword={this.toggleForgotPassword}
            />
          ) : (
            <LoginForm
              origin={state && state.from ? state.from.pathname : '/'}
              toggleForgotPassword={this.toggleForgotPassword}
            />
          )}
        </FormWrapper>
      </FlexContainer>
    );
  }
}

const FormWrapper = styled(Card)`
  margin: 0 auto;
  max-width: 400px;
  width: 90%;
`;

const StyledHeading = styled(Heading)`
  margin: ${space.sm} 0 ${space.default};
`;

LoginContainer.propTypes = propTypes;
LoginContainer.defaultProps = defaultProps;

export default withRouter(LoginContainer);
