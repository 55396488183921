import React, { useRef, useState } from 'react';
import { arrayOf, shape, func, string } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import LocalStorage from '../../utils/localStorage';
import {
  IconClose,
  IconMenu,
  theme,
  Heading,
  Button,
  Box,
} from '@freska/freska-ui';
import { v4 as uuidv4 } from 'uuid';
import { Animate } from 'react-animate-mount';

import { space, sizes } from '../../theme/theme';
import NavMenu from './NavMenu';

const propTypes = {
  location: shape({ pathname: string }).isRequired,
  actions: arrayOf(
    shape({ handler: func, component: func, iconTooltip: string })
  ),
};

const defaultProps = {
  actions: [],
};

function NavBar({ location, actions }) {
  const intl = useIntl();
  const navContainerRef = useRef(null);
  const menuIconRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const isEmployed = LocalStorage.get('is-employed');

  function formatPathToTitle(path) {
    let title = path === '/' ? 'dashboard' : path.split('/')[1];
    title =
      isEmployed && (title === 'availability' || title === 'invoices')
        ? 'not_valid'
        : title;
    return title && !!intl.messages[`${title}.title`]
      ? `${title}.title`
      : 'not_found.title';
  }

  return (
    <NavContainer isOpen={isOpen} ref={navContainerRef}>
      <HeaderWrapper>
        <TitleAndMenuIconWrapper>
          <IconButton
            isOpen={isOpen}
            title={
              isOpen
                ? `${intl.formatMessage({
                    id: 'app.close',
                  })} ${intl.formatMessage({ id: 'app.menu' })}`
                : `${intl.formatMessage({
                    id: 'app.open',
                  })} ${intl.formatMessage({ id: 'app.menu' })}`
            }
            onClick={() => setIsOpen(!isOpen)}
            ref={menuIconRef}
          >
            {isOpen ? (
              <IconClose color="primary" size={24} />
            ) : (
              <IconMenu color="secondary" size={24} />
            )}
          </IconButton>
          <Heading level={3} ml={2} mb={0}>
            {isOpen ? (
              <FormattedMessage id="app.menu" />
            ) : (
              <FormattedMessage id={formatPathToTitle(location.pathname)} />
            )}
          </Heading>
        </TitleAndMenuIconWrapper>
        {!isOpen && (
          <Actions>
            {actions.map(action => (
              <StyledButton
                key={uuidv4()}
                p={0}
                onClick={action.handler}
                ml={2}
              >
                {action.component({
                  color: 'primary',
                  size: 24,
                  title: intl.formatMessage({ id: action.iconTooltip }),
                })}
              </StyledButton>
            ))}
          </Actions>
        )}
      </HeaderWrapper>
      <div>
        <Animate show={isOpen} duration={225}>
          <NavMenu
            isOpen={isOpen}
            closeMenu={() => setIsOpen(false)}
            isEmployed={isEmployed}
          />
        </Animate>
      </div>
    </NavContainer>
  );
}

const NavContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 13px ${space.default};
  background-color: ${props =>
    props.isOpen ? theme.colors.white : theme.colors.greyLight};
  height: ${props => (props.isOpen ? '100%' : sizes.height.header)};
  max-width: ${sizes.width.maxContent};
  width: 100%;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

const TitleAndMenuIconWrapper = styled(HeaderWrapper)`
  justify-content: flex-start;
`;

const IconButton = styled.button.attrs(props => ({
  id: 'nav-btn',
  name: 'nav-button',
  title: props.title,
}))`
  border: 0;
  background: transparent;
  cursor: pointer;
  padding-top: ${space.xs};

  & svg {
    display: block;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: ${theme.colors.greyLight};
  box-shadow: none;
  padding: none;
  border-radius: 16px;
  min-width: 32px;
  min-height: 32px;

  & svg {
    flex-shrink: 0;
  }
  &:hover,
  &:focus {
    background-color: ${theme.colors.greyLight};
    box-shadow: none;
  }
`;

NavBar.propTypes = propTypes;
NavBar.defaultProps = defaultProps;

export default withRouter(NavBar);
