import * as Joi from 'joi-browser';

const schema = Joi.object().keys({
  password: Joi.string()
    .min(6)
    .required(),
  passwordConfirmation: Joi.string()
    .min(6)
    .valid(Joi.ref('password'))
    .required(),
});

export default schema;
