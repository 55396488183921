import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { instanceOf, shape, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  IconChevronLeft,
  IconChevronRight,
  Heading,
  theme,
  typography,
} from '@freska/freska-ui';
import { addWeeks, isSameWeek, subWeeks, getISOWeek, format } from 'date-fns';
import { withRouter } from 'react-router-dom';

import FlexContainer from '../Common/FlexContainer';
import { sizes, space } from '../../theme/theme';
import { trackEvent } from '../../utils/tracking';

const propTypes = {
  startDayOfActiveWeek: instanceOf(Date).isRequired,
  history: shape({ push: func }).isRequired,
};
class WeekNav extends PureComponent {
  state = {
    today: new Date(),
    thisWeekNum: getISOWeek(new Date()),
    activeWeekNum: getISOWeek(new Date()),
    maxWeek: addWeeks(new Date(), 12),
    minWeek: subWeeks(new Date(), 12),
  };
  maxWeeks = 12;

  componentDidMount() {
    const { startDayOfActiveWeek } = this.props;
    this.setState({
      activeWeekNum: getISOWeek(startDayOfActiveWeek),
    });
  }

  componentDidUpdate = prevProps => {
    const { startDayOfActiveWeek } = this.props;
    if (prevProps.startDayOfActiveWeek !== startDayOfActiveWeek) {
      this.setState({
        activeWeekNum: getISOWeek(startDayOfActiveWeek),
      });
    }
  };

  getHeaderMessage = () => {
    const { activeWeekNum } = this.state;

    if (this.checkIfLastWeek()) {
      return <FormattedMessage id="bookings.last_week" />;
    }
    if (this.checkIfThisWeek()) {
      return <FormattedMessage id="bookings.this_week" />;
    }
    if (this.checkIfNextWeek()) {
      return <FormattedMessage id="bookings.next_week" />;
    }

    return (
      <FormattedMessage
        id="bookings.week_num"
        values={{ num: activeWeekNum }}
      />
    );
  };

  showHeaderWeekNumber = () => {
    const { activeWeekNum } = this.state;
    if (
      this.checkIfThisWeek() ||
      this.checkIfNextWeek() ||
      this.checkIfLastWeek()
    ) {
      return <sup>{activeWeekNum}</sup>;
    }
    return <span />;
  };

  checkIfThisWeek = () => {
    const { thisWeekNum, activeWeekNum } = this.state;
    return thisWeekNum === activeWeekNum;
  };

  checkIfLastWeek = () => {
    const { startDayOfActiveWeek } = this.props;
    const { today } = this.state;
    return isSameWeek(subWeeks(today, 1), startDayOfActiveWeek);
  };

  checkIfNextWeek = () => {
    const { today } = this.state;
    const { startDayOfActiveWeek } = this.props;
    return isSameWeek(addWeeks(today, 1), startDayOfActiveWeek);
  };

  checkIfMaxWeek = () => {
    const { maxWeek } = this.state;
    const { startDayOfActiveWeek } = this.props;
    return isSameWeek(maxWeek, startDayOfActiveWeek);
  };

  checkIfMinWeek = () => {
    const { minWeek } = this.state;
    const { startDayOfActiveWeek } = this.props;
    return isSameWeek(minWeek, startDayOfActiveWeek);
  };

  changeWeek = dir => {
    const { startDayOfActiveWeek, history } = this.props;
    if (dir === 'prev')
      trackEvent('Bookings: Previous week selected', {
        category: 'Bookings',
      });
    if (dir === 'next')
      trackEvent('Bookings: Upcoming week selected', {
        category: 'Bookings',
      });

    const pathname = window.location.pathname;

    if (dir === 'prev' && !this.checkIfMinWeek()) {
      const newStartDay = subWeeks(startDayOfActiveWeek, 1);
      this.setState({ activeWeekNum: getISOWeek(newStartDay) });
      history.push({
        pathname,
        search: `?date=${format(newStartDay, 'yyyy-MM-dd')}`,
      });
    } else if (dir === 'next' && !this.checkIfMaxWeek()) {
      const newStartDay = addWeeks(startDayOfActiveWeek, 1);
      this.setState({ activeWeekNum: getISOWeek(newStartDay) });
      history.push({
        pathname,
        search: `?date=${format(newStartDay, 'yyyy-MM-dd')}`,
      });
    }
  };

  getIconColor = dir => {
    if (dir === 'next') {
      return this.checkIfMaxWeek() ? 'greyMed' : 'black';
    }
    return this.checkIfMinWeek() ? 'greyMed' : 'black';
  };

  render() {
    return (
      <StyledDiv>
        <NavWrapper p={3} pt={2} alignV="flex-end" alignH="space-between">
          <IconChevronLeft
            onClick={() => {
              this.changeWeek('prev');
            }}
            size={24}
            color={this.getIconColor('prev')}
          />
          <WeekTitle level={2}>
            {this.getHeaderMessage()}
            {this.showHeaderWeekNumber()}
          </WeekTitle>
          <IconChevronRight
            size={24}
            color={this.getIconColor('next')}
            onClick={() => {
              this.changeWeek('next');
            }}
          />
        </NavWrapper>
      </StyledDiv>
    );
  }
}

const NavWrapper = styled(FlexContainer)`
  max-width: 300px;
  margin: 0 auto;
  padding: 0;
  width: 95%;
`;

const StyledDiv = styled.div`
  background: ${theme.colors.greyLight};
  position: sticky;
  padding: ${space.sm} 0 ${space.md};
  top: ${sizes.height.header};
  width: 100%;
  z-index: 3;
`;

const WeekTitle = styled(Heading)`
  margin: 0;

  sup {
    ${typography.fontRegular()};
    ${typography.fontBold()};
    color: ${theme.colors.secondary};
    display: inline-block;
    transform: translateY(-80%);
  }
`;

WeekNav.propTypes = propTypes;

export default withRouter(WeekNav);
