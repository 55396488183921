import React from 'react';
import styled from 'styled-components';
import { Button, theme, Text, Heading } from '@freska/freska-ui';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import FlexContainer from '../Common/FlexContainer';
import { sizes, space } from '../../theme/theme';
import CleaningGif from '../../assets/images/cleaning-gif.gif';

const NotFound = () => {
  const history = useHistory();
  return (
    <FlexContainer center column>
    <Error404Text level={1}>404</Error404Text>
    <ImageWrapper />
    <Text as="span" align="center">
      <FormattedMessage id="not_found.whoops" />
    </Text>
    <ReturnButton variant="secondary" mt={4} onClick={() => history.goBack()}>
      <FormattedMessage id="not_found.return" />
    </ReturnButton>
  </FlexContainer>
  );
};

const ImageWrapper = styled.div`
  background: url(${CleaningGif}) no-repeat ${theme.colors.greyLight};
  background-blend-mode: darken;
  background-size: 100%;
  height: 0;
  max-width: 500px;
  padding-top: 50%;
  width: 100%;

  @media screen and (min-width: 520px) {
    height: 250px;
    padding-top: 0;
  }
`;

const Error404Text = styled(Heading)`
  margin: ${space.default} 0;
`;

const ReturnButton = styled(Button)`
  font-size: ${sizes.text.md};

  a,
  a:visited {
    color: ${theme.colors.primary};
  }
`;

export default NotFound;
