import React from 'react';
import { Query } from 'react-apollo';
import { Box, Spinner } from '@freska/freska-ui';

import { GET_LANGUAGE } from '../gql/queries';

function withLanguage(Wrapped) {
  return function WrappedComponent(props) {
    return (
      <Query query={GET_LANGUAGE}>
        {({ loading, data }) => {
          if (loading)
            return (
              <Box p={3}>
                <Spinner />
              </Box>
            );
          return <Wrapped language={data.language} {...props} />;
        }}
      </Query>
    );
  };
}

export default withLanguage;
