import React, { PureComponent } from 'react';
import { shape, string } from 'prop-types';
import styled from 'styled-components';
import { Text } from '@freska/freska-ui';
import FlexContainer from '../Common/FlexContainer';

const propTypes = {
  video: shape({
    link: string,
    title: string,
    duration: string,
  }).isRequired,
};

class VideoComponent extends PureComponent {
  getVideoId = link => link.split('v=')[1];

  render() {
    const { video } = this.props;

    return (
      <FlexContainer>
        <div>
          <Video
            id={this.getVideoId(video.link)}
            src={video.link.replace('watch?v=', 'embed/')}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title={video.title}
          />
        </div>
        <FlexContainer ml={2} column alignV="flex-start" alignH="flex-start">
          <Text as="span" bold align="left">
            {video.title}
          </Text>
          <Text as="span">{video.duration}</Text>
        </FlexContainer>
      </FlexContainer>
    );
  }
}

const Video = styled.iframe`
  // these sizes are used to keep the aspect ratio
  width: 55vw;
  height: 31vw;
  max-width: 412px;
  max-height: 232px;
`;

VideoComponent.propTypes = propTypes;

export default VideoComponent;
