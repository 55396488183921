import { keyframes } from 'styled-components';

export const AttentionWiggle = keyframes`
  2.5% {
    transform: scale(1.2) rotate(25deg);
  }
  5% {
    transform: scale(1) rotate(0);
  }
  7.5% {
    transform: scale(1.2) rotate(-25deg);
  }
  10%, 100% {
    transform: scale(1) rotate(0);
  }
`;

export const ConfettiFloat = keyframes`
  0% {
    opacity: 0;
    transform: translateY(0%) rotate(0deg);
  }
  10% {
    opacity: 1;
  }
  35% {
    transform: translateY(-800%) rotate(270deg);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(2000%) rotate(1440deg);
  }
`;
