import * as Joi from 'joi-browser';

const schema = Joi.object().keys({
  startField: Joi.string().regex(/^([0-9]{2}):([0-9]{2})$/),
  endField: Joi.string().regex(/^([0-9]{2}):([0-9]{2})$/),
  startHours: Joi.number()
    .max(Joi.ref('endHours'))
    .required(),
  endHours: Joi.number()
    .min(Joi.ref('startHours'))
    .required(),
});

export default schema;
