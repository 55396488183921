import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Label } from '@freska/freska-ui';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { space } from '../../theme/theme';

const propTypes = {
  title: PropTypes.string,
  Icon: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  hasContent: PropTypes.bool,
  withAttention: PropTypes.bool,
  noContentValue: PropTypes.node,
  hasBottomMargin: PropTypes.bool,
};

const defaultProps = {
  title: '',
  hasContent: true,
  withAttention: false,
  hasBottomMargin: true,
  noContentValue: [
    <Text as="p" m={0} color="secondary" key={uuidv4()}>
      &ndash;
    </Text>,
  ],
};

function getIconColor(hasContent, withAttention) {
  if (withAttention) {
    return 'attention';
  }
  return hasContent ? 'secondary' : 'greyMed';
}

function getLabelColor(hasContent, withAttention) {
  if (withAttention) {
    return 'attention';
  }
  return hasContent ? 'black' : 'secondary';
}

const DataBlock = ({
  hasContent,
  title,
  Icon,
  children,
  noContentValue,
  withAttention,
  hasBottomMargin,
  ...rest
}) => {
  const intl = useIntl();
  return (
    <StyledGrid {...rest} hasBottomMargin={hasBottomMargin}>
      <Icon
        size={24}
        title={
          title
            ? intl.formatMessage({
                id: title,
              })
            : ''
        }
        color={getIconColor(hasContent, withAttention)}
      />
      <Box ml={2}>
        {!!title && (
          <Label mb={1} color={getLabelColor(hasContent, withAttention)}>
            <FormattedMessage id={title} />
          </Label>
        )}
        {hasContent ? children : noContentValue}
      </Box>
    </StyledGrid>
  );
};

const StyledGrid = styled(Box)`
  align-items: start;
  display: grid;
  grid-template-columns: 24px 1fr;
  margin-bottom: ${props => props.hasBottomMargin && space.default};
`;

DataBlock.propTypes = propTypes;
DataBlock.defaultProps = defaultProps;

export default DataBlock;
