import React from 'react';
import { string, func, bool, number, shape } from 'prop-types';
import styled, { css } from 'styled-components';
import { Input, Checkbox, Text, theme, Box } from '@freska/freska-ui';
import { space } from '../../theme/theme';
import { checkForError } from '../../utils/validate';
import { Fragment } from 'react';

const propTypes = {
  id: string.isRequired,
  title: string.isRequired,
  handleInputChange: func.isRequired,
  isChecked: bool.isRequired,
  handleSwitch: func.isRequired,
  startValue: string.isRequired,
  endValue: string.isRequired,
  index: number.isRequired,
  errors: shape({}),
};

const defaultProps = {
  errors: {},
};

function Time({
  title,
  id,
  index,
  handleInputChange,
  handleSwitch,
  isChecked,
  startValue,
  endValue,
  errors,
}) {
  return (
    <ListItem isChecked={isChecked}>
      <StyledCheckbox
        id={`${id}-checkbox`}
        onChange={() => handleSwitch(`is${id}Checked`)}
        checked={isChecked}
        label={title}
        name={title}
      />
      {isChecked && (
        <Fragment>
          <StartTimeInput
            id={`${id}Start`}
            shrink={true}
            type="time"
            disabled={!isChecked}
            onChange={e => handleInputChange(e, 'start')}
            value={startValue}
            error={
              checkForError(errors, `schedule.${index}.start`) ||
              checkForError(errors, `schedule.${index}.startTime`)
            }
            hasBorder
          />
          <Dash textAlign="center" gridArea="dash">
            &ndash;
          </Dash>
          <EndTimeInput
            gridArea="endTime"
            id={`${id}End`}
            type="time"
            disabled={!isChecked}
            onChange={e => handleInputChange(e, 'end')}
            value={endValue}
            error={
              checkForError(errors, `schedule.${index}.end`) ||
              checkForError(errors, `schedule.${index}.endTime`)
            }
            hasBorder
          />
        </Fragment>
      )}
    </ListItem>
  );
}

const StartTimeInput = styled(Input)`
  width: 100%;
  height: 44px;
  grid-area: startTime;
`;

const EndTimeInput = styled(Input)`
  width: 100%;
  height: 44px;
  grid-area: endTime;
`;

const Dash = styled(Text)`
  grid-area: dash;
  width: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  grid-area: checkbox;
  width: 100%;
`;
const ListItem = styled(Box)`
  display: grid;

  grid-gap: ${theme.space[1]}px;
  border-bottom: solid 1px ${theme.colors.greyMed};
  padding: ${space.md} 0;
  grid-template-areas: 'checkbox checkbox checkbox checkbox';
  align-items: center
    ${props =>
      props.isChecked &&
      css`
        grid-template-columns: 24px 1fr 8px 1fr;
        grid-template-rows: 24px 1fr;
        grid-template-areas:
          'checkbox checkbox checkbox checkbox'
          '. startTime dash endTime';
        align-items: center;
      `};
`;

Time.propTypes = propTypes;
Time.defaultProps = defaultProps;

export default Time;
