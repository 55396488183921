import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { theme } from '@freska/freska-ui';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const FullscreenContentContainer = ({ children }) => (
  <FullscreenContainer>{children}</FullscreenContainer>
);

const FullscreenContainer = styled.section`
  background-color: ${theme.colors.white};
  min-height: 100vh;
  z-index: 3;
`;

FullscreenContentContainer.propTypes = propTypes;

export default FullscreenContentContainer;
