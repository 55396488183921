import React, { Fragment } from 'react';
import { shape, func, oneOf, bool } from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import {
  IconDateRange,
  IconReceipt,
  Heading,
  Text,
  Tag,
  Box,
  Spinner,
  theme,
} from '@freska/freska-ui';
import { useIntl, FormattedMessage, FormattedDate } from 'react-intl';
import { graphql } from 'react-apollo';
import withLanguage from '../../utils/withLanguage';
import {
  formatUnitPrice,
  formatPrice,
  formatAndRoundDownNumber,
} from '../../utils/formatNumber';
import { getRawServiceTypeIcon } from '../../utils/bookingsHelper';
import { LANGUAGES_TYPE, SERVICE_TYPES } from '../../constants';
import { GET_INVOICE_BY_ID } from '../../gql/queries';

import DataBlock from '../Common/DataBlock';
import StickyTop from '../Common/StickyTop';
import FullscreenContentContainer from '../Common/FullscreenContentContainer';
import BackLink from '../Common/BackLink';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import InvoicedBookingCard from './InvoicedBookingCard';
import TotalPerServiceType from './TotalPerServiceType';
import renderInvoiceDetail from './InvoiceReceiptDetail';

import { space } from '../../theme/theme';
import { invoiceType } from '../../types';

const propTypes = {
  language: oneOf(LANGUAGES_TYPE).isRequired,
  data: invoiceType.isRequired,
  history: shape({ push: func }).isRequired,
  location: shape({ state: shape({ invoiceOrigin: bool }) }).isRequired,
};

function getTotalHours(language, lines) {
  return formatAndRoundDownNumber(
    lines.reduce((prev, current) => prev + Number(current.quantity), 0),
    language
  );
}

function getTagColor(status) {
  switch (status) {
    case 'invoiced':
      return 'secondary';
    case 'processed':
      return 'greyMed';
    case 'paid':
      return 'completed';
    default:
      return 'attention';
  }
}

function getServiceTotalHours(language, service, lines) {
  const totalHours = lines
    .filter(line => line.description === service)
    .reduce(
      (accumulator, current) => accumulator + Number(current.quantity),
      0
    );
  return totalHours ? (
    <FormattedMessage
      id="invoices.hours"
      values={{ hours: formatAndRoundDownNumber(totalHours, language) }}
    />
  ) : null;
}

function getServiceTotalAmount(service, lines, language, currency) {
  const totalAmount = lines
    .filter(line => line.description === service)
    .reduce(
      (accumulator, current) =>
        accumulator + Number(current.total_amount_vat_excluded),
      0
    );
  return formatPrice(totalAmount, language, currency);
}

function goToOverview(history, location) {
  if (location.state && location.state.invoiceOrigin) {
    history.goBack();
  } else {
    history.push('/invoices');
  }
}

const InvoiceDetails = ({
  data: { loading, error, getInvoiceById: invoice },
  language,
  history,
  location,
}) => {
  const intl = useIntl();
  if (error) {
    return <ErrorHandler error={error} />;
  }
  return (
    <FullscreenContentContainer>
      {loading ? (
        <Box p={3}>
          <Spinner />
        </Box>
      ) : (
        <Fragment>
          <StickyTop>
            <BackLink
              onClick={() => goToOverview(history, location)}
              captionIntlId="invoices.back_link"
            />
            <Heading level={2} mb={2} align="left">
              {invoice.our_reference}
            </Heading>
            <Tag color={getTagColor(invoice.status)} mt={2} mb={1}>
              {intl.formatMessage({
                id: `invoices.status.${invoice.status}`,
              })}
            </Tag>
            {invoice.status === 'uninvoiced' && (
              <StatusDescriptionBox>
                <Text as="p" bold>
                  <FormattedMessage id="invoices.awaiting_invoice_header" />
                </Text>
                <Text as="p">
                  <FormattedMessage id="invoices.awaiting_invoice_desc" />
                </Text>
              </StatusDescriptionBox>
            )}
          </StickyTop>
          <Wrapper>
            <Section>
              <DataBlock Icon={IconDateRange} hasContent>
                <Text m={0} as="p">
                  <FormattedDate
                    value={invoice.data.bookings_from}
                    year="numeric"
                    month="2-digit"
                    day="2-digit"
                  />
                  &nbsp;&ndash;&nbsp;
                  <FormattedDate
                    value={invoice.data.bookings_to}
                    year="numeric"
                    month="2-digit"
                    day="2-digit"
                  />
                </Text>
              </DataBlock>
              <DataBlock Icon={IconReceipt} hasContent>
                <Grid>
                  {invoice.paid &&
                    renderInvoiceDetail(
                      'invoices.ukko.invoice_number',
                      invoice.purchase_invoices[0].invoice_number
                    )}
                  {invoice.paid &&
                    renderInvoiceDetail(
                      'invoices.ukko.paid_on',
                      intl.formatDate(invoice.purchase_invoices[0].paid_on, {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })
                    )}
                  {renderInvoiceDetail(
                    'invoices.ukko.quantity',
                    intl.formatMessage(
                      {
                        id: 'invoices.hours',
                      },
                      { hours: getTotalHours(language, invoice.lines) }
                    )
                  )}
                  {renderInvoiceDetail(
                    'invoices.ukko.unit_price',
                    formatUnitPrice(
                      invoice.lines[0].unit_price,
                      language,
                      invoice.currency
                    )
                  )}
                  {renderInvoiceDetail(
                    'invoices.ukko.payment_terms',
                    invoice.payment_term
                  )}
                  {renderInvoiceDetail(
                    'invoices.ukko.total_amount',
                    formatPrice(
                      invoice.total_amount_vat_excluded,
                      language,
                      invoice.currency
                    )
                  )}
                  {renderInvoiceDetail(
                    'invoices.ukko.vat_amount',
                    formatPrice(
                      invoice.total_vat_amount,
                      language,
                      invoice.currency
                    )
                  )}
                  {renderInvoiceDetail(
                    'invoices.ukko.total_amount_with_vat',
                    formatPrice(
                      invoice.total_amount,
                      language,
                      invoice.currency
                    )
                  )}
                </Grid>
              </DataBlock>
            </Section>
            <Section>
              <Heading level={3} mb={2}>
                <FormattedMessage id="invoices.totals_per_service_type" />
              </Heading>
              {SERVICE_TYPES.map(serviceType => (
                <TotalPerServiceType
                  key={serviceType}
                  Icon={getRawServiceTypeIcon(serviceType)}
                  totalHours={getServiceTotalHours(
                    language,
                    serviceType,
                    invoice.lines
                  )}
                  totalAmount={getServiceTotalAmount(
                    serviceType,
                    invoice.lines,
                    language,
                    invoice.currency
                  )}
                />
              ))}
            </Section>
            <Section>
              <Heading level={3} mb={2}>
                <FormattedMessage id="invoices.included_bookings" />
              </Heading>
              {invoice.lines.map(
                line =>
                  !!line.booking && (
                    <InvoicedBookingCard
                      key={line.id}
                      booking={line.booking}
                      currency={invoice.currency}
                      language={language}
                      totalPrice={line.total_amount_vat_excluded}
                      unitPrice={line.unit_price}
                      serviceType={line.description}
                    />
                  )
              )}
            </Section>
          </Wrapper>
        </Fragment>
      )}
    </FullscreenContentContainer>
  );
};

const Wrapper = styled.div`
  padding: 0 ${space.default} ${space.default};
`;

const Section = styled.section`
  margin-bottom: ${space.lg};
`;

const Grid = styled.div`
  grid-gap: ${space.md};
  align-items: start;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const StatusDescriptionBox = styled.div`
  border-left: 2px solid ${theme.colors.attention};
  padding-left: ${space.sm};
`;

InvoiceDetails.propTypes = propTypes;

export default graphql(GET_INVOICE_BY_ID, {
  options: props => ({
    variables: {
      invoiceId: Number(props.match.params.id),
    },
    fetchPolicy: 'network-only',
  }),
})(withRouter(withLanguage(InvoiceDetails)));
